import gql from 'graphql-tag';

export default gql`
  mutation deleteLocation($id: ID!){
    commonMutations{
      deleteLocation(id:$id){
        result
        message
        isError
        isSuccess
      }
    }
  }
`;