import gql from 'graphql-tag';

export default gql`
query locationsCustomQuery($skip: Int, $take: Int){
    commonQueries{
        getLocations(skip:$skip, take:$take){
            _id
            level
            center{
              lat
              lon
            }
            nameEN
            nameMM
            placeCode
            parent{
              _id
              nameEN
              nameMM
            }
        }
    }
}
`
