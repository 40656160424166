import {fixMalformedGeometry} from './'

export default (data) =>  {
  const changeGeoTypeString = (type) => {
    if (type === 'POINT') return 'Point';
    if (type === 'POLYGON') return 'Polygon';
    if (type === 'LINE') return 'Line';
    if (type === 'LineString') return 'MultiLineString';
    // if (type === 'Polygon') return 'MultiPolygon';
    return type;
  }

  // console.log("geodata",data);

  // This function returns an object with properties and geometry as seperate properties of the return value

  return data.map(d => {
    const propertiesKeyVals = {};
    const changedGeoType = changeGeoTypeString(d.geometry.type);
    // console.log(d);
    const returnObj = {
      properties: d.properties.reduce((obj, item) => {
          return {
            ...obj,
            [item['field']['nameEN']]: item['value'] ? item['value'] : "",
          };
        }, propertiesKeyVals),
      geometry: { 
        type: changedGeoType, 
        coordinates: changedGeoType === 'Point' ? d.geometry.coordinates : fixMalformedGeometry(d.geometry.coordinates) }
    }
    // console.log(returnObj);
    return returnObj;
  })
}