import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Header,
  Button,
  Grid,
  Icon,
  Dropdown,
  Pagination,
  Table,
  Modal,
  Container,
  Input,
  Label,
  Popup,
} from 'semantic-ui-react';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import LAYERS_TOTAL_QUERY from '../graphql/layersTotalQuery';
import LAYERS_CUSTOM_QUERY from '../graphql/layersCustomQuery';
import GET_LAYERS_BY_NESTED_PROP from '../graphql/getLayersByNestedProperties';
import DEFAULT_COUNTS_QUERY from '../graphql/getFilterTotals';
import TOTAL_SEARCH_LAYERS from '../graphql/getTotalLayersByInput';
import LAYERS_QUERY_INPUT from '../graphql/getLayersByInput';
import DELETE_LAYER from '../graphql/deleteLayer';
import '../styles/EditorPage.scss';
import moment from 'moment';
import intl from 'react-intl-universal';
import { useDebounce } from '../components/customHooks/useDebounce';
import { LanguageContext } from '../context/langProvider';
import { SocketContext } from '../context/socketProvider';
import FilterAccordian from '../components/editor/FilterAccordian';
import { getRole } from '../utils';

function EditorPage(props) {
  const languageContext = useContext(LanguageContext);
  const socketContext = useContext(SocketContext);
  const { socket } = socketContext;
  const { OPEN, readyState } = socket;
  const { data: default_count_data } = useQuery(DEFAULT_COUNTS_QUERY);
  const { data: totallayerdata } = useQuery(LAYERS_TOTAL_QUERY);
  const [state, setState] = useState({
    skip: 0,
    take: 10,
    activePage: 1,
    sskip: 0,
    stake: 10,
    sactivePage: 1,
    uskip: 0,
    utake: 10,
    uactivePage: 1,
  });
  const [sortBy, setSortby] = useState('updatedAt');
  const [sortAscending, setSortAscending] = useState(false);
  const [boundaryRange, setBoundaryRange] = useState(1);
  const [siblingRange, setSiblingRange] = useState(1);
  const [showEllipsis, setShowEllipis] = useState(true);
  const [showFirstAndLastNav, setShowFirstAndLastNav] = useState(true);
  const [showPreviousAndNextNav, setShowPreviousAndNextNav] = useState(true);
  const [id, setId] = useState('');
  const [notify, setNotify] = useState(false);
  const [notificaton, setNotificaton] = useState({});
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [upcount, setUpCount] = useState(0);
  const [hostdata, setHostdata] = useState();
  const [updatedata, setUpdatedata] = useState();
  const [offices, setOffices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [levels, setLevels] = useState([]);
  const [searchedcount, setSearchedCount] = useState(0);
  const [searcheddata, setSearchedData] = useState([]);
  let [searchedvalue, setSearchedvalue] = useState('');
  const [unlock, setUnlock] = useState(false);
  let debouncedValue = useDebounce(searchedvalue, 220);
  const [query, setQuery] = useState({});
  const [filterby, setFilterby] = useState('');
  const [layerid, setLayerId] = useState('');
  const [layerlockedby, setLayerLockedBy] = useState({
    id: '',
    name: '',
    email: '',
  });
  const [roleLevel, setRoleLevel] = useState(0);

  const {
    loading: customlayerloading,
    data: customlayerdata,
    error: customlayererror,
    refetch,
  } = useQuery(
    LAYERS_CUSTOM_QUERY,
    {
      variables: { skip: state.skip, take: state.take, sortBy, sortAscending },
    },
    { fetchPolicy: 'network-only' },
  );
  // const [getLazyLayers, lazyLayersQuery] = useLazyQuery(LAYERS_CUSTOM_QUERY,
  //   { variables: { skip: state.skip, take: state.take, sortBy } })
  const { data: search_layers_count } = useQuery(TOTAL_SEARCH_LAYERS, {
    variables: {
      where:
        languageContext.language === 'my'
          ? {
              nameMM: debouncedValue,
            }
          : {
              nameEN: debouncedValue,
            },
    },
    skip: searchedvalue === '',
  });
  const {
    loading: search_layer_loading,
    data: search_layer_results,
    refetch: refetchinput,
  } = useQuery(LAYERS_QUERY_INPUT, {
    variables: {
      skip: state.sskip,
      take: state.stake,
      sortBy,
      sortAscending,
      where:
        languageContext.language === 'my'
          ? {
              nameMM:
                debouncedValue === '' || debouncedValue.length <= 2
                  ? null
                  : debouncedValue,
            }
          : {
              nameEN:
                debouncedValue === '' || debouncedValue.length <= 2
                  ? null
                  : debouncedValue,
            },
    },
    skip: searchedvalue === '',
  });
  const {
    loading: layers_by_child_loading,
    data: layers_by_child_data,
    error: layers_by_child_error,
    refetch: refetchchild,
  } = useQuery(GET_LAYERS_BY_NESTED_PROP, {
    variables: {
      skip: state.uskip,
      take: state.utake,
      where: query,
    },
    skip: Object.keys(query).length === 0,
  });

  useEffect(() => {
    if (default_count_data) {
      const { offices, locations, levels } =
        default_count_data && default_count_data.layersQuery.getFilterTotals;
      let hostoffices = [];
      offices &&
        offices.map((off) => {
          return hostoffices.push({
            name: off.department,
            count: off.count,
            active: false,
            id: off._id,
            level: off.level,
            ministry: off.ministry,
            department: off.department,
          });
        });
      setOffices(hostoffices);
      let hostlocations = [];
      locations &&
        locations.map((loc) => {
          return hostlocations.push({
            name: loc.nameEN,
            count: loc.count,
            active: false,
            nameEN: loc.nameEN,
            nameMM: loc.nameMM,
            id: loc._id,
          });
        });
      setLocations(hostlocations);
      let hostlevels = [];
      levels &&
        levels.map((lev) => {
          return hostlevels.push({
            name: lev.name,
            count: lev.count,
            active: false,
            locations: lev.locations,
          });
        });
      setLevels(hostlevels);
    }
  }, [default_count_data]);

  useEffect(() => {
    if (totallayerdata) {
      setCount(
        totallayerdata &&
          totallayerdata.layersQuery &&
          totallayerdata.layersQuery.getLayersTotal &&
          totallayerdata.layersQuery.getLayersTotal.count,
      );
    }
    if (customlayerdata) {
      setHostdata(
        customlayerdata &&
          customlayerdata.layersQuery &&
          customlayerdata.layersQuery.getLayers &&
          customlayerdata.layersQuery.getLayers,
      );
    }
    // if (lazyLayersQuery.data) {
    //   setHostdata(lazyLayersQuery.data && lazyLayersQuery.data.layersQuery && lazyLayersQuery.data.layersQuery.getLayers && lazyLayersQuery.data.layersQuery.getLayers)
    // }
  }, [customlayerdata, totallayerdata]);

  useEffect(() => {
    let data =
      layers_by_child_data &&
      layers_by_child_data.layersQuery &&
      layers_by_child_data.layersQuery.getLayers;
    if (data === null && data === undefined) {
      setUpdatedata([]);
      setUpCount(0);
      setState({
        uskip: 0,
        activePage: 1,
      });
    } else if (data && Object.keys(query).length !== 0) {
      setUpdatedata(data);
    }
  }, [layers_by_child_data]);

  useEffect(() => {
    if (search_layers_count && search_layers_count.layersQuery) {
      if (debouncedValue === '' || debouncedValue.length <= 2) {
        setSearchedCount(0);
        setState({
          sskip: 0,
        });
      } else {
        setSearchedCount(
          parseInt(search_layers_count.layersQuery.getLayersTotal.count),
        );
      }
    }
  }, [search_layers_count]);

  useEffect(() => {
    if (
      search_layer_results &&
      search_layer_results.layersQuery &&
      debouncedValue !== ''
    ) {
      removeFilterby();
      let data = [...search_layer_results.layersQuery.getLayers];
      setSearchedData([...new Map(data.map((l) => [l['_id'], l])).values()]);
    }
    if (searchedvalue === '') {
      setSearchedData([]);
      setSearchedCount(0);
      setState({
        sskip: 0,
      });
    }
  }, [search_layer_results]);

  useEffect(() => {
    if (id !== null) {
      socket.onmessage = (e) => {
        let res = JSON.parse(e.data);
        if (res && res.type === 'notification') {
          setNotificaton(res.message);
        }
      };
    }
  }, [socket, notify]);

  useEffect(() => {
    if (readyState === OPEN && notify) {
      let noti = {
        type: 'notify-unlock-data',
        message: {
          model: 'layer',
          id,
        },
      };
      socket.send(JSON.stringify(noti));
      console.log('requested to unlock!');
    }
  }, [socket, notify]);

  useEffect(() => {
    if (notify) {
      setOpen(Object.entries(notificaton).length !== 0);
    } else {
      setOpen(false);
    }
  }, [socket, notificaton, notify]);

  useEffect(() => {
    const { OPEN, readyState } = socket;
    if (
      readyState === OPEN &&
      unlock &&
      layerlockedby.id !== '' &&
      layerlockedby.id === props.user.id
    ) {
      let message = {
        type: 'unlock-data',
        message: {
          model: 'layer',
          id: layerid,
        },
      };
      socket.send(JSON.stringify(message));
      setUnlock(false);
    }
  }, [socket, unlock, layerid]);

  const refreshConnection = async () => {
    await socketContext.externalRefresh();
  };
  useEffect(() => {
    if (!socket) {
      refreshConnection();
    }
    if (socket && socket.readyState !== socket.OPEN) {
      refreshConnection();
    }

    if (socket && socket.readyState !== 1) {
      refreshConnection();
    }

    if (socket && socket.OPEN !== 1) {
      refreshConnection();
    }
  }, [
    socket,
    state,
    updatedata,
    searcheddata,
    customlayerdata,
    layers_by_child_data,
    search_layer_results,
    search_layers_count,
    hostdata,
    layerid,
  ]);

  useEffect(() => {
    if (props.user) {
      let res = getRole(
        props.user.userLevel.priority,
        props.user.userLevel.accessType,
      );
      setRoleLevel(parseInt(res));
    }
  }, [props]);

  const [deleteLayer, { isDeletingLayer = customlayerloading }] = useMutation(
    DELETE_LAYER,
    {
      onCompleted: () => {
        return isDeletingLayer;
      },
      refetchQueries: [
        {
          query: LAYERS_CUSTOM_QUERY,
          variables: {
            skip: state.skip,
            take: state.take,
            sortBy,
            sortAscending,
          },
        },
      ],
      onError: (error) => {
        // const { message } = error.response.data;
        // return message;
        console.log(error);
      },
      errorPolicy: 'all',
    },
  );

  const handleRetry = async () => {
    // getLazyLayers();
    await refetch({
      variables: { skip: state.skip, take: state.take, sortBy, sortAscending },
    });
  };

  const handleDeleteLayer = async (id) => {
    await deleteLayer({ variables: { id } });
    await refetch({
      variables: { skip: state.skip, take: state.take, sortBy, sortAscending },
    });
    if (debouncedValue !== '' || debouncedValue.length >= 2) {
      await refetchinput({
        variables: {
          skip: state.sskip,
          take: state.stake,
          sortBy,
          sortAscending,
          where:
            languageContext.language === 'my'
              ? {
                  nameMM: debouncedValue,
                }
              : {
                  nameEN: debouncedValue,
                },
        },
      });
    }
    await refetchchild();
  };

  const handleNotify = (id) => {
    if (!socket) {
      refreshConnection();
    }
    if (socket && socket.readyState !== socket.OPEN) {
      refreshConnection();
    }
    setId(id);
    setNotify(true);
  };

  const handleClose = async () => {
    setNotify(false);
    setOpen(false);
    setNotificaton({});
    await refetch({
      variables: { skip: state.skip, take: state.take, sortBy, sortAscending },
    });
  };

  const handleUnlockLayer = async (layer) => {
    if (!socket) {
      refreshConnection();
    }
    if (socket && socket.readyState !== socket.OPEN) {
      refreshConnection();
    }
    setUnlock(true);
    setLayerId(layer._id);
    setLayerLockedBy({
      id: layer.lockedBy._id,
      name: layer.lockedBy.name,
      email: layer.lockedBy.email,
    });
    await refetch({
      variables: { skip: state.skip, take: state.take, sortBy, sortAscending },
    });
    if (debouncedValue !== '' || debouncedValue.length >= 2) {
      await refetchinput({
        variables: {
          skip: state.sskip,
          take: state.stake,
          sortBy,
          sortAscending,
          where:
            languageContext.language === 'my'
              ? {
                  nameMM: debouncedValue,
                }
              : {
                  nameEN: debouncedValue,
                },
        },
      });
    }
    await refetchchild();
  };

  const isselected = (element) => element.active === true;

  const setDefaultLevels = () => {
    let hostlevels = levels;
    hostlevels.map((lev) => {
      lev.active = false;
      return lev;
    });
    setLevels(hostlevels);
  };

  const setDefaultLocations = () => {
    let hostlocations = locations;
    hostlocations.map((loc) => {
      loc.active = false;
      return loc;
    });
    setLocations(hostlocations);
  };

  const setDefaultOffices = () => {
    let hostoffices = offices;
    hostoffices.map((off) => {
      off.active = false;
      return off;
    });
    setOffices(hostoffices);
  };

  const removeFilterby = () => {
    setDefaultLevels();
    setDefaultLocations();
    setDefaultOffices();
    setQuery({});
    setUpdatedata([]);
    setFilterby('');
  };

  const cleanupQuery = (arrval, strval) => {
    if (arrval.some(isselected) === false) {
      if (filterby === strval) {
        setQuery({});
        setUpdatedata([]);
        setUpCount(0);
        setFilterby('');
      }
    }
  };

  const handleLevelSelect = async (level, key) => {
    setSearchedvalue('');
    setDefaultOffices();
    setDefaultLocations();
    let hostlevels = levels;
    hostlevels[key] = hostlevels[key].active
      ? {
          name: level.name,
          count: level.count,
          active: false,
          locations: level.locations,
        }
      : {
          name: level.name,
          count: level.count,
          active: true,
          locations: level.locations,
        };
    setLevels(hostlevels);
    let querylocations = [];
    let querycount = 0;
    levels.map((level) => {
      if (level.active) {
        console.log(level);
        return (
          querylocations.push(...level.locations),
          (querycount += level.count),
          setFilterby(intl.get('editor.layer_list.filtered_by_level'))
        );
      }
    });
    setQuery({
      location: { $in: querylocations },
    });
    setState({
      uskip: 0,
    });
    setUpCount(querycount);
    cleanupQuery(levels, intl.get('editor.layer_list.filtered_by_level'));
  };
  const handleLocationSelect = (loc, key) => {
    setSearchedvalue('');
    setDefaultLevels();
    setDefaultOffices();
    let hostlocations = locations;
    hostlocations[key] = hostlocations[key].active
      ? {
          name: loc.nameEN,
          count: loc.count,
          active: false,
          nameEN: loc.nameEN,
          nameMM: loc.nameMM,
          id: loc.id,
        }
      : {
          name: loc.nameEN,
          count: loc.count,
          active: true,
          nameEN: loc.nameEN,
          nameMM: loc.nameMM,
          id: loc.id,
        };
    setLocations(hostlocations);
    let querylocations = [];
    let querycount = 0;
    locations.map((loc) => {
      if (loc.active) {
        return (
          querylocations.push(loc.id),
          (querycount += loc.count),
          setFilterby(intl.get('editor.layer_list.filtered_by_location'))
        );
      }
    });
    setQuery({
      location: { $in: querylocations },
    });
    setState({
      uskip: 0,
    });
    setUpCount(querycount);
    cleanupQuery(locations, intl.get('editor.layer_list.filtered_by_location'));
  };
  const handleOfficeSelect = (off, key) => {
    setSearchedvalue('');
    setDefaultLocations();
    setDefaultLevels();
    let hostoffices = offices;
    hostoffices[key] = hostoffices[key].active
      ? {
          name: off.department,
          count: off.count,
          active: false,
          id: off.id,
          level: off.level,
          ministry: off.ministry,
          department: off.department,
        }
      : {
          name: off.department,
          count: off.count,
          active: true,
          id: off.id,
          level: off.level,
          ministry: off.ministry,
          department: off.department,
        };
    let queryoffices = [];
    let querycount = 0;
    offices.map((off) => {
      if (off.active) {
        return (
          queryoffices.push(off.id),
          (querycount += off.count),
          setFilterby(intl.get('editor.layer_list.filtered_by_office'))
        );
      }
    });
    setQuery({
      office: { $in: queryoffices },
    });
    setState({
      uskip: 0,
    });
    setUpCount(querycount);
    cleanupQuery(offices, intl.get('editor.layer_list.filtered_by_office'));
  };

  const handleToggleAsc = () => {
    setSortAscending((prev) => !prev);
  };

  const handlePaginationChange = async (e, { activePage }) => {
    if (
      (debouncedValue === '' || debouncedValue === null) &&
      Object.entries(query).length !== 0
    ) {
      setState({
        uactivePage: activePage,
        utake: parseInt(10),
        uskip: parseInt(activePage * 10 - 10),
      });
    }
    if (debouncedValue !== '' && searchedvalue !== '') {
      setState({
        sactivePage: activePage,
        stake: parseInt(10),
        sskip: parseInt(activePage * 10 - 10),
      });
    }
    if (
      (debouncedValue === '' || debouncedValue === null) &&
      Object.entries(query).length === 0
    ) {
      setState({
        activePage: activePage,
        take: parseInt(10),
        skip: parseInt(activePage * 10 - 10),
      });
    }
  };

  const layerTypeIcons = {
    POINT: 'point',
    LINE: 'line',
    POLYGON: 'polygon',
  };

  if (customlayererror) {
    return (
      <Layout>
        <Header as="h3">An Error has occured. Please try again.</Header>
        <Button onClick={handleRetry} icon primary loading={customlayerloading}>
          Retry <Icon name="redo" />
        </Button>
      </Layout>
    );
  }

  const renderTableContent = (layers) => {
    if (layers.length === 0) {
      return (
        <Table.Row>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
      );
    }
    return layers.map((layer) => {
      return (
        <Table.Row key={layer._id}>
          <Table.Cell>
            {layer.isLocked ? (
              <Popup
                content={intl.get('editor.layer_list.locked_tooltip', {
                  name: layer.lockedBy.name,
                })}
                trigger={<Icon name="lock" />}
              />
            ) : (
              <></>
            )}
          </Table.Cell>
          <Table.Cell>
            {layer.nameMM || layer.nameEN || 'No name given to layer.'}
          </Table.Cell>
          <Table.Cell>
            <span
              className={'tgicon tgicon-' + layerTypeIcons[layer.type]}
            ></span>{' '}
            {layer.type}
          </Table.Cell>
          {/* <Table.Cell>{status || ''}</Table.Cell> */}
          <Table.Cell>
            {layer.location && layer.location.nameEN
              ? layer.location.nameEN
              : ''}
          </Table.Cell>
          <Table.Cell>
            {(layer.uploadedBy && layer.uploadedBy.email) ||
              'User not attributed to email'}
          </Table.Cell>
          <Table.Cell>{moment(layer.updatedAt).format('LLL')}</Table.Cell>
          {/* <Grid.Column width={1} style={{ textAlign: 'right' }}>
            <Button primary icon className="red">
              <Icon name="trash"  />
            </Button>
          </Grid.Column> */}
          <Table.Cell width={1} align="right">
            {layer.lockedBy !== null && layer.lockedBy._id !== props.user.id ? (
              <>
                <Dropdown
                  floating
                  icon="ellipsis vertical"
                  className="dropdown elipse"
                >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleNotify(layer._id)}>
                      <span className="tgicon tgicon-notification-bell"></span>
                      Notify to Unlock
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <Dropdown
                  floating
                  icon="ellipsis vertical"
                  className="dropdown elipse"
                >
                  <Dropdown.Menu>
                    {/* <>
                        {
                          roleLevel > 3 ? (<Dropdown.Item as={Link} to={"/editor/upload/" + layer._id}>
                            <span className="tgicon tgicon-edit-layer"></span>
                            {intl.get("editor.layer_list.edit_layer")}
                          </Dropdown.Item>) : null
                        }
                      </> */}

                    <Dropdown.Item
                      as={Link}
                      to={'/editor/upload/' + layer._id}
                      // disabled={roleLevel > 3 ? false : true}
                      disabled={
                        props.user.userLevel.priority === 4 &&
                        props.user.userLevel.accessType === 'EDIT'
                          ? false
                          : `${props.user.office}` ===
                            `${layer && layer.office && layer.office._id}`
                          ? false
                          : true
                      }
                    >
                      <span className="tgicon tgicon-edit-layer"></span>
                      {intl.get('editor.layer_list.edit_layer')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleDeleteLayer(layer._id)}
                      className="redButton"
                      // disabled={props.user.userLevel.priority === 4 && props.user.userLevel.accessType === "EDIT" ? false : `${props.user.office}` === `${layer && layer.office && layer.office._id}` ? false : true}
                      disabled={
                        props.user.userLevel.priority === 4 &&
                        props.user.userLevel.accessType === 'EDIT'
                          ? false
                          : props.user.userLevel.priority < 3
                          ? true
                          : `${props.user.office}` ===
                            `${layer && layer.office && layer.office._id}`
                          ? false
                          : true
                      }
                    >
                      <span className="tgicon tgicon-delete-bin-alt"></span>
                      {intl.get('editor.layer_list.delete_layer')}
                    </Dropdown.Item>
                    {layer.isLocked ? (
                      <Dropdown.Item onClick={() => handleUnlockLayer(layer)}>
                        <Icon name="lock open" />
                        {intl.get('editor.layer_list.unlock_layer')}
                      </Dropdown.Item>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };
  const renderPagination = (count, pactive) => {
    return Math.ceil(count && count / 10) <= 1 ? null : (
      <>
        <Pagination
          activePage={pactive ? pactive : 1}
          boundaryRange={1}
          siblingRange={1}
          onPageChange={handlePaginationChange}
          boundaryRange={boundaryRange}
          size="mini"
          siblingRange={siblingRange}
          ellipsisItem={showEllipsis ? undefined : null}
          firstItem={showFirstAndLastNav ? undefined : null}
          lastItem={showFirstAndLastNav ? undefined : null}
          prevItem={showPreviousAndNextNav ? undefined : null}
          nextItem={showPreviousAndNextNav ? undefined : null}
          totalPages={Math.ceil(count && count / 10)}
        />
      </>
    );
  };

  return !customlayerdata ? (
    <Loader />
  ) : (
    <Layout className="EditorPage">
      <Grid>
        <Grid.Row>
          <Container>
            <Modal
              centered={false}
              open={open}
              onClose={handleClose}
              onOpen={() => setOpen(true)}
            >
              <Modal.Header>Thank you!</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  Layer locked by {notificaton.assignedTo} is now unlocked!
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  onClick={async () => {
                    setNotify(false);
                    await refetch({
                      variables: {
                        skip: state.skip,
                        take: state.take,
                        sortBy,
                        sortAscending,
                      },
                    });
                  }}
                >
                  OK
                </Button>
              </Modal.Actions>
            </Modal>
          </Container>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} align="left">
            <h2>{intl.get('editor.layer_list.all_layers')}</h2>
          </Grid.Column>
          <Grid.Column width={9} align="left" className="filteredValues">
            {filterby !== '' ? (
              <div>
                <h4>
                  <span>
                    Results:&nbsp;
                    <Label className="teal">{upcount}</Label>
                  </span>
                  &nbsp;{filterby}:&nbsp;
                  <Label as="a">
                    {intl.get('editor.layer_list.remove_all_filters')}{' '}
                    <Icon name="delete" onClick={() => removeFilterby()} />
                  </Label>
                </h4>
              </div>
            ) : (
              <></>
            )}
            {levels.map((lev, key) => {
              if (lev.active) {
                return (
                  <Label key={key} as="a" className="teal">
                    {lev.name}
                    <Icon
                      name="delete"
                      onClick={() => handleLevelSelect(lev, key)}
                    />
                  </Label>
                );
              }
            })}
            {locations.map((loc, key) => {
              if (loc.active) {
                return (
                  <Label key={key} as="a" className="teal">
                    {loc.name}
                    <Icon
                      name="delete"
                      onClick={() => handleLocationSelect(loc, key)}
                    />
                  </Label>
                );
              }
            })}
            {offices.map((off, key) => {
              if (off.active) {
                return (
                  <Label key={key} as="a" className="teal">
                    {off.name}
                    <Icon
                      name="delete"
                      onClick={() => handleOfficeSelect(off, key)}
                    />
                  </Label>
                );
              }
            })}
          </Grid.Column>
          <Grid.Column width={3} align="right">
            <Button as={Link} to="/editor/upload" primary>
              <span className="tgicon tgicon-upload-map"></span>{' '}
              {intl.get('editor.layer_list.add_new_layer')}
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} align="left">
            <Input
              icon="search"
              placeholder="Search by LayerName"
              className="LayerSearchInput"
              style={{ width: '100%', marginBottom: '1rem' }}
              value={searchedvalue}
              onChange={(e) => setSearchedvalue(e.target.value)}
            />
            <h4>
              <span className="tgicon tgicon-filter"></span>{' '}
              {intl.get('editor.layer_list.filter_layers')}
            </h4>
            <FilterAccordian
              title={intl.get('editor.layer_list.level')}
              list={levels}
              handleClick={handleLevelSelect}
            />
            <FilterAccordian
              title={intl.get('editor.layer_list.layer_location')}
              list={locations}
              handleClick={handleLocationSelect}
            />
            <FilterAccordian
              title={intl.get('editor.layer_list.layer_department')}
              list={offices}
              handleClick={handleOfficeSelect}
            />
          </Grid.Column>
          <Grid.Column width={12}>
            {customlayerloading ||
            search_layer_loading ||
            layers_by_child_loading ? (
              <Loader />
            ) : (
              <Table striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell
                      onClick={() =>
                        setSortby(
                          languageContext.language === 'my'
                            ? 'nameMM'
                            : 'nameEN',
                        )
                      }
                    >
                      {intl.get('editor.layer_list.layer_name')}
                      &nbsp;
                      {sortBy === 'nameEN' || sortBy === 'nameMM' ? (
                        sortAscending ? (
                          <Icon name="caret up" onClick={handleToggleAsc} />
                        ) : (
                          <Icon name="caret down" onClick={handleToggleAsc} />
                        )
                      ) : (
                        <Icon name="sort" />
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell onClick={() => setSortby('type')}>
                      {intl.get('editor.layer_list.layer_type')}
                      &nbsp;
                      {sortBy === 'type' ? (
                        sortAscending ? (
                          <Icon name="caret up" onClick={handleToggleAsc} />
                        ) : (
                          <Icon name="caret down" onClick={handleToggleAsc} />
                        )
                      ) : (
                        <Icon name="sort" />
                      )}
                    </Table.HeaderCell>
                    {/* <Table.HeaderCell>{intl.get("editor.layer_list.layer_status")}</Table.HeaderCell> */}
                    <Table.HeaderCell>
                      {intl.get('editor.layer_list.layer_location')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {intl.get('editor.layer_list.uploaded_by')}
                    </Table.HeaderCell>
                    <Table.HeaderCell onClick={() => setSortby('updatedAt')}>
                      {intl.get('editor.layer_list.last_updated')}
                      &nbsp;
                      {sortBy === 'updatedAt' ? (
                        sortAscending ? (
                          <Icon name="caret up" onClick={handleToggleAsc} />
                        ) : (
                          <Icon name="caret down" onClick={handleToggleAsc} />
                        )
                      ) : (
                        <Icon name="sort" />
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {searchedvalue !== '' ? (
                    renderTableContent(searcheddata)
                  ) : updatedata && !!updatedata.length ? (
                    renderTableContent(updatedata)
                  ) : hostdata && !!hostdata.length ? (
                    renderTableContent(hostdata)
                  ) : (
                    <></>
                  )}
                </Table.Body>
              </Table>
            )}
            <Grid.Row style={{ marginBottom: '1rem' }}>
              <Grid.Column>
                {searchedvalue !== ''
                  ? renderPagination(
                      searchedcount,
                      parseFloat(state.sactivePage),
                    )
                  : !!upcount && filterby !== ''
                  ? renderPagination(upcount, parseInt(state.uactivePage))
                  : renderPagination(count, parseInt(state.activePage))}
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  );
}

export default EditorPage;

// ACCEPT LANGUAGE
// - Myanmar = my
// - English = en
