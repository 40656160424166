import { useEffect, useState } from 'react';

export function useDebounce(value, wait = 0) {
  let [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    let tid = setTimeout(() => setDebouncedValue(value), wait);
    return () => clearTimeout(tid);
  }, [value, wait]);
  return debouncedValue;
}
