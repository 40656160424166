import universe from 'universe';
import {parseBins,makeBinName,isNumber,sortGroupBys} from './';
// import Moment from 'react-moment';
import * as moment from 'moment';

export default (layerName,data,chartGroupBys,lengendGroupBys,filters) => {
	console.log(data);
	// console.log(chartGroupBys);
	console.log(lengendGroupBys);
	// console.log(filters);


	// Build Settings and Functions for Generated Columns
	const generatedColsSettings = {};
	let uniGroupBys = [];


	chartGroupBys.forEach(groupBy => {
		const colName = "chart_" + groupBy.field.nameEN + (groupBy.grouping ? "_"+groupBy.grouping : "");
		let colFunc = null;
	
		// console.log(groupBy);
		if (groupBy.bins && groupBy.bins.length > 0) {
			// const bins = parseBins(groupBy.bins);
			const bins = groupBy.bins;
			colFunc = (row) => {
				// console.log(row,groupBy.field.nameEN);
				for (let i = 0; i < bins.length; i++) {
					if (isNumber(bins[i].max) && isNumber(bins[i].min)) {
						if (+row[groupBy.field.nameEN] >= +bins[i].min && +row[groupBy.field.nameEN] <= +bins[i].max) {
							// console.log("Making bins with numeric value",row[groupBy.field.nameEN],bins[i].key);
							return makeBinName(+bins[i].min,+bins[i].max);
						}
					} else if (row[groupBy.field.nameEN] === bins[i].key) {
							// console.log("Making bins with categorical value",row[groupBy.field.nameEN],bins[i].key);
							return makeBinName(null,null,bins[i].key);
					}
				}
				return "--Others--";
					// if (row[groupBy.field.nameEN] >= +bins[i].min && row[groupBy.field.nameEN] <= +bins[i].max) 
					// 	return bins[i].min + " - " + bins[i].max;
			}
		} 

		if (groupBy.grouping) {
			if (groupBy.field.fieldType === 'DATE') {
				console.log(groupBy);
				const HourGroupingRegex = /\d+hrs/g;

				// Parse as Date
				if (groupBy.grouping === 'DatePicker') {
					// colFunc = (row) => moment( new Date(row[groupBy.field.nameEN])).format('LL');
					colFunc = (row) => moment(row[groupBy.field.nameEN]).format('LL');
				} 
				// Parse as Chunks of Hours
				else if (HourGroupingRegex.test(groupBy.grouping)) {
					const chunk = groupBy.grouping.split('hrs')[0];
					// const chunk = 6;
					let hourCounter = 0;
					const hourBins = [];
					while (hourCounter*chunk < 24) {
						hourBins.push({
							min:hourCounter*chunk,
							max:(hourCounter+1)*chunk > 24 ? 24 : (hourCounter+1)*chunk
						})
						hourCounter +=1;
					}
					colFunc = (row) => {
						for (let i = 0; i < hourBins.length; i++) 
							if (moment(row[groupBy.field.nameEN]).hour() >= hourBins[i].min 
								&& moment(row[groupBy.field.nameEN]).hour() <= hourBins[i].max) 
								return moment({hour:hourBins[i].min}).format("HHmm") 
									+ " - " 
									+ (hourBins[i].max === 24  ? "2400" : moment({hour:hourBins[i].max}).format("HHmm"));
					}
					// Parse as Month

					// Parse as Day of Week

					// Parse as Quarter

					// Parse as Year
				}
			}
		}

		if (colFunc) {
			console.log("Chart Filters Groupbys with colFunc",colFunc)
			generatedColsSettings[colName] = colFunc;
			// uniGroupBys.chart[colName] = null;
			// uniGroupBys.chart.push(colName);
			uniGroupBys.push({category: 'chart', dataType: groupBy.field.fieldType, title: groupBy.field.nameEN, groupBy: colName})
		} else {

			console.log("Chart Filters Groupbys without colFunc")
			// uniGroupBys.chart.push(groupBy.field.nameEN);
			uniGroupBys.push({category: 'chart', dataType: groupBy.field.fieldType, title: groupBy.field.nameEN, groupBy: groupBy.field.nameEN})

		}

	})

	lengendGroupBys.map(groupBy => {
		console.log(groupBy);
		const colName = "legend_" + groupBy.field.nameEN 
			+ (groupBy.bins.length > 0 && groupBy.type === 'RADIUS' ? "_radius" : "") 
			+ (groupBy.bins.length > 0 && groupBy.type === 'COLOR' ? "_color" : "") 
			+ (groupBy.bins.length > 0 && groupBy.type === 'SYMBOL' ? "_symbol" : "");

		let colFunc = null;

		if (groupBy.bins.length > 0) {
			let bins = [];
			if (groupBy.bins.length > 0) bins = groupBy.bins;

			// const bins = parseBins(groupBy.colorBins);
			console.log(bins);
			colFunc = (row) => {
				// console.log(row,groupBy.field.nameEN);
				for (let i = 0; i < bins.length; i++) {
					if (isNumber(bins[i].max) && isNumber(bins[i].min)) {
						if (+row[groupBy.field.nameEN] >= +bins[i].min && +row[groupBy.field.nameEN] <= +bins[i].max) {
							// console.log("Making bins with numeric value",row[groupBy.field.nameEN],bins[i].key);
							return makeBinName(+bins[i].min,+bins[i].max);
						}
					} else if (row[groupBy.field.nameEN] === bins[i].key) {
							// console.log("Making bins with categorical value",row[groupBy.field.nameEN],bins[i].key);
							return makeBinName(null,null,bins[i].key);
					}
				}
				return "--Others--";
			}
		} 
		
		if (colFunc) {
			console.log("Legend Groupbys with colFunc")

			generatedColsSettings[colName] = colFunc;
			// uniGroupBys.legend[colName] = null;
			// uniGroupBys.legend.push(colName);
			uniGroupBys.push({category: 'legend', dataType: groupBy.field.fieldType, title: groupBy.field.nameEN, groupBy: colName})
		} else {
			console.log("Legend Groupbys without colFunc")
			// uniGroupBys.legend[groupBy.field.nameEN] = null;
			// uniGroupBys.legend.push(groupBy.field.nameEN);
			uniGroupBys.push({category: 'legend', dataType: groupBy.field.fieldType, title: groupBy.field.nameEN, groupBy: groupBy.field.nameEN})
		}

	})

	if (!lengendGroupBys.length) {
		generatedColsSettings[layerName] = () => layerName;
		uniGroupBys.push({category: 'legend', dataType: 'TEXT', title: layerName, groupBy: layerName})
	}


	// const makeUniGroupBy = (uni,varName,returnGroupBysCollector) => {
	// const makeUniGroupBy = (uni,varName) => {

	// 	return uni.query({
	// 			groupBy:varName,
	// 			columns: {
	// 				$count: true
	// 			}
	// 		})
	// }


	return universe(data,{generatedColumns:generatedColsSettings})
		.then(async (uni) => {
			console.log(uni);
			uniGroupBys.forEach(gb => {
				console.log(gb)
				gb.query = uni.query({
						groupBy:gb.groupBy,
						columns: {
							$count: true
						}
					})
			})

			console.log(generatedColsSettings);
			const getUniGroupBys = () => Promise.all(uniGroupBys.map(gb => gb.query))
				.then((unis) => {
					console.log(unis);
					return unis.map(uni => uni.data)
				})
				.then((data) => {
					console.log(data,uniGroupBys);
					const returnObj = data.map((gb,i) => {
						return gb.map((dataGB,j) => {
							return {
								groupBy: uniGroupBys[i].groupBy, 
								category: uniGroupBys[i].category, 
								dataType: uniGroupBys[i].dataType,
								title: uniGroupBys[i].title, 
								...dataGB}
						})
					})
					return returnObj.map(gb => sortGroupBys(gb));
				})

			console.log(uniGroupBys);
			const returnObj = {
				uni: uni,
				groupBys: await getUniGroupBys()
			}
			console.log(returnObj);
			return returnObj;
		});
	
}