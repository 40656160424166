import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Grid, Form, Dropdown } from 'semantic-ui-react';
import { allowedLevelsEffect ,levelOptions as options } from '../../utils';
import GET_LOCATIONS from '../../graphql/getLocations';
import Loader from '../Loader';
import intl from 'react-intl-universal';

function LocationFormFields (props) {

	const { values, errors, onChange } = props;

	const [parents, setParents] = useState([]);
	const [allowedLevel, setAllowedLevel] = useState('');

	const locationsQuery = useQuery(GET_LOCATIONS);

	const handleDropdownChange = (name, value) => {
	  return onChange({ target: { name, value, focus: () => false }, preventDefault: () => false })
	}

	useEffect(() => {
    if (locationsQuery.data) {
      const { getLocations } = locationsQuery.data.commonQueries;
      const parents = getLocations.map(location => ({
        value: location._id,
        text: location.nameMM || location.nameEN,
        level: location.level
      }));

      setParents(parents);
    }
  }, [locationsQuery.data]);

	allowedLevelsEffect(values.level, setAllowedLevel);

	console.log("values", values);

	return (
		locationsQuery.loading
		?
		<Loader />
      	:
		<>
			<Grid.Row>
			  <Grid.Column textAlign="left">
			    <Form.Input label={intl.get("admin.location_fields.name_mm")} name="nameMM" value={values.nameMM} onChange={onChange} placeholder="New Name (MM)" error={errors.nameMM} required />
			  </Grid.Column>
			  <Grid.Column textAlign="left">
			    <Form.Input label={intl.get("admin.location_fields.name_en")} name="nameEN" value={values.nameEN} onChange={onChange} placeholder="New Name (EN)" error={errors.nameEN} required />
			  </Grid.Column>
			</Grid.Row>
			<Grid.Row>
			  <Grid.Column textAlign="left">
			    <Form.Field required>
			      <label>{intl.get("admin.location_fields.level")}</label>
			      <Dropdown
			        label="Level"
			        name="level"
			        options={options}
			        placeholder="Choose a level"
			        selection
			        error={!!errors.level}
			        fluid
			        value={values.level}
			        onChange={(event, { value }) => handleDropdownChange('level', value)} />
			    </Form.Field>
			  </Grid.Column>
			  <Grid.Column textAlign="left">
			    <Form.Field required>
			      <label>{intl.get("admin.location_fields.parent")}</label>
			      <Dropdown
			        label="Parent"
			        name="parent"
			        options={parents.filter(o => o.level === allowedLevel)}
			        placeholder="Parent Location"
			        selection
			        error={!!errors.parent}
			        fluid
			        disabled={allowedLevel !== '' ? false : true}
			        value={typeof(values.parent) === 'object' && values.parent !== null ? values.parent._id : values.parent}
			        onChange={(event, { value }) => handleDropdownChange('parent', value)} />
			    </Form.Field>
			  </Grid.Column>
			</Grid.Row>
			<Grid.Row>
			  <Grid.Column textAlign="left">
			    <Form.Input label={intl.get("admin.location_fields.latitude")} name="lat" value={values.lat} onChange={onChange} placeholder="Latitude" error={errors.lat} required />
			  </Grid.Column>
			  <Grid.Column textAlign="left">
			    <Form.Input label={intl.get("admin.location_fields.longitude")} name="lon" value={values.lon} onChange={onChange} placeholder="Longitude" error={errors.lon} required />
			  </Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column textAlign="left">
				  <Form.Input label={intl.get("admin.location_fields.placecode")} name="placeCode" value={values.placeCode} onChange={onChange} placeholder="New Place Code" error={errors.placeCode} required />
				</Grid.Column>
			</Grid.Row>
		</>
	)

}

export default LocationFormFields;