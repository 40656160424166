export default (featureData) => {
    return featureData.map(feature => {
      // console.log(feature.geometry.coordinates);
      try {
        if (!feature.geometry)
          return {...feature.properties}
        else {
          const coords = feature.geometry.coordinates; 
          if (Array.isArray(coords[0]) && feature.geometry.type==="MultiPolygon") 
            return {"Geometry":feature.geometry.type,"Vertices":coords.flat(2).length,...feature.properties}
          else if (Array.isArray(coords[0])) 
            return {"Geometry":feature.geometry.type,"Vertices":coords.flat().length,...feature.properties}
          else
            return {"Latitude":coords[1], "Longitude":coords[0],...feature.properties}
        }
      } catch(e) {
        console.log("Error parsing geometry", feature.geometry)
        return {...feature.properties}
      }
    })
  }