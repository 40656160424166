import gql from 'graphql-tag';

export default gql`
  mutation createUpdateLocation($officeInput: OfficeInput!){
    commonMutations{
      createUpdateOffice(officeInput: $officeInput){
        result
        message
        isError
        isSuccess
      }
    }
  }
`;