import React, { createRef, Component } from "react";
// import PropTypes from 'prop-types'
// import styles from './MapViewComponent.scss'
import styles from "../../styles/MapView.scss";
import Control from "react-leaflet-control";
import ReactSVG from "react-svg";
// import LoadingBar from 'loading-svg/loading-bars.svg'
import Loader from "../Loader";
import {
  Map,
  CircleMarker,
  Polyline,
  Popup,
  GeoJSON,
  Marker,
  Tooltip,
  TileLayer,
  LayersControl,
  MapControl,
  ScaleControl,
  withLeaflet,
  ZoomControl
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {
  scales,
  getScaleFunc,
  HeatmapLayer,
  getIcon,
  getIconSVG,
  defaultMapStylings,
  defaultMapStylingsForMutation
} from "../../utils";
import { HexbinLayer } from "react-leaflet-d3";
import "leaflet-easyprint";
import ReactDOMServer from "react-dom/server";
import MeasureControlDefault from "react-leaflet-measure";
import { CoordinatesControl } from "react-leaflet-coordinates";
import { Icon } from "semantic-ui-react";

class PrintControl extends MapControl {
  createLeafletElement(props) {
    return L.easyPrint(props);
  }

  componentDidMount() {
    const { map } = this.context;
    if (map) this.leafletElement.addTo(map);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.mapRef) {
      this.leafletElement.addTo(nextProps.mapRef.contextValue.map);
    } else console.log("Still loading data");
  }

  printMap(size, filename) {
    this.leafletElement.printMap(`${size} page`, filename);
  }
}

const measureOptions = {
  position: "topright",
  primaryLengthUnit: "kilometers",
  secondaryLengthUnit: "miles",
  primaryAreaUnit: "sqmeters",
  secondaryAreaUnit: "acres",
  activeColor: "#db4a29",
  completedColor: "#9b2d14"
};

const MeasureControl = withLeaflet(MeasureControlDefault);

const { BaseLayer } = LayersControl;
const WrappedHexbinLayer = withLeaflet(HexbinLayer);

const getSymbology = (type, style, feature) => {
  // console.log(feature.properties,style)
  const symbologyValue =
    style.varToStyleOn && style.varToStyleOn[type]
      ? feature.properties[style.varToStyleOn[type]]
      : "";

  // console.log(symbologyValue);

  if (type === "SYMBOL") return style.symbol(symbologyValue);
  if (type === "RADIUS") return style.radius(symbologyValue);
  if (type === "COLOR") return style.color(symbologyValue);
};

// const position = [16.88225, 96.0533]
const zoom = 11;

class MapViewComponent extends Component {

  constructor(props) {
    super(props);
    this.printMap = this.printMap.bind(this);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  printMap() {
    this.printControl.printMap("A4Landscape", "MyFileName");
  }

  pointToLayerGenFunction = style => {
    const returnFunc = (feature, latlng) => {
      // const iconName = feature.properties['Type']
      //   ? (feature.properties['Type'].toLowerCase().split(' ').join('_'))
      //   : null;

      // const filterChecked = this.filterChecked(feature,groupInLegend)
      // const filterChecked = true;

      // const symbol = style.symbol(feature.properties[style.varToStyleOn['SYMBOL']]);
      // const radius = style.radius(feature.properties[style.varToStyleOn['RADIUS']]);
      // const color = style.color(feature.properties[style.varToStyleOn['COLOR']]);
      const symbol = getSymbology("SYMBOL", style, feature);
      const radius = getSymbology("RADIUS", style, feature);
      const color = getSymbology("COLOR", style, feature);
      const icon = getIcon(symbol);
      console.log("Get Icon .......", icon);
      // console.log(feature,style,symbol);

      if (!!icon) {
        const iconToUse = L.divIcon({
          className: "ship-div-icon",
          html: ReactDOMServer.renderToStaticMarkup(
            getIconSVG(symbol, radius * 4, color)
          )
        });
        return L.marker(latlng, {
          icon: iconToUse,
          opacity: style.alpha / 100
        });
      } else if (symbol === "circle" || !symbol) {
        let softFilterAlpha = this.getSoftFilterAlpha(feature);
        return L.circleMarker(latlng, {
          color: color,
          fillColor: color,
          fillOpacity:
            (softFilterAlpha * style.alpha * style.fillMultiplier) / 100,
          opacity: (softFilterAlpha * style.alpha) / 100,
          radius: radius,
          weight: style.weight
        });
      } else {
        this.setState({ hasError: true });
      }

      // }

      // }
    };
    return returnFunc;
  };

  onEachFeatureGenFunction = (varTitle, tooltipVars) => {
    const returnFunc = (feature, layer) => {
      let tooltipText =
        '<h3 className="mm">' + this.props.l10n(varTitle, true) + "</h3><br/>";
      tooltipVars.forEach(v => {
        if (v.type === "PhotoUrl") {
          tooltipText +=
            '<p className="mm"><strong>' +
            this.props.l10n(v.nameEN, true) +
            ":</strong> " +
            '<img src="' +
            feature.properties[v.nameEN] +
            '">' +
            "</p>";
        } else {
          const varText = String(feature.properties[v.nameEN]).split("|")[1]
            ? String(feature.properties[v.var]).split("|")[1]
            : feature.properties[v.nameEN];
          tooltipText +=
            '<p className="mm"><strong>' +
            this.props.l10n(v.nameEN, true) +
            " - </strong> " +
            this.props.l10n(varText, true) +
            "</p>";
        }
      });
      layer.bindTooltip(tooltipText, { className: "maptooltip" });
    };
    return returnFunc;
  };

  filterChecked = (feature, groupInLegend) => {
    // return groupInLegend ? feature.properties.checked : true;
    return true;
  };

  // This is for filtering just to highlight, not remove from the map
  softFilter = feature => {
    const filters = this.props.softFilters;

    // If there are no filters, then includedInFilter is an empty array
    // If there are filters, then includedInFilter is an array of false of length = number of filtered columns
    const includedInFilter = Array(
      Object.keys(filters).length ? Object.keys(filters).length : 0
    ).fill(false);

    // For each filtered column
    Object.keys(filters).forEach((k, i) => {
      // We take the values for which the filters have been turned on
      const filterValues = filters[k].filterTerm.map(f => f.value);

      // If the current feature we are examining is included in the array of filter values for this column,
      //  we set includedInFilter at the index of the column to true
      if (
        filterValues.length === 0 ||
        filterValues.includes(feature.properties[k])
      )
        includedInFilter[i] = true;
    });

    // If there are no filters, we return true
    // If there are filters, we return true only if every item in the includedInFilter array is true
    return includedInFilter.length
      ? includedInFilter.every(item => item)
      : true;
  };

  getSoftFilterAlpha = feature => (this.softFilter(feature) ? 1 : 0.1);

  resetZoom = () => {
    if (this.map) {
      this.map.leafletElement.setView(this.props.center, zoom);
    }
  };

  tryRequire = path => {
    try {
      //  return require(`${path}`);
      return require(path);
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  layerRefs = [];

  render() {

    const showLayer = layer => {
      const { dataSet, type, tooltipVars, mapStyling, nameEN, nameMM } = this.props.data[
        layer._id
      ];

      const layerTitle = this.props.language === 'my' ? nameMM : nameEN;

      // console.log(dataSet);
      let newRef = createRef();
      this.layerRefs.push(newRef);

      if (mapStyling.scale && typeof mapStyling.scale === "string") {
        const scaleType = mapStyling.scale.split(".")[1];
        mapStyling.scaleFunc = scales[scaleType];
      }

      // console.log(mapStyling);

      const hexBinOptions = {
        colorScaleExtent: [1, undefined],
        radiusScaleExtent: [1, undefined],
        colorRange: ["#f7fbff", "#ff0000"],
        radiusRange: [5, 12],
        radius: 12,
        opacity: 0.5,
        duration: 200
      };

      if (type === "POINT") {
        // console.log(mapStyling);

        const style = {
          color: getScaleFunc(mapStyling, "COLOR"),
          alpha: layer.alpha,
          weight: mapStyling.weight,
          // radius: (mapStyling.radius.min + mapStyling.radius.max)/2.0,
          radius: getScaleFunc(mapStyling, "RADIUS"),
          symbol: getScaleFunc(mapStyling, "SYMBOL"),
          fillMultiplier: mapStyling.fillMultiplier
            ? mapStyling.fillMultiplier
            : defaultMapStylings.fillMultiplier,
          varToStyleOn: mapStyling.stylingColumns.length
            ? {
                RADIUS:
                  mapStyling.stylingColumns.filter(
                    col => col.type === "RADIUS"
                  ).length > 0
                    ? mapStyling.stylingColumns.filter(
                        col => col.type === "RADIUS"
                      )[0].field.nameEN
                    : "",
                SYMBOL:
                  mapStyling.stylingColumns.filter(
                    col => col.type === "SYMBOL"
                  ).length > 0
                    ? mapStyling.stylingColumns.filter(
                        col => col.type === "SYMBOL"
                      )[0].field.nameEN
                    : "",
                COLOR:
                  mapStyling.stylingColumns.filter(col => col.type === "COLOR")
                    .length > 0
                    ? mapStyling.stylingColumns.filter(
                        col => col.type === "COLOR"
                      )[0].field.nameEN
                    : ""
              }
            : null
          // mapStyling.stylingColumns[0].field.nameEN
        };

        if (layer.mapVizType === "heatmap") {
          const pointsForHeatmap = dataSet[0].features.map(
            d => d.geometry.coordinates
          );
          return (
            <HeatmapLayer
              // fitBoundsOnLoad
              // fitBoundsOnUpdate
              key={"heatmap" + layer._id}
              points={pointsForHeatmap}
              longitudeExtractor={m => m[0]}
              latitudeExtractor={m => m[1]}
              intensityExtractor={m => 100.0}
            />
          );
        } else if (layer.mapVizType === "hexbins") {
          const geoJSONData = {
            type: "FeatureCollection",
            features: dataSet[0].features.map(d => ({ type: "Feature", ...d }))
          };
          // console.log(geoJSONData);
          return (
            <WrappedHexbinLayer
              data={geoJSONData}
              {...hexBinOptions}
              key={"hexbins" + layer._id}
            />
          );
        } else
          return (
            <GeoJSON
              key={Math.random() * 1000000}
              data={dataSet[0].features.map(d => {
                return { type: "Feature", ...d };
              })}
              ref={newRef}
              pointToLayer={this.pointToLayerGenFunction(style).bind(this)}
              onEachFeature={this.onEachFeatureGenFunction(
                layerTitle,
                tooltipVars
              ).bind(this)}
            />
          );
      } else {
        let style = null;

        // console.log(dataSet[0].features.map(d => {return {type:"Feature",d}}));
        const geoJSONData = {
          type: "FeatureCollection",
          features: dataSet[0].features.map(d => ({ type: "Feature", ...d }))
        };
        console.log(geoJSONData);

        style = feature => {
          const varToStyleOn = mapStyling.stylingColumns.length
            ? mapStyling.stylingColumns[0].field.nameEN
            : null;
          const softFilterAlpha = this.getSoftFilterAlpha(feature);
          const colorVar = feature.properties[varToStyleOn];
          return {
            color: getScaleFunc(mapStyling, "COLOR")(colorVar),
            weight: mapStyling.weight,
            fillOpacity:
              (softFilterAlpha *
                layer.alpha *
                (mapStyling.fillMultiplier
                  ? mapStyling.fillMultiplier
                  : defaultMapStylings.fillMultiplier)) /
              100,
            opacity: (softFilterAlpha * layer.alpha) / 100
          };
        };

        return (
          <GeoJSON
            key={Math.random() * 10000}
            data={geoJSONData}
            ref={newRef}
            style={style}
            filter={d => this.filterChecked(d)}
            onEachFeature={this.onEachFeatureGenFunction(
              layerTitle,
              tooltipVars
            ).bind(this)}
          />
        );
      }
    };

    const showAllLayers = (layers, layerSortOrder) => {
      console.log("showing layers on map...", layers);
      console.log("layer sort order for map...", layerSortOrder);
      layers = layers.filter(k => k.showing);

      let newLayers = [];

      if (
        layerSortOrder &&
        layerSortOrder.length &&
        layerSortOrder.length === layers.length
      ) {
        for (let i = 0; i < layerSortOrder.length; i++) {
          layers.forEach(l => {
            if (l._id === layerSortOrder[i]._id) newLayers.push(l);
          });
        }
        // layers = newLayers;
      }

      // console.log(newLayers);

      return newLayers.map(l => showLayer(l)).reverse();
      // return newLayers.map(l => showLayer(l));
    };

    const isLoading = !!Object.keys(this.props.data).length ? false : true;
    const mapHeight = this.props.height ? this.props.height : 600;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Map View is unavailable. There are errors in the data</h1>;
    }
    return (
      <div className="MapViewComponent MapView">
        <div id="map-container">
          {/* {!!isLoading
          ? <Loader /> */}
          {/* : <div> */}
          <Map
            center={this.props.center}
            zoom={zoom}
            zoomControl={false}
            style={{ height: mapHeight, width: this.props.width }}
            ref={ref => {
              this.map = ref;
            }}
            maxZoom={17}
          >
            <LayersControl position="topright" size="small">
              <BaseLayer name="Simple">
                <TileLayer
                  // attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community"
                  // url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
                  // crossOrigin={null}
                />
              </BaseLayer>
              <BaseLayer name="OpenStreetMap Color">
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </BaseLayer>
              <BaseLayer name="Satellite">
                <TileLayer
                  attribution="&amp;Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />
              </BaseLayer>
              {/* <BaseLayer name="Mapbox Light">
                <TileLayer
                  id='mapbox.light'
                  attribution='Source: Mapbox'
                  url={"https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=" + mapBoxAccessToken}
                />
              </BaseLayer>
              <BaseLayer name="Mapbox Dark">
                <TileLayer
                  id='mapbox.dark'
                  attribution='Source: Mapbox'
                  url={"https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=" + mapBoxAccessToken}
                />
              </BaseLayer> */}

              <BaseLayer checked name="Carto Light">
                <TileLayer
                  id="carto.positron"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url={
                    "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                  }
                />
              </BaseLayer>
              <BaseLayer name="Carto Dark">
                <TileLayer
                  id="carto.dark"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url={
                    "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                  }
                />
              </BaseLayer>
            </LayersControl>

            {/* <PrintControl ref={(ref) => { this.printControl = ref; }} position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} /> */}
            <PrintControl
              position="topright"
              sizeModes={["Current", "A4Portrait", "A4Landscape"]}
              hideControlContainer={true}
              title="Export as PNG"
              exportOnly
              mapRef={this.map}
            />
            <Control position="topright">
              <button
                onClick={this.resetZoom.bind(this)}
                className="leaflet-control-reset"
              >
                <Icon name="home" size="small" />
              </button>
            </Control>

            <MeasureControl {...measureOptions} />
            <CoordinatesControl position="bottomright" coordinates="degrees" />
            <ScaleControl position="bottomright" />
            <ZoomControl position="topright" />

            {isLoading ? (
              <></>
            ) : (
              showAllLayers(
                this.props.layersControlsData,
                this.props.layerSortOrder
              )
            )}
          </Map>
        </div>
      </div>
    );
  }
}

// MapViewComponent.propTypes = {}

// MapViewComponent.defaultProps = {}

export default MapViewComponent;
