import React, { useState, useMemo } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Header, Icon, Segment, Modal, Message } from 'semantic-ui-react';
import GET_USERS from '../graphql/usersQuery';
import GET_SINGLE_USER from '../graphql/singleUserQuery';
import UPDATE_USER from '../graphql/createUpdateUser';
import DELETE_USER from '../graphql/deleteUser';
import { useForm, shouldLogState as SHOULD_LOG_STATE, validate } from '../utils';
import UserFormFields from '../components/admin/UserFormFields';
import intl from 'react-intl-universal';

import Loader from '../components/Loader';
import Layout from '../components/Layout';

function UserEditorPage(props) {
  const { history } = props;
  const { id } = props.match.params;
  const { loading, data } = useQuery(GET_SINGLE_USER, { variables: { id } });
  const [getLazySingleUser] = useLazyQuery(GET_SINGLE_USER);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSubmitting] = useState(false);
  const [isEditingPassword, setEditingPassword] = useState(false);

  const initialState = {
    email: '',
    phone: '',
    name: '',
    position: '',
    rank: '',
    otpRequired: false,
    userLevel: '',
    password: '',
    office: '',
    location: ''
  }

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: _ => {
      setIsUpdatingUser(false);
      setIsError(false);
      setErrorMessage({});
      setFormErrors({});
      setEditingPassword(false);
    },
    onError: (error) => {
      setIsError(true);
      setErrorMessage(error);
    },
    errorPolicy: 'all',
  });

  const [deleteUser, deleteUserMutation] = useMutation(DELETE_USER, {
    onCompleted: (data) => {
      const { isSuccess } = data.userMutations.deleteUser;
      if (isSuccess) {
        console.log(data);
        history.push('/admin/users');
      }
    },
    refetchQueries: [
      { query: GET_USERS }
    ],
    onError: (error) => {
      console.log(error);
      setIsError(true);
    },
    errorPolicy: 'all',
  });

  const { onSubmit, onChange, overrideValues, values } = useForm((values) => {
    const validation = validate(values);
    if (validation.isValid) {
      console.log('isValid', values);
      const userInput = {
        ...values,
      };

      if (!values.password) {
        delete userInput.password;
      }

      setIsUpdatingUser(true);
      updateUser({
        variables: { userInput },
        refetchQueries: [
          { query: GET_SINGLE_USER, variables: { id } },
          { query: GET_USERS }
        ]
      });
    }
    else {
      setFormErrors(validation.errors);
    }

  }, initialState, SHOULD_LOG_STATE);

  const handleRefresh = () => {
    console.log('handleRefresh');
    getLazySingleUser({ variables: { id } });
  }

  const handleHardReload = () => window.location.reload();

  const updateDataOnly = () => {
    if (data) {
      const user = data.userQueries.getUserById;
      console.log(user);
      const userLevel = (user && user.userLevel && user.userLevel._id) || '';
      const office = (user && user.office && user.office._id) || '';
      const location = (user && user.location && user.location._id) || '';
      const values = {
        ...user,
        userLevel,
        office,
        location,
      }
      console.log(values);
      overrideValues(values);
    }
  }

  useMemo(updateDataOnly, [data])

  const handleDelete = () => {
    console.log(id);
    deleteUser({ variables: { id } })
  }

  if (isError) {
    return (
      <>
        <Header as="h3">An Error has occured. Please try again.</Header>
        <p>{JSON.stringify(errorMessage)}</p>
      </>
    )
  }

  return (
    loading
      ?
      <Loader />
      :
      <Layout>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column textAlign="left">
              <Button primary as={Link} to="/admin/users" icon>
                <Icon name="triangle left" />Back to Users
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Header as="h3">{intl.get("admin.edit_user")}</Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              {process.env.NODE_ENV !== 'development' && <Button primary onClick={handleRefresh} icon>
                Reload{' '}<Icon name="redo" />
              </Button>}
              {process.env.NODE_ENV === 'development' && <Button primary onClick={handleHardReload} icon>
                Refresh{' '}<Icon name="redo" />
              </Button>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment.Group>
          <Segment>
            <Segment.Group>
              <Segment as={Form} onSubmit={onSubmit} loading={isUpdatingUser} autoComplete="off" error={!!formErrors}>
                <Grid columns="equal">
                  <UserFormFields values={values} errors={formErrors} onChange={onChange} actionType="edit" />
                  {Object.keys(formErrors).length === 0 && formErrors.constructor === Object ? false :
                    <Grid.Row>
                      <Grid.Column textAlign="left">
                        <Message error icon="warning sign" content={<ul>
                          {Object.keys(formErrors).map(key => formErrors[key].map((error, index) => <li key={index}>{error}</li>))}
                        </ul>} />
                      </Grid.Column>
                    </Grid.Row>
                  }
                </Grid>
              </Segment>
            </Segment.Group>
            <Segment.Group>
              <Segment>
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      <Button className="red" onClick={() => setIsModalOpen(true)} icon disabled={isUpdatingUser}>{intl.get("button.delete")} <Icon name="trash" /></Button>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                      <Button primary onClick={onSubmit} disabled={isUpdatingUser} icon>{intl.get("button.update")} <Icon name="check" /></Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Segment.Group>
          </Segment>
        </Segment.Group>
        <Modal open={isModalOpen} closeOnDimmerClick={deleteUserMutation.loading}>
          <Modal.Header>{intl.get("admin.delete_user_confirm")}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Button className="red" onClick={handleDelete} icon disabled={deleteUserMutation.loading}>{intl.get("button.delete")} <Icon name="trash" /></Button>
              <Button onClick={() => setIsModalOpen(false)} icon disabled={deleteUserMutation.loading}>{intl.get("button.cancel")} <Icon name="cancel" /></Button>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Layout>
  )
}

export default UserEditorPage;