import gql from 'graphql-tag';

export default gql`
query QLayer(
  $where: JSON
  $skip: Int
  $take: Int
  $sortBy: PROPERTIES
  $sortAscending: Boolean
) {
  layersQuery {
    getLayers(
      skip: $skip
      take: $take
      where: $where
      sortBy: $sortBy
      sortAscending: $sortAscending
    ) {
      _id
      isLocked
      lockedBy {
        _id
        email
      	name
      }
      nameEN
      nameMM
      source
      location {
        _id
        level
        nameMM
        nameEN
        center {
          lat
          lon
        }
      }
      office {
        _id
        level
        ministry
      }
      type
      uploadedBy {
        email
      }
      publishedBy {
        email
      }
      submitDate
      publishDate
      createdAt
      updatedAt
      dateRange {
        start
        end
      }
      uploadStep
    }
  }
}
`;