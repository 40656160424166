
import gql from 'graphql-tag';

export default gql`
query layerById($id: String!){
  layersQuery{
    getLayerByID(id: $id){
        _id
        isLocked
        lockedBy {
          _id
          email
          name
        }
        nameEN
        nameMM
        source
       location{
         _id
         level
         nameEN
         nameMM
         center{
           lat
           lon
         }
       }
       office{
         _id
         level
         ministry
       }
       type
       uploadedBy{
         email
       }
       publishedBy{
         email
       }
       chartVars{
         field{
           _id
           fieldType
           nameEN
         }
         bins{
           max
           min
           key
           value
         }
         grouping
         
       }
       tooltipVars{
         _id
         fieldType
         nameEN
         nameMM
       }
       tableVars{
         _id
         fieldType
         nameEN
         nameMM
       }
       mapStyling{
         radius{
           min
           max
         }
         weight
         fillMultiplier
         symbol
         color
         stylingColumns{
           field{
             _id
             fieldType
             nameEN
             nameMM
           }
           type
           bins{
             max
             min
             key
             value
           }
           func
         }
       }
       submitDate
       publishDate
       createdAt
       updatedAt
       dateRange{
         start
         end
       }
       uploadStep
       features{
         type
         properties{
           field{
             _id
             fieldType
             nameEN
             nameMM
           }
           value
         }
         geometry{
           type
           coordinates
         }
       }
     }
  }
}
`;