import {getKeyValuesForProperties} from './'

// This function spreads the properties out together with geometry in the return object

export default (data) => {
	return getKeyValuesForProperties(data).map((d) => {
		return {
			...d.properties,
			geometry: d.geometry
		}
	});
}
