import React from 'react'
import { getIcon } from './'

export default (symbol,width,color) => {

const iconPathData = getIcon(symbol);

const scaleFactorIcon = width/512


return <svg width={width} height={width}>
  <g>
    <path 
      d={iconPathData} 
      style={{fill:color, strokeWidth:'2px'}} 
      transform={"scale("+scaleFactorIcon+","+scaleFactorIcon+")"} 
      width={width/1.4142}
      height={width/1.4142} />
  </g>
</svg>

}