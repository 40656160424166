import { setContext } from 'apollo-link-context';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('expressGraphQLAuth');
  const language = localStorage.getItem('lang');
  // return {
  //   headers: {
  //     Authorization: token ? `Bearer ${token}` : '',
  //     'Accept-Language': !!language ? language : 'my'
  //   }
  // };
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      'Accept-Language': !!language ? language : 'en-US'
    }
  };
});

export default authLink;