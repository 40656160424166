import gql from 'graphql-tag';

export default gql`
  mutation createUpdateLocation($locationInput: LocationInput!){
    commonMutations{
      createUpdateLocation(locationInput: $locationInput){
        result
        message
        isError
        isSuccess
      }
    }
  }
`;