import React, { createContext, useContext, useEffect, useState } from 'react';
import { getToken, setToken } from '../utils';
import axios from 'axios';

export const SocketContext = createContext({
    sockettoken: localStorage.getItem('expressGraphQLAuthRefresh') !== undefined && localStorage.getItem('expressGraphQLAuthRefresh')
})

export const SocketProvider = (props) => {
    const socketContext = useContext(SocketContext);
    const [sockettoken, setSocketToken] = useState(socketContext.sockettoken);
    const [iswsalive, setIsWsAlive] = useState(false)
    let url = '';
    let ws = null;
    switch (window.location.host) {
        case 'localhost:3000':
            url = `ws://localhost:3001/${sockettoken}`;
            break;
        case 'staging.cgist.thibi.co':
            url = `wss://staging.cgist.thibi.co/${sockettoken}`
            break;
        case 'cgist.thibi.co':
            url = `wss://cgist.thibi.co/${sockettoken}`
            break;
        default:
            url = `ws://localhost:3001/${sockettoken}`;
            break;
    }
    const connect = async (sockettoken) => {
        let url = ""
        switch (window.location.host) {
            case 'localhost:3000':
                url = `ws://localhost:3001/${sockettoken}`;
                break;
            case 'staging.cgist.thibi.co':
                url = `wss://staging.cgist.thibi.co/${sockettoken}`;
                break;
            case 'cgist.thibi.co':
                url = `wss://cgist.thibi.co/${sockettoken}`;
                break;
            default:
                url = `ws://localhost:3001/${sockettoken}`;
                break;
        }
        ws = new WebSocket(url)
    }

    const refreshNewToken = async () => {
        let url = '';
        switch (window.location.host) {
            case 'localhost:3000':
                url = `http://localhost:3001/auth/refreshToken`;
                break;
            case 'staging.cgist.thibi.co':
                url = `https://staging.cgist.thibi.co/auth/refreshToken`
                break;
            case 'cgist.thibi.co':
                url = `https://cgist.thibi.co/auth/refreshToken`
                break;
            default:
                url = `http://localhost:3001/auth/refreshToken`;
                break;
        }
        const config = { headers: { 'Content-Type': 'application/json' } };
        const token = getToken('expressGraphQLAuthRefresh');
        let sockettoken = null
        let response = null;
        try {
            response = await axios.post(url, {
                token: token
            }, config)
            if (response && response.data.isSuccess) {
                setToken(
                    response.data.user.token,
                    'expressGraphQLAuth',
                    // console.log.bind(this, 'complete'),
                );
                setToken(
                    response.data.user.refreshToken,
                    'expressGraphQLAuthRefresh',
                    // console.log.bind(this, 'complete'),
                );
                setToken(
                    response.data.user.socketToken,
                    'expressGraphQLAuthSocket',
                    // console.log.bind(this, 'complete'),
                );
                sockettoken = response.data.user.socketToken
            } else {
                localStorage.clear()
            }
        } catch (err) {
            localStorage.clear()
            // console.log(err)
        }
        return sockettoken
    }

    const reconnect = async () => {
        if (getToken('expressGraphQLAuthRefresh') !== undefined && getToken('expressGraphQLAuthRefresh') !== null && getToken('expressGraphQLAuthRefresh') !== "") {
            let sockettoken = await refreshNewToken()
            if (sockettoken === null) {
                localStorage.clear();
                window.location.reload()
                return
            }
            if (sockettoken) {
                await connect(sockettoken)
                setSocketToken(sockettoken)
            }
            return
        }
    }

    ws = new WebSocket(url);
    ws.onerror = async () => {
        await reconnect()
    }

    const externalRefresh = async () => {
        if (getToken('expressGraphQLAuthRefresh') !== undefined && getToken('expressGraphQLAuthRefresh') !== null && getToken('expressGraphQLAuthRefresh') !== "") {
            if (ws && ws.readyState !== ws.OPEN) {
                await reconnect()
                console.log("reconnected ws from external components")
            }
        }
    }

    useEffect(() => {
        if (ws && ws.readyState === ws.OPEN) {
            setIsWsAlive(true)
        } else {
            setIsWsAlive(false)
            reconnect();
        }
        if (ws.readyState !== 1) {
            setIsWsAlive(false)
            reconnect();
        }
        if (ws.OPEN !== 1) {
            setIsWsAlive(false)
            reconnect();
        }
        if (!ws) {
            setIsWsAlive(false)
            reconnect();
        }
    }, [ws])

    useEffect(() => {
        if (!iswsalive) {
            reconnect()
        }
    }, [ws, iswsalive, sockettoken])

    const provider = {
        sockettoken,
        socket: ws,
        setSocketToken: (token) => {
            setSocketToken(token);
        },
        externalRefresh: () => {
            externalRefresh();
        }
    }
    return (
        <SocketContext.Provider value={provider}>
            {props.children}
        </SocketContext.Provider>
    );
}