
import gql from 'graphql-tag';

export default gql`
query layerDetailsQuery($json: JSON!){
  layersQuery{
    getLayers(where: $json){
      _id
      nameEN
      nameMM
      source
      location{
        _id
        level
        center{
          lat
          lon
        }
      }
      office{
        _id
        level
        ministry
      }
      type
      uploadedBy{
        email
      }
      publishedBy{
        email
      }
      chartVars{
        field{
          fieldType
          nameEN
        }
        bins{
          max
          min
          key
          value
        }
        grouping
        
      }
      tooltipVars{
        _id
        fieldType
        nameEN
        nameMM
      }
      tableVars{
        _id
        fieldType
        nameEN
        nameMM
      }
      mapStyling{
        radius{
          min
          max
        }
        weight
        fillMultiplier
        symbol
        color
        stylingColumns{
          field{
            fieldType
            nameEN
            nameMM
          }
          type
          bins{
            max
            min
            key
            value
          }
          func
        }
      }
      submitDate
      publishDate
      createdAt
      updatedAt
      dateRange{
        start
        end
      }
      uploadStep
      features{
        type
        properties{
          field{
            _id
            fieldType
            nameEN
            nameMM
          }
          value
        }
        geometry{
          type
          coordinates
        }
      }
    }
  }
}
`;