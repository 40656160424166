import gql from 'graphql-tag';

export default gql`
  mutation deleteLayer($id: IdOrIds!){
    layerMutations{
      deleteLayer(id:$id){
        result
        message
        isError
        isSuccess
      }
    }
  }
`;