import { groupBy } from "lodash"

import * as moment from 'moment';

export default (groupBys) => {

  const sortByKey = (type) => (a,b) => {
    if (type === 'NUMBER') {
      return +b.key.split('-')[0] > +a.key.split('-')[0] ? -1 : 1;
    } else if (type === 'DATE') {
      return moment(b.key).isAfter(a.key) ? -1 : 1;
    }
    
  }

  if (groupBys[0].dataType === 'TEXT') {
    return groupBys;
  } else {
    return [...groupBys.sort(sortByKey(groupBys[0].dataType))];
  }
}