import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Grid, Message, Segment, Form, Icon } from 'semantic-ui-react';
import Loader from '../Loader';
import GET_LOCATIONS from '../../graphql/getLocations';
import GET_USERS from '../../graphql/usersQuery';
import GET_OFFICES from '../../graphql/getOffices';
import ADD_USER from '../../graphql/createUpdateUser';
import { useForm, shouldLogState as SHOULD_LOG_STATE, validate } from '../../utils';
import UserFormFields from './UserFormFields';
import intl from 'react-intl-universal';

function NewUser(props) {
  const { cancelNewUser } = props;
  const [errors, setErrors] = useState({});
  const initialState = {
    email: '',
    phone: '',
    name: '',
    position: '',
    rank: '',
    otpRequired: false,
    userLevel: '',
    password: '',
    office: '',
    location: ''
  }
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [addUser] = useMutation(ADD_USER, {
    onCompleted: (data) => {
      console.log(data)
      setIsSubmitting(false);
      overrideValues(initialState);
      cancelNewUser();
    },
    errorPolicy: 'all',
    onError: (err) => {
      console.log(err);
    }
  })

  const { onChange, onSubmit, overrideValues, values } = useForm((newUser) => {
    console.log(newUser);
    const validation = validate(newUser);
    if (validation.isValid) {
      setIsSubmitting(true);
      const userInput = {
        ...newUser,
      }
      delete userInput.userLevel.text;
      console.log(userInput);
      addUser({
        variables: { userInput },
        refetchQueries: [{ query: GET_USERS }, { query: GET_OFFICES }, { query: GET_LOCATIONS }],
      });
    }
    else {
      setErrors(validation.errors);
      setIsSubmitting(false);
    }
  }, initialState, SHOULD_LOG_STATE)

  return (
    isSubmitting
      ?
      <Loader />
      :
      <>
        <Segment>
          <Form onSubmit={onSubmit} noValidate error={!!errors}>
            <Grid centered columns="equal">
              <UserFormFields values={values} errors={errors} onChange={onChange} actionType="create" />
              <Grid.Row>
                <Grid.Column textAlign="right">
                  <Form.Field>
                    <Button type="submit" onClick={onSubmit} primary disabled={isSubmitting}>
                      <Icon name="checkmark"/> {intl.get("admin.add_user")}
                    </Button>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              {Object.keys(errors).length === 0 && errors.constructor === Object ? false :
                <Grid.Row>
                  <Grid.Column textAlign="left">
                    <Message error icon="warning sign" content={<ul>
                      {Object.keys(errors).map(key => errors[key].map((error, index) => <li key={index}>{error}</li>))}
                    </ul>} />
                  </Grid.Column>
                </Grid.Row>
              }
            </Grid>
          </Form>
        </Segment>
      </>
  )
};

export default NewUser;
