import gql from 'graphql-tag';

export default gql`
  mutation deleteUser($id: IdOrIds!){
    userMutations{
      deleteUser(id:$id){
        result
        message
        isError
        isSuccess
      }
    }
  }
`;