import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider as Provider } from '@apollo/react-hooks';
import authLink from './utils/authLink';
import { LanguageProvider } from './context/langProvider';
import { SocketProvider } from './context/socketProvider';
import './index.css';
import App from './App';

const httpLink = createHttpLink({
  uri: process.env.NODE_ENV === 'production' ? '/api' : '//localhost:3001/api',
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    }
  }
});


const ApolloApp = () => (
  <Provider client={client}>
    <SocketProvider>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </SocketProvider>
  </Provider>
)

ReactDOM.render(<ApolloApp />, document.getElementById('root'));
