// import market from '../icons/market.svg';
// import medical_cross from '../icons/medical_cross.svg';
// import school from '../icons/school.svg';
import market from '../icons/market';
import medical_cross from '../icons/medical_cross';
import school from '../icons/school';


export const iconList = {
  'circle': null,
  'market': market,
  'medical_cross': medical_cross,
  'school': school
}


export default (symbol) => {
  // console.log('geticon',symbol)
  return iconList[symbol] ? iconList[symbol] : '';
}

