import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { Button, Container, Grid, Sticky, Modal } from 'semantic-ui-react';
import JwtDecode from 'jwt-decode';

function StickyTimer(props) {

  const exp = JwtDecode(props.token).exp * 1000;
  const [expiration, setExpiration] = useState(moment(exp));
  const [now, setNow] = useState(moment.now());
  const [promptTime, setPromptTime] = useState(moment(expiration).subtract(5, 'minutes'));

  const [isAfterPrompt, setIsAfterPrompt] = useState(false);
  const [isAfter, setIsAfter] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const counter = () => {
      setTimeout(() => {
        setNow(moment.now());
        setIsAfterPrompt(moment(moment.now()).isAfter(promptTime));
        setIsAfter(moment(moment.now()).isAfter(expiration));
      }, 1000);
    }

    return clearTimeout(counter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [now]);

  useEffect(() => {
    setExpiration(exp);
    setPromptTime(moment(exp).subtract(5, 'minutes'));
  }, [exp]);

  useEffect(() => {
    if (!!isAfterPrompt) {
      setModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAfterPrompt])

  const continueWorking = () => {
    setModalOpen(false);
    props.setToken();
  }

  if (isAfter) return <Redirect to="/logout" />

  return (
    <>
      <Sticky style={{ display: 'none' }}>
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Grid.Row style={{ wordWrap: 'break-word' }}>token: {props.token}</Grid.Row>
                <Grid.Row>now: {moment(now).format('DD/MM/YYYY LTS')}</Grid.Row>
                <Grid.Row>promptTime: {moment(promptTime).format('DD/MM/YYYY LTS')}</Grid.Row>
                <Grid.Row>exp: {moment(expiration).format('DD/MM/YYYY LTS')}</Grid.Row>
                <Grid.Row>isAfterPrompt: {JSON.stringify(isAfterPrompt)}</Grid.Row>
                <Grid.Row>isAfter: {JSON.stringify(isAfter)}</Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Sticky>
      <Modal open={isModalOpen} closeOnDimmerClick={false} closeOnEscape={false}>
        <Modal.Header>Your session is about to expire.</Modal.Header>
        <Modal.Content>
          <Button icon="x" onClick={() => setModalOpen(false)} />
          <Button onClick={continueWorking}>Continue Working</Button>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default StickyTimer;
