import intl from 'react-intl-universal';

const breadcrumbSteps = [
  {
    stepIndex: 'upload',
    title: 'editor.steps.upload',
    icon: 'tgicon-upload-map',
    stepNumber: 1
  },
  {
    stepIndex: 'visualize',
    title: 'editor.steps.visualize',
    icon: 'tgicon-visualize',
    stepNumber: 2
  },
  {
    stepIndex: 'submit',
    title: 'editor.steps.submit',
    icon: 'tgicon-submit',
    stepNumber: 3
  },
  {
    stepIndex: 'publish',
    title: 'editor.steps.publish',
    icon: 'tgicon-publish',
    stepNumber: 4
  }
]

export default breadcrumbSteps;