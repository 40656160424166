import { useEffect } from 'react';

export default (valuesLevel, setAllowedLevel) => useEffect(() => {
  switch (valuesLevel) {
    case 'VillageTrack':
    case 'Ward':
      return setAllowedLevel('Township');
    case 'Township':
      return setAllowedLevel('District');
    case 'District':
      return setAllowedLevel('StateRegion');
    case 'StateRegion':
      return setAllowedLevel('Union');
    case 'Union':
      return setAllowedLevel('');
    default:
      return setAllowedLevel('');
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [valuesLevel]);