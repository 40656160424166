import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { compareAuthValues } from '../utils'
import Loader from './Loader';


export default ({ component: Component, ...rest }) => {
  const { user, userrole } = rest;
  const { hasGottenToken, testPriority } = rest;
  const redirectPath = rest.location.pathname;

  if (!hasGottenToken) {
    return <Loader />
  }

  if (hasGottenToken && !user) {
    return (<Redirect to={{
      pathname: "/login",
      state: { referrer: redirectPath }
    }} />)
  }

  return (<Route
    {...rest}
    render={props =>
      user && compareAuthValues(userrole, testPriority) ? <Component user={user} {...props} /> : <Redirect to="/login" />
    }
  />)
}
