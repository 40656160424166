import gql from 'graphql-tag';

export default gql`
  query {
    commonQueries{
      getLocations(take:100){
        _id
        level
        center{
          lat
          lon
        }
        nameEN
        nameMM
        placeCode
        parent{
          _id
          nameEN
          nameMM
        }
      }
    }
  }
`;
