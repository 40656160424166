// import stylingFuncs from './stylingFuncs';
// import defaultMapStylings from './defaultMapStylings';
import {stylingFuncs,defaultMapStylings,makeBinName,isNumber} from './';

const returnDefault = (styling,type) => {
	if (type === 'RADIUS' && styling.radius.min && styling.radius.max)
		return (styling.radius.max + styling.radius.min)/2;
	else if (type === 'RADIUS')
		return (defaultMapStylings.radius.max + defaultMapStylings.radius.min)/2;
	else if (type === 'COLOR' && styling.color)
		return styling.color;
	else if (type === 'COLOR')
		return defaultMapStylings.color;
	else if (type === 'SYMBOL' && styling.symbol)
		return styling.symbol;
	else if (type === 'SYMBOL')
		return defaultMapStylings.symbol;
}

const makeReturnFunc = (scaleType,binArray,styling,type,forLegend) => (value) => {
	if (scaleType === "bins") {
		for (let i = 0; i < binArray.length; i++) {
			let returnVal = binArray[i];
			if (!forLegend) {
				if (value >= +returnVal.min && value < +returnVal.max) {
					return returnVal.value;
				}
			} else {
				if (value === makeBinName(returnVal.min,returnVal.max)) {
					return returnVal.value;
				}
			}
		}
	} else if (scaleType === "categorical") {
		for (let i = 0; i < binArray.length; i++) {
			let returnVal = binArray[i];
			if (returnVal.key === value) {
				return returnVal.value;
			}
		}
	} else return returnDefault(styling,type);
}

export default (styling,type,forLegend) =>  {

	// console.log(styling,type);

	let returnFunc = {};

	// -- loop through each of the stylingColumns

	styling.stylingColumns.forEach(col => {

			// ---- if there is a func return that
			if (col.func && col.type === type) {
				returnFunc[type] = stylingFuncs(col.func,type)
			}

			// ---- else if there is a bins obj calculate based on that
			else if (col.bins.length > 0 && col.type === type) {
				let scaleType = '';
				if (isNumber(col.bins[0].max) && isNumber(col.bins[0].min)) scaleType = 'bins';
				else if (!!col.bins[0].key ) scaleType = 'categorical';

				// console.log(scaleType,col);

				returnFunc[type] = scaleType ? makeReturnFunc(scaleType,col.bins,styling,type,forLegend) : null;
			}
	})



	
	// ---- if both func and bins are null, 
	// and if type is RADIUS return (max+min)/2 of radius object
	// else if type is COLOUR return a default color
	// else if type is SYMBOL return a default symbol
	returnFunc[type] = !returnFunc[type] ? () => returnDefault(styling,type) : returnFunc[type];

	return returnFunc[type];

}
  
  
  