import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Icon, Container } from 'semantic-ui-react';
import { SocketContext } from '../context/socketProvider';
import IS_LOCKED from '../graphql/isLayerLocked';

export default ({ id, userid, layer, setAuthorization }) => {
  const socketContext = useContext(SocketContext);
  const { socket } = socketContext;
  const { OPEN, readyState } = socket;
  const [lockByCurrentUser, setLockByCurrentUser] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const { loading: islockedloading, data: islockeddata, refetch } = useQuery(
    IS_LOCKED,
    {
      variables: { id },
    },
    { fetchPolicy: 'network-only' },
  );

  const updateData = async () => {
    let refdata = await refetch({
      variables: { id },
    });
    setUpdatedData(refdata && refdata.data.layersQuery.getLayerByID);
  };
  useEffect(() => {
    if (lockByCurrentUser) {
      updateData();
    }
  }, [lockByCurrentUser]);

  useEffect(() => {
    if (socket == null || socket === undefined) {
      socketContext.externalRefresh();
    }
    if (socket && socket.readyState !== socket.OPEN) {
      socketContext.externalRefresh();
    }
    if (socket && socket.readyState !== 1) {
      socketContext.externalRefresh();
    }
    if (socket && socket.OPEN !== 1) {
      socketContext.externalRefresh();
    }
    if (!socket) {
      socketContext.externalRefresh();
    }
  }, [
    socket,
    id,
    userid,
    layer,
    islockedloading,
    islockeddata,
    lockByCurrentUser,
    socketContext,
  ]);

  useEffect(() => {
    if (id !== null && userid !== null && layer !== null) {
      if (readyState === OPEN) {
        if (!layer.isLocked || layer.lockedBy === null) {
          let message = {
            type: 'lock-data',
            message: {
              model: 'layer',
              id,
            },
          };
          socket.send(JSON.stringify(message));
          setLockByCurrentUser(true);
        }
        if (layer.lockedBy !== null && layer.lockedBy !== userid) {
          setAuthorization(false);
        }
        if (layer.lockedBy === null || layer.lockedBy === userid) {
          setAuthorization(true);
        }
      }
    }
  }, [id, userid, layer, islockedloading]);

  if (socket.readyState !== socket.OPEN) {
    socketContext.externalRefresh();
  }

  return (
    <>
      {lockByCurrentUser ? (
        <>
          {islockedloading ? (
            <Container textAlign="center">
              <h4>loading ... </h4>
            </Container>
          ) : (
            <Container textAlign="center">
              {updatedData && !updatedData.isLocked ? (
                <Icon name="lock open" />
              ) : (
                <Icon name="lock" />
              )}
            </Container>
          )}
        </>
      ) : (
        <Container textAlign="center">
          {layer && !layer.isLocked ? (
            <Icon name="lock open" />
          ) : (
            <Icon name="lock" />
          )}
        </Container>
      )}
    </>
  );
};
