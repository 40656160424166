// import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useContext } from 'react'
import { setToken, getToken, removeToken } from '../utils';
import { LocalizationContext } from '../context/localization';

export default async token => {
  const { locale } = useContext(LocalizationContext);
  if (!!token && token.length) {
    const loginEndpoint = process.env.NODE_ENV === 'development'
      ? 'http://localhost:3001/auth/refreshToken'
      : '/auth/refreshToken'

    const config = { headers: { 'Content-Type': 'application/json', 'Accept-Language': locale } };
    return axios.post(loginEndpoint, { token }, config)
      .then(response => {
        const { refreshToken } = response.data.user;
        setToken(refreshToken, 'expressGraphQLAuthRefresh');
        const newToken = getToken('expressGraphQLAuthRefresh');
        return newToken;
      })
      .catch(err => {
        removeToken()
      })
  }
}

