import React from 'react';
// import DatePicker from "react-datepicker";
import { Input, Form, Dropdown } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import intl from 'react-intl-universal';

function LayerPropertyComponent(props) {
  const {propertyName,inputType,label,placeholder,options,changeFunc,styling,value, className} = props;

  let returnJSX = null;
  const disabled = props.disabled === undefined ? false : props.disabled;

  if (inputType === 'text') {
    returnJSX = <Form.Field>
      <label>{intl.get(label)}</label>
      <Input 
        placeholder={intl.get(placeholder)}
        onChange={(event, { value }) => changeFunc(value)} 
        defaultValue={value}
        disabled={disabled}
        className={`${className}`}
        />
    </Form.Field>
  } else if (inputType === 'dropdown') {
    returnJSX = <div className="field">
      <label>{intl.get(label)}</label>
      <Dropdown
        label={label}
        name={label}
        options={options}
        placeholder={placeholder}
        value={value}
        selection
        fluid
        disabled={disabled}
        onChange={(event, { value }) => changeFunc(value)}
        className={`${className}`} />
    </div>
  }

  return (
    returnJSX
  )

}

export default LayerPropertyComponent;
