import gql from 'graphql-tag';
export default gql`
  mutation addUser($userInput: userInput!){
    userMutations{
      createUpdateUser(userInput: $userInput){
        result
        message
        isError
        isSuccess
      }
    }
  }
`;