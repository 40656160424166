const defaultVals = {
  color: '#ff0000',
  radius: {
    max: 8,
    min: 4
  },
  symbol: 'circle',
  fillMultiplier: 0.7
}

export const defaultMapStylingsForMutation = {
  ...defaultVals,
}

export default {
  ...defaultMapStylingsForMutation,
  alpha: 100,
  'SYMBOL': defaultVals.symbol,
  'RADIUS': (defaultVals.radius.min + defaultVals.radius.max)/2,
  'COLOR': defaultVals.color
}