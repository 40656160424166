import React from 'react';
import _ from 'lodash';


export const l10nMain = (s,l,dontWrapInSpan) => {

  let returnString = '';
  if (l === 'en') {
    returnString = s;
  } else {
    // console.log(s);
    if(typeof s === 'string') {
      returnString = translateString(s);
    } else if (Array.isArray(s)){
      returnString = s;
    } else if (typeof s === 'number' && Number.isInteger(s)) {
      returnString = translateNumber(s);
    }
    returnString = dontWrapInSpan ? returnString : <span className="mm">{returnString}</span>;
  }
  return returnString;
}

const lookup = (() => {
  const wards = [
    ['sein pan', 'စိန်ပန်း'],
    ['shwe taung', 'ရွှေတောင်'],
    ['ward 20', '၂၀ ရပ်ကွက်'],
    ['ward 5', '၅ ရပ်ကွက်'],
    ['ward 1', '၁ ရပ်ကွက်'],
    ['ward 05', '၅ ရပ်ကွက်'],
    ['ward 01', '၁ ရပ်ကွက်'],
    ['ward 4', '၄ ရပ်ကွက်'],
    ['ward 04', '၄ ရပ်ကွက်'],
  ]

  const ward_layers = [
    ['roads', 'လမ်းများ'],
    ['accident prone areas', 'ယာဉ်မတော်တဆမှု ဖြစ်လေ့ရှိသည့် နေရာ '],
    ['crime prone areas', 'မှုခင်း ဖြစ်လေ့ရှိသည့် နေရာ'],
    ['damaged roads', 'ပျက်စီးနေသော လမ်းများ'],
    ['flood prone areas', 'ရေကြီးမှု ဖြစ်လေ့ရှိသည့် နေရာ'],
    ['foul smelling areas', 'အနံဆိုးရှိသည့် နေရာ'],
    ['landslide prone areas', 'မြေပြိုမှု ဖြစ်လေ့ရှိသည့်နေရာ'],
    ['limited car access', 'ကားရောက်ရှိရန် ခက်ခဲခြင်း'],
    ['sectors', 'အပိုင်း'],
    ['street dogs', 'လမ်းပေါ်ရှိခွေးများ'],
    ['waste collection', 'အမှိုက်သိမ်းခြင်း'],
    ['water issues', 'ရေပြသနာ'],
    ['water sources', 'ရေအရင်းအမြစ်'],
    ['broken retaining wall', 'တာဘောင် အကျိုး'],
    ['crime cases', 'မှုခင်းဖြစ်စဉ်များ'],
    ['dark areas', 'မှောင်ရိပ်'],
    ['drainage', 'ရေမြောင်း'],
    ['drug issues', 'မူယစ်ဆေးဝါး ပြသနာ'],
    ['fire prone areas', 'မီးဘေး အန္တာရာယ် ရှိသည့်နေရာ'],
    ['garbage dumps', 'အမှိုက်ပုံ'],
    ['limited pedestrian access', 'လမ်းလျှောက်ရ ခက်ခဲခြင်း'],
    ['wildfire prone areas', 'တောမီးလောင်လွယ်သည် နေရာ'],
    ['barriers to accesibility', 'လက်လှမ်းမီနိုင်ရန် အတားအဆီးများ'],
    ['blind spots', 'မမြင်နိုင်သည့် နေရာ'],
    ['boundary', 'ဘောင်'],
    ['crime hot spots', 'မှုခင်းဖြစ်များသည့် နေရာ'],
    ['dengue prone areas', 'သွေးလွန်တုတ်ကွေး ဖြစ်ပွားလေ့ရှိသည့် နေရာ'],
    ['flood prone roads', 'ရေကြီးလေ့ရှိသည့် လမ်းများ'],
    ['flood shelters', 'ရေဘေး ခိုလှုံရာနေရာ'],
    ['power issues', 'လျှပ်စစ်မီး ပြသနာ'],
    ['shops', 'ဈေးဆိုင်များ'],
    ['unsafe lake', 'စိတ်မချရသော ရေကန်'],
    ['waste dumps', 'အမှိုက်ကန်'],
    ['waste hot spots', 'အမှိုက်ပြစ်သည့် နေရာများ'],
    ['crime', 'မှုခင်း'],
    ['flood zone', 'ရေကြီးမှုဖြစ်ရာ ဒေသ'],
    ['informal settlement', 'တရားမဝင် အခြေချမှု'],
    ['lamp post issues', 'လမ်းမီးတိုင် ပြသနာ'],
    ['roads with limited access', 'သွားလာရခက်ခဲ့သည် လမ်းများ'],
    ['sector boundaries', 'အပိုင်းအလိုက် ကာကွယ်ထားသည့် ဘောင်'],
    ['waste and bad smells', 'အမှိုက်နှင့် အနံ့ဆိုး'],
    ['ward boundaries', 'ရပ်ကွက် အပိုင်းအခြား'],
    ['informal streets', 'တရားမဝင် လမ်းများ'],
    ['mobility', 'လှုပ်ရှားသွားလာနှိုင်ခြင်း'],
    ['road conditions', 'လမ်းအခြေအနေ'],
    ['thick bushes', 'ထူသောချုံ'],
    ['unsafe things in informal settlements', 'တရားမဝင် အခြေချနေရာများမှ စိတ်မချရသောနေရာများ'],
    ['electricity (formal)', 'လျှပ်စစ်မီး (တရားဝင်)'],
    ['electricity (informal)', 'လျှပ်စစ်မီး (တရားမဝင်)'],
    ['flood prone roads (formal)', 'ရေကြီးမှု ဖြစ်လေ့ရှိသည့် လမ်းများ (တရားဝင်)'],
    ['flood prone roads (informal)', 'ရေကြီးမှု ဖြစ်လေ့ရှိသည့် လမ်းများ (တရားမဝင်)'],
    ['heavy traffic and accident prone zones', 'လန်းပိတ်ချင်းနှင့်ယာဉ်မတော်တဆမှု ဖြစ်လေ့ရှိသည့် နေရာ '],
    ['flood zones', 'ရေကြီးမှု ဖြစ်လေ့ရှိသည့် ဒေသ'],
    ['high crime risk zones', 'မှုခင်း ဖြစ်လေ့ရှိသည့် နေရာ'],
    ['informal roads', 'တရားမဝင် လမ်းများ'],
    ['moge gas pipeline', 'MOGE ဓာတ်ငွေ့ ပိုက်လိုင်း'],
    ['squatter areas', 'ကျူးကျော်ရပ်ကွက်'],
    ['traffic zones', 'လန်းပိတ်လေ့ရှိသည့် နေရာ'],
    ['vacant land', 'မြေလွတ်'],
    ['waste dumps (formal)', 'အမှိုက်ပစ်သည့်နေရာ (တရားဝင်)'],
    ['waste dumps (informal)', 'အမှိုက်ပစ်သည့်နေရာ  (တရားမဝင်)']
  ]

  let lookup = {
    'township gis tool': 'မြို့နယ် မြေပုံအလွှာများ',
    'hlaing tharyar': 'လှိုင်သာယာ',
    'taunggyi': 'တောင်ကြီး',
    'hpa an': 'ဘားအံ',
    'location:': 'တည်နေရာ',
    'ward boundaries': 'ရပ်ကွက်နယ်နိမိတ်',
    'choose layers to display': 'ပြသရန် မြေပုံ ရွေးချယ်ရန်',
    'layers': 'မြေပုံအလွှာများ',
    'chart filters': 'Filter လုပ်ရန် ပုံများ',
    'sein pan': 'စိန်ပန်း',
    'shwe taung': 'ရွှေတောင်',
    'ward 20': '၂၀ ရပ်ကွက်',
    'ward 5': '၅ ရပ်ကွက်',
    'ward 1': '၁ ရပ်ကွက်',
    'ward 05': '၅ ရပ်ကွက်',
    'ward 01': '၁ ရပ်ကွက်',    
    'ward 4': '၄ ရပ်ကွက်',
    'ward 04': '၄ ရပ်ကွက်',
    'accidents': 'ယာဉ်မတော်တဆ ထိခိုက်မှုများ',
    'public buildings': 'အများပြည်သူဆိုင်ရာ အဆောက်အအုံ ',
    'roads': 'လမ်းများ',
    'accident prone areas': 'ယာဉ်မတော်တဆမှု ဖြစ်လေ့ရှိသည့် နေရာ ',
    'crime prone areas': 'မှုခင်း ဖြစ်လေ့ရှိသည့် နေရာ',
    'damaged roads': 'ပျက်စီးနေသော လမ်းများ',
    'flood prone areas': 'ရေကြီးမှု ဖြစ်လေ့ရှိသည့် နေရာ',
    'foul smelling areas': 'အနံဆိုးရှိသည့် နေရာ',
    'landslide prone areas': 'မြေပြိုမှု ဖြစ်လေ့ရှိသည့်နေရာ',
    'limited car access': 'ကားရောက်ရှိရန် ခက်ခဲခြင်း',
    'sectors': 'အပိုင်း',
    'street dogs': 'လမ်းပေါ်ရှိခွေးများ',
    'waste collection': 'အမှိုက်သိမ်းခြင်း',
    'water issues': 'ရေပြသနာ',
    'water sources': 'ရေအရင်းအမြစ်',
    'broken retaining wall': 'တာဘောင် အကျိုး',
    'crime cases': 'မှုခင်းဖြစ်စဉ်များ',
    'dark areas': 'မှောင်ရိပ်',
    'drainage': 'ရေမြောင်း',
    'drug issues': 'မူယစ်ဆေးဝါး ပြသနာ',
    'fire prone areas': 'မီးဘေး အန္တာရာယ် ရှိသည့်နေရာ',
    'garbage dumps': 'အမှိုက်ပုံ',
    'limited pedestrian access': 'လမ်းလျှောက်ရ ခက်ခဲခြင်း',
    'wildfire prone areas': 'တောမီးလောင်လွယ်သည် နေရာ',
    'barriers to accessibility': 'လက်လှမ်းမီနိုင်ရန် အတားအဆီးများ',
    'blind spots': 'မမြင်နိုင်သည့် နေရာ',
    'boundary': 'ဘောင်',
    'crime hot spots': 'မှုခင်းဖြစ်များသည့် နေရာ',
    'dengue prone areas': 'သွေးလွန်တုတ်ကွေး ဖြစ်ပွားလေ့ရှိသည့် နေရာ',
    'flood prone roads': 'ရေကြီးလေ့ရှိသည့် လမ်းများ',
    'flood shelters': 'ရေဘေး ခိုလှုံရာနေရာ',
    'power issues': 'လျှပ်စစ်မီး ပြသနာ',
    'shops': 'ဈေးဆိုင်များ',
    'unsafe lake': 'စိတ်မချရသော ရေကန်',
    'waste dumps': 'အမှိုက်ကန်',
    'waste hot spots': 'အမှိုက်ပြစ်သည့် နေရာများ',
    'crime': 'မှုခင်း',
    'flood zone': 'ရေကြီးမှုဖြစ်ရာ ဒေသ',
    'informal settlement': 'တရားမဝင် အခြေချမှု',
    'lamp post issues': 'လမ်းမီးတိုင် ပြသနာ',
    'roads with limited access': 'သွားလာရခက်ခဲ့သည် လမ်းများ',
    'sector boundaries': 'အပိုင်းအလိုက် ကာကွယ်ထားသည့် ဘောင်',
    'waste and bad smells': 'အမှိုက်နှင့် အနံ့ဆိုး',
    // 'ward boundaries': 'ရပ်ကွက် အပိုင်းအခြား',
    'show chart filters': 'Filter လုပ်သည့် ပုံများပြရန်',
    'show data table': 'အချက်အလက် ဇယားကို ပြရန်',
    'damage type': 'ပျက်စီးမှု အမျိုးအစား',
    'who is injured': 'ဒဏ်ရာရသူ',
    'who is involved': 'ဖြစ်ရပ်တွင် ပါဝင်သူ',
    'narrative': 'ဖြစ်ရပ်',
    'time': 'အချိန်',
    'date': 'နေ့စွဲ',
    'day': 'နေ့ရက်',
    'month': 'လ',
    '(select all)': '(အားလုံးကိုရွေးရန်)',
    '1) pedestrian': '၁) လမ်းသွားလမ်းလာ',
    '2) driver or operator of the transport (including bicyclists and motorcyclists)': '၂) ဒရိုင်ဘာ (သို့) ပို့ဆောင်ရေး အုပ်ချုပ်သူ (စက်ဘီး နှင့် ဆိုင်ကယ် အပါအဝင်)',
    '3) passenger (including motorcycle passengers)': '၃) ခရီးသည် ( ဆိုင်ကယ်စီးသည့် ခရီးသည် အပါအဝင်)',
    '9) unknown': '၉) အမည်မသိ',
    // 'catastrophic (fatal)': 'ကပ်ဘေး (အသက်ဘေးအန္တာရာယ် ရှိသော)',
    'catastrophic (fatal)': 'အသက်ဘေးအန္တာရာယ် ရှိ',
    // 'critical (hospitalized)': 'စိုးရိမ်ရသော အခြေအနေ (ဆေးရုံတင်ထားရာတွင်)',
    'critical (hospitalized)': 'စိုးရိမ်ရ(ဆေးရုံတင်ထား)',
    // 'marginal (injured)': 'မစိုးရိမ်ရသော အခြေအနေ (ဒဏ်ရာရရှိမှု)',
    'marginal (injured)': 'မစိုးရိမ်ရ(ဒဏ်ရာရရှိ)',
    'marginal injured': 'မစိုးရိမ်ရ(ဒဏ်ရာရရှိ)',
    // 'negligible crash': 'မပြောပလောက်သည့် ထိခိုက်မှု',
    'negligible crash': 'မပြောပလောက်',
    'no data': 'အချက်အလက် မရှိ',
    'nan': 'အချက်အလက် မရှိ',
    'invalid date': 'အချက်အလက် မရှိ',
    'invalid time': 'အချက်အလက် မရှိ',
    'pedestrain only': 'လမ်းလျှောက်ရန်သာ',
    'vehicle alone': 'ယာဉ်များသာ',
    'vehicle and pedestrian': 'ယာဉ် နှင့် လမ်းလျှောက်သူများ',
    'vehicle and vehicle': 'ယာဉ် နှင့် ယာဉ်',
    'monday': 'တနင်္လာ',
    'tuesday': 'အင်္ဂါ',
    'wednesday': 'ဗုဒ္ဓဟူး',
    'thursday': 'ကြာသာပတေး',
    'friday': 'သောကြာ',
    'saturday': 'စနေ',
    'sunday': 'တနင်္ဂနွေ',
    'jan': 'ဇန်နဝါရီ',
    'feb': 'ဖေဖော်ဝါရီ',
    'mar': 'မတ်',
    'apr': 'ဧပြီ',
    'may': 'မေ',
    'jun': 'ဇွန်',
    'jul': 'ဇူလိုင်',
    'aug': 'သြဂုတ်',
    'sep': 'စက်တင်ဘာ',
    'oct': 'အောက်တိုဘာ',
    'nov': 'နိုဝင်ဘာ',
    'dec': 'ဒီဇင်ဘာ',
    'january': 'ဇန်နဝါရီ',
    'february': 'ဖေဖော်ဝါရီ',
    'march': 'မတ်',
    'april': 'ဧပြီ',
    // 'may': 'မေ',
    'june': 'ဇွန်',
    'july': 'ဇူလိုင်',
    'august': 'သြဂုတ်',
    'september': 'စက်တင်ဘာ',
    'october': 'အောက်တိုဘာ',
    'november': 'နိုဝင်ဘာ',
    'december': 'ဒီဇင်ဘာ',
    'jan 2017': 'ဇန်နဝါရီ 2017',
    'feb 2017': 'ဖေဖော်ဝါရီ 2017',
    'mar 2017': 'မတ် 2017',
    'apr 2017': 'ဧပြီ 2017',
    'may 2017': 'မေ 2017',
    'jun 2017': 'ဇွန် 2017',
    'jul 2017': 'ဇူလိုင် 2017',
    'aug 2017': 'သြဂုတ် 2017',
    'sep 2017': 'စက်တင်ဘာ 2017',
    'oct 2017': 'အောက်တိုဘာ 2017',
    'nov 2017': 'နိုဝင်ဘာ 2017',
    'dec 2017': 'ဒီဇင်ဘာ 2017',
    'jan 2018': 'ဇန်နဝါရီ 2018',
    'feb 2018': 'ဖေဖော်ဝါရီ 2018',
    'mar 2018': 'မတ် 2018',
    'apr 2018': 'ဧပြီ 2018',
    'may 2018': 'မေ 2018',
    'jun 2018': 'ဇွန် 2018',
    'jul 2018': 'ဇူလိုင် 2018',
    'aug 2018': 'သြဂုတ် 2018',
    'sep 2018': 'စက်တင်ဘာ 2018',
    'oct 2018': 'အောက်တိုဘာ 2018',
    'nov 2018': 'နိုဝင်ဘာ 2018',
    'dec 2018': 'ဒီဇင်ဘာ 2018',
    'simple': 'လွယ်ကူသော',
    'openstreetmap color': 'OpenStreetMap ရောင်စုံ',
    'satellite': 'ဂြိုလ်တု',
    'bank': 'ဘဏ်',
    'church': 'ဘုရားကျောင်း',
    'cinema': 'ရုပ်ရှင်ရုံ',
    'fire station': 'မီးသတ်',
    'government building': 'အစိုးရအဆောက်အအုံ',
    'government office': 'အစိုးရရုံး',
    'high way gate': 'အဝေးပြေးဂိတ်',
    'hospital/clinic': 'ဆေးရုံ/ဆေးခန်း',
    'hotel': 'ဟိုတယ်',
    'market': 'ဈေး',
    'monestery': 'ဘုန်းကြီးကျောင်း',
    'monestry': 'ဘုန်းကြီးကျောင်း',
    'none': 'တစ်ခုမှမဟုတ်',
    'pagoda': 'ဘုရားစေတီ',
    'pagoada': 'ဘုရားစေတီ',
    'religious': 'ဘာသာရေး',
    'mosque': 'ဗလီ',
    'park': 'ပန်းခြံ',
    'playground': 'ကစားကွင်း',
    'police station': 'ရဲစခန်း',
    'public facilities': 'ပြည်သူပိုင် နေရာများ',
    'school': 'ကျောင်း',
    'shopping center': 'ဈေးဝယ်စင်တာ',
    'temple': 'ဘုရားဝတ်ကျောင်း',
    'university': 'တက္ကသိုလ်',
    'water tank': 'ရေတိုင်ကီ',
    'dumping into drainage': 'ရေမြောင်းသို့ အမှိုက်စွန့်ခြင်း',
    'dumping into creek': 'ရေချောင်းထဲသို့ အမှိုက်စွန့်ခြင်း',
    'general': 'အထွေထွေ',
    'water purifying factory (for donation)': 'ရေသန့်စက်ရုံ (အလှုပေးရန်)',
    'public water tank': 'ပြည်သူပိုင်ရေကန်',
    'grey water point': 'ရေဆိုးထွက်သည့်နေရာ',
    'water tank, supply every fourth day': 'လေးရက်တစ်ခါ ရေရသည့် ရေကန်',
    'water tank(broken)': 'ရေကန်ပျက်',
    'tank/well': 'ရေကန်/ရေတွင်း',
    'snack shop': 'မုန့်ဆိုင်',
    'water post': 'ရေရနိုင်သည့်နေရာ',
    'shop': 'ဆိုင်',
    'informal waste dump': 'အမှိုက်ခိုးပစ်သည့်နေရာ',
    'waste dispose in drainage': 'ရေမြောင်းတွင်းသို့ ရေဆင်းခြင်း',
    'refugee camp during flood': 'ရေကြီးချိန်တွင် ခိုလှုံရာနေရာ',
    'cables': 'ဓါတ်ကြိုးများ',
    'cable': 'ဓါတ်ကြိုး',
    'post': 'နေရာ',
    'transformer': 'ထရန်ဖော်စမာ',
    'public pond': 'အများပိုင်ရေအိုင်/ကန်',
    'public well': 'အများပိုင်ရေတွင်း',
    'innpyae pond': 'အင်းပြေရေကန်',
    'middle school': 'အလယ်တန်းကျောင်း',
    'primary school': 'မူလတန်းကျောင်း',
    'log out': 'ထွက်ရန်',
    '(all)': '(အားလုံး)',
    'natural source': 'သဘာဝအရင်းအမြစ်',
    'dao source': 'ခရိုင်ရုံး အရင်းအမြစ်',
    'water supply': 'ရေပေးဝေရေး',
    'crash data': 'ယာဉ်ထိခိုက်မှု အချက်အလက်',
    'ward level': 'ရပ်ကွက်အဆင့်',
    'township level': 'မြို့နယ်အဆင့်',
    'wards': 'ရပ်ကွက်များ',
    'boundaries': 'နယ်နိမိတ်များ',
    'community contribution': 'ပြည်သူများမှ ထာက်ပံ့သောအချက်အလက်',
    'myotaw app community contribution': 'မြို့တော် App မှပြည်သူများ ထာက်ပံ့သောအချက်အလက်',
    'show layers cards': 'မြေပုံအလွှာကဒ်များပြရန်',
    'date range': 'ရက်စွဲအပိုင်းအခြား',
    'open': 'ဖွင့်',
    'close': 'ပိတ်',
    'reset': 'မူရင်းအတိုင်း',
    'from': 'မှ',
    'to': 'အထိ',
    'kbz water post': 'KBZ ရေရနိုင်သည့်နေရာ',
    'kbz water tank': 'KBZ ရေတိုင်ကီ',
    'water tank/well': 'ရေတိုင်ကီ/ရေတွင်း',
    'snack shop, public water tank': 'မုန့်ဆိုင်၊ ရေတိုင်ကီ',
    'damaged': 'ပျက်စီး',
    'elpwa water tank': 'Elpwa ရေတိုင်ကီ',
    'elpwa + kbz water tank': 'Elpwa + KBZ ရေတိုင်ကီ',
    'kbz water source': 'KBZ ရေအရင်းအမြစ်',
    'concrete': 'ကွန်ကရစ်',
    'asphalt': 'ကတ္တရာ',
    'earth': 'မြေ',
    'traffic light': 'မီးပွိုင့်',
    'traffic lights': 'မီးပွိုင့်',
    "no.3 high school (extension)": "အမှတ် ၃ အထက်တန်းကျောင်း (တိုးချဲ့)",
    "thiri mandai monastry": "သီရိမန်ဒိုင် ဘုန်းကြီးကျောင်း",
    "taung than thar nar pyu pra hita monastry": "တောင်တန်းသာသနာပြု ဘုန်းကြီးကျောင်း",
    "no.3 middle school": "အမှတ် ၃ အထက်တန်းကျောင်း", 
    "no.6 primary school": "အမှတ် ၆ အလယ်တန်းကျောင်း",
    "welcome to the township gis tool": "မြို့နယ် မြေပုံအလွှာများမှ ကြိုဆိုပါသည်။",
    "i agree": "သဘောတူပါသည်",
    "do not show this screen in the future": "ရှေ့ဆက်တွင် ဒီမျက်နှာပြင်ကို မပြပါနှင့်ဆို သည့်အရာကို နှိပ်၍မှတ်သားထားပါ။",
    "township":"မြို့နယ်",
    "township boundary":"မြို့နယ်ဘောင်",
    "bus stops":"ဘတ်စ်ကားမှတ်တိုင်",
    "bus stop":"ဘတ်စ်ကားမှတ်တိုင်",
    "road user age":"လမ်းအသုံးပြုသူရဲ့အသက်",
    "vehicles":"ယာဉ်",
    "industrial zones":"စက်မှုဇုန်",
    "land use":"မြေယာအသုံးပြုမှု",
    "monastery":"ဘုန်းကြီးကျောင်း",
    "hindu temples":"ဟိန္ဒူဘုရားကျောင်း",
    "hospital":"ဆေးရုံ",
    "clinic":"ဆေးခန်း",
    "education":"ပညာရေး",
    "commercial":"စီးပွားရေ",
    "government and public office":"အစိုးရရုံး",
    "health":"ကျန်းမာရေး",
    "green space":"စိမ်းလန်းသောနေရာ",
    "body of water":"ရေ",
    "industry":"စက်မှုလုပ်ငန်း",
    "open space":"ကွက်လပ်",
    "public and private facilities":"အများပြည်သူနှင့်ပုဂ္ဂလိက",
    "low density collective building":"သိပ်သည်းမှု နည်းသောစုပေါင်းအဆောက်အဦး",
    "green zone and body of water":"ကွက်လပ်နှင့်ရေ",
    "pa dan monestry":"ပထန်းဘုန်းကြီးကျောင်း",
    "middle density collective building":"သိပ်သည်းမှု အလယ်တန်းစုပေါင်းအဆောက်အဦး",
    "middle density collective building ":"သိပ်သည်းမှု အလယ်တန်းစုပေါင်းအဆောက်အဦး",
    "high density collective building":"သိပ်သည်းမှု များသောစုပေါင်းအဆောက်အဦး",
    "high density collective building ":"သိပ်သည်းမှု များသောစုပေါင်းအဆောက်အဦး",
    "green zones of and body of water":"စိမ်းလန်းသောနေရာနှင့်ရေ",
    "slum":"ကျူးကျော်",
    "military area":"စစ်တပ်",
    "green space inside the plot":"ကွက်လပ်ထဲမှ စိမ်းလန်းသောနေရာ",
    "residential":"လူနေထိုင်ရာ",
    "identify":"ခွဲခြားသတ်မှတ်နေဆဲ",
    "identifying":"ခွဲခြားသတ်မှတ်နေဆဲ",
    "water body":"ရေ",
    "agricultural":"စိုက်ပျိုးရေး",
    "military":"စစ်တပ်",
    "transportation":"သယ်ယူပို့ဆောင်ရေး",
    "green area":"စိမ်းလန်းသောနေရာ",
    "cementery":"သုဿန်",
    "governmental":"အစိုးရ",
    'informal streets': 'တရားမဝင် လမ်းများ',
    'mobility': 'လှုပ်ရှားသွားလာနှိုင်ခြင်း',
    'road conditions': 'လမ်းအခြေအနေ',
    'thick bushes': 'ထူသောချုံ',
    'unsafe things in informal settlements': 'တရားမဝင် အခြေချနေရာများမှ စိတ်မချရသောနေရာများ',
    'electricity (formal)': 'လျှပ်စစ်မီး (တရားဝင်)',
    'electricity (informal)': 'လျှပ်စစ်မီး (တရားမဝင်)',
    'flood prone roads (formal)': 'ရေကြီးမှု ဖြစ်လေ့ရှိသည့် လမ်းများ (တရားဝင်)',
    'flood prone roads (informal)': 'ရေကြီးမှု ဖြစ်လေ့ရှိသည့် လမ်းများ (တရားမဝင်)',
    'flood zones': 'ရေကြီးမှု ဖြစ်လေ့ရှိသည့် ဒေသ',
    'heavy traffic and accident prone zones': 'လန်းပိတ်ချင်းနှင့်ယာဉ်မတော်တဆမှု ဖြစ်လေ့ရှိသည့် နေရာ ',
    'high crime risk zones': 'မှုခင်း ဖြစ်လေ့ရှိသည့် နေရာ',
    'informal roads': 'တရားမဝင် လမ်းများ',
    'moge gas pipeline': 'MOGE ဓာတ်ငွေ့ ပိုက်လိုင်း',
    'squatter areas': 'ကျူးကျော်ရပ်ကွက်',
    'traffic zones': 'လန်းပိတ်လေ့ရှိသည့် နေရာ',
    'vacant land': 'မြေလွတ်',
    'waste dumps (formal)': 'အမှိုက်ပစ်သည့်နေရာ (တရားဝင်)',
    'waste dumps (informal)': 'အမှိုက်ပစ်သည့်နေရာ  (တရားမဝင်)',
    'power points': 'လျှပ်စစ်မီး အမှတ်များ',
    'power lines': 'လျှပ်စစ်မီး လိုင်းများ',
    'fmi boundary':'FMI အိမ်ရာနယ်နိမိတ်',
    'housing estate':'အိမ်ရာစီမံကိန်း',
    'informal':'တရားမဝင်',
    'loading data':'အချက်အလက်များ တင်ယူနေဆဲ',
    'schools': 'ကျောင်းများ',
    'recent map layers': 'လတ်တလော မြေပုံလွှာများ',
    'all map layers': 'မြေပုံအလွှာများအားလုံး',
    'filter rows': 'အတန်း row များစစ်ထုတ်မည်',
    'download as csv': 'csv ဖိုင်အနေဖြင့်ဒေါင်းလုတ်လုပ်မည်',
    'add new layer': 'အလွှာသစ်တင်သွင်းရန်',
    'buttons': 'ခလုပ်များ',
    'title': 'ခေါင်းစဉ်',
    'location': 'တည်နေရာ',
    'hierarchy': 'အဆင့်သတ်မှတ်ချက်',
    'type': 'အမျိုးအစား',
    'select csv file to upload as new layer': 'အလွှာသစ်တင်သွင်းရန်အတွက် CSV ဖိုင်ကို ‌ရွေးချယ်ပါ',
    'select excel file to upload as new layer': 'အလွှာသစ်တင်သွင်းရန်အတွက် Excel ဖိုင်ကို ‌ရွေးချယ်ပါ',
    'choose file': 'ဖိုင်ရွေးရန်',
    'no file chosen': 'ဖိုင်ရွေးချယ်ထားခြင်းမရှိပါ',
    'choose latitude column': 'လက္တီကျုရွေးရန်',
    'choose longitude column': 'လောင်ဂီကျုရွေးရန်',
    'save and edit layer': 'အလွှာသိမ်းဆည်းပြီး ပြင်ဆင်စီသို့',
    'edit layer': 'အလွှာပြင်ရန်',
    'open configuration panel': 'ပြင်ဆင်ပြောင်းလဲသည့်နေရာသို့သွားမည်',
    'close configuration panel': 'ပြင်ဆင်ပြောင်းလဲသည့်နေရာပိတ်မည်',
    'table view': 'ဇယား',
    'map view': 'မြေပုံ',
    'split view': 'မြေပုံဇယားတွဲ',
    'add row': 'အတန်း row အသစ်သွင်းမည်',
    'rows count:': 'အတန်း row အရေအတွက်',
    // 'title': 'ခေါင်းစဉ်',
    'name': 'အမည်',
    'group in legend': 'အညွှန်းအလိုက်အုပ်စုဖွဲ့မည်',
    // 'location': 'တည်နေရာ',
    // 'type': 'အမျိုးအစား',
    'legendvars': 'မြေပုံအညွှန်းများ‌ပြောင်းခြင်း',
    'chartvars': 'နောက်တဆင့် စစ်ထုတ်ခြင်း',
    'tooltipvars': 'ထပ်ဆင့် အညွှန်းများပြောင်းခြင်း',
    'tablevars': 'ဇယားတွင်ပြမည့် အချက်အလက်များပြောင်းခြင်း',
    'mapstyling > radius': 'မြေပုံစတိုင် > ပွိုင့်အကြီးအသေး',
    'mapstyling > weight': 'မြေပုံစတိုင် > ပွိုင့် border အထူ',
    'mapstyling > fillmultiplier': 'မြေပုံစတိုင် > ပွိုင့်အရောင် အထင်းအမှိန်',
    'mapstyling > color': 'မြေပုံ အရောင်များ',
    'mapstyling > scale': 'မြေပုံ အရောင်စကေး',
    'showdefault': 'နဂိုအတိုင်းပြမည်',
    'filterdate': 'ရက်စွဲကို စစ်ထုတ်မည်',
    'ispublished': 'ထုတ်လုပ်မည်',
    'save': 'သိမ်းဆည်းမည်',
    'add option': 'ရွေးချယ်စရာ အသစ်သွင်းမည်',
    'layer configuration': 'အလွှာများ‌ ပြင်ဆင်ပြောင်းလဲခြင်း',
    'option selection': 'အတွက် ‌ရွေးချယ်စရာများ'
  }

  let lookup_wards = wards.map(w => ward_layers.map(l => 
      ({'var': w[0]+' '+l[0], 'value': w[1]+' '+l[1]})
    ));

  lookup_wards = _.flatten(lookup_wards);
  lookup_wards = _.mapValues(_.keyBy(lookup_wards, 'var'), 'value')

  lookup = {...lookup, ...lookup_wards};
  return lookup;
})();

const translateNumber = (number) => {
  let digits = number.toString().split('');
  digits = digits.map(d => {
    switch(d) {
      case '0':
        return '၀';
      case '1':
        return '၁';
      case '2':
        return '၂';
      case '3':
        return '၃';
      case '4':
        return '၄';
      case '5':
        return '၅';
      case '6':
        return '၆';
      case '7':
        return '၇';
      case '8':
        return '၈';
      case '9':
        return '၉';
      default:
        return d;
    }
  })
  digits = digits.join('')
  // console.log(digits);
  return digits;
}

const translateString = (s) => {
  let sen = s.toLowerCase();
  sen= sen.split('|')[1] ? sen.split('|')[1] : sen;
  sen = sen.trim()

  // console.log(sen);
  // console.log(lookup);

  let smm = lookup[sen] ? lookup[sen] : s;
  return smm;
}

export default l10nMain;
