import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {Grid, Form, Dropdown, Label } from 'semantic-ui-react';
import Loader from '../Loader';
import GET_LOCATIONS from '../../graphql/getLocations';
import { levelOptions as options } from '../../utils';
import intl from 'react-intl-universal';

function GroupFormFields(props) {

	const { values, errors, onChange } = props;

	const locationsQuery = useQuery(GET_LOCATIONS);
	const [locations, setLocations] = useState([]);

	useEffect(() => {
	  if (
	    locationsQuery.data
	    && locationsQuery.data.commonQueries
	    && locationsQuery.data.commonQueries.getLocations
	  ) {
	    const { getLocations } = locationsQuery.data.commonQueries;
	    const locations = getLocations.map(location => ({ value: location._id, text: `${location.nameMM} ${location.level ? '(' + location.level + ')' : false}` }))
	    setLocations(locations);
	  }
	}, [locationsQuery.data])

	const handleDropdownChange = (name, value) => {
	  return onChange({ target: { name, value, focus: () => false }, preventDefault: () => false })
	}

	return(
		locationsQuery.loading
		?
		<Loader />
      	:
      	<>
      		<Grid.Row>
      		  <Grid.Column textAlign="left">
      		    <Form.Field required>
      		      <label>{intl.get("admin.group_fields.level")}</label>
      		      <Dropdown
      		        label={intl.get("admin.group_fields.level")}
      		        name="level"
      		        options={options}
      		        placeholder={intl.get("admin.group_fields.choose_level")}
      		        selection
      		        fluid
      		        value={values.level}
      		        error={errors.level}
      		        onChange={(event, { value }) => handleDropdownChange('level', value)} />
      		        {errors.level ? <Label basic color='red' pointing>{errors.level[0]}</Label> : false}
      		    </Form.Field>
      		  </Grid.Column>
      		  <Grid.Column textAlign="left">
      		    <Form.Field required>
      		    <label>{intl.get("admin.group_fields.location")}</label>
      		      <Dropdown
      		        label={intl.get("admin.group_fields.location")}
      		        name="location"
      		        options={locations}
      		        placeholder={intl.get("admin.group_fields.choose_location")}
      		        selection
      		        fluid
      		         value={values.location}
      		        error={errors.location}
      		        onChange={(event, { value }) => handleDropdownChange('location', value)} />
      		        {errors.location ? <Label basic color='red' pointing>{errors.location[0]}</Label> : false}
      		    </Form.Field>
      		  </Grid.Column>
      		</Grid.Row>
      		<Grid.Row>
      		  <Grid.Column textAlign="left">
      		    <Form.Input label={intl.get("admin.group_fields.ministry")} name="ministry" value={values.ministry} onChange={onChange} placeholder="Ministry Name" error={errors.ministry} required />
      		  </Grid.Column>
      		  <Grid.Column textAlign="left">
      		    <Form.Input label={intl.get("admin.group_fields.department")} name="department" value={values.department} onChange={onChange} placeholder="Department Name" error={errors.department} required />
      		  </Grid.Column>
      		</Grid.Row>
      	</>
	)

}

export default GroupFormFields;