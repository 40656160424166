import gql from 'graphql-tag';

export default gql`
  query singleUserQuery($id:String!){
    userQueries{
      getUserById(id:$id){
        _id
        email
        phone
        name
        position
        rank
        otpRequired
        userLevel{
          _id
          accessType
          priority
          titleEN
          titleMM
        }
        office{
          _id
          ministry
          department
        }
        location{
          _id
          level
          nameEN
          nameMM
        }
      }
    }
  }
`