import React, { useState, useMemo } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Header, Icon, Segment, Modal, Message } from 'semantic-ui-react';
import GET_SINGLE_LOCATION from '../graphql/singleLocationQuery';
import GET_LOCATIONS from '../graphql/getLocations';
import UPDATE_LOCATION from '../graphql/createUpdateLocation';
import DELETE_LOCATION from '../graphql/deleteLocation';
import { useForm, shouldLogState as SHOULD_LOG_STATE, validate } from '../utils';
import LocationFormFields from '../components/admin/LocationFormFields';
import intl from 'react-intl-universal';

import Loader from '../components/Loader';
import Layout from '../components/Layout';

function LocationEditorPage(props) {
  const { history } = props;
  const { id } = props.match.params;
  const { loading, data } = useQuery(GET_SINGLE_LOCATION, { variables: { id } });
  const locationsQuery = useQuery(GET_LOCATIONS);
  const [getLazySingleLocation, lazySingleLocationQuery] = useLazyQuery(GET_SINGLE_LOCATION);
  const [getLazyLocations, getLazyLocationsQuery] = useLazyQuery(GET_LOCATIONS);

  const [isUpdatingLocation, setIsUpdatingLocation] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isError, setIsError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);

  const initialState = {};

  const [updateLocation] = useMutation(UPDATE_LOCATION, {
    onCompleted: _ => {
      setIsUpdatingLocation(false);
    },
    onError: (error) => {
      setIsError(true);
      setErrorMessage(error);
    },
    errorPolicy: 'all',
  });

  const [deleteLocation, deleteLocationMutation] = useMutation(DELETE_LOCATION, {
    onCompleted: (data) => {
      const { isSuccess } = data.commonMutations.deleteLocation;
      if (isSuccess) {
        console.log(data);
        history.push('/admin/locations');
      }
    },
    refetchQueries: [{ query: GET_LOCATIONS }],
    onError: (error) => {
      console.log(error);
    },
    errorPolicy: 'all',
  });

  const { onSubmit, onChange, overrideValues, values } = useForm((values) => {

    const validation = validate(values);
    if (validation.isValid) {
      const locationInput = {
        ...values,
        center: {
          lat: parseFloat(values.lat),
          lon: parseFloat(values.lon)
        }
      };

      delete locationInput.lat;
      delete locationInput.lon;

      setIsUpdatingLocation(true);
      updateLocation({
        variables: { locationInput },
        refetchQueries: [
          { query: GET_LOCATIONS },
          { query: GET_SINGLE_LOCATION, variables: { id } }
        ]
      });
    }
    else {
      setFormErrors(validation.errors);
    }

  }, initialState, SHOULD_LOG_STATE);

  const updateDataOnly = () => {
    if (data) {
      const location = data.commonQueries.getLocationById;
      console.log(location);

      if (Object.keys(location).length === 0) {
        deleteLocation({ variables: { id } });
      }

      else {
        const values = {
          ...location,
          lat: location.center.lat,
          lon: location.center.lon,
        }

        delete values.center;
        overrideValues(values);
      }
    }
  };

  useMemo(updateDataOnly, [data]);

  const handleRefresh = () => {
    getLazySingleLocation({ variables: { id } });
    getLazyLocations();
  }

  const handleHardReload = () => window.location.reload();

  const handleDelete = () => {
    console.log(id);
    deleteLocation({ variables: { id } })
  }

  if (isError) {
    return (
      <>
        <Header as="h3">An Error has occured. Please try again.</Header>
        <p>{JSON.stringify(errorMessage)}</p>
      </>
    )
  }

  return (
    loading || locationsQuery.loading || lazySingleLocationQuery.loading || getLazyLocationsQuery.loading
      ?
      <Loader />
      :
      <Layout>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column textAlign="left">
              <Button primary as={Link} to="/admin/locations" icon>
                <Icon name="triangle left" />Back to Locations
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Header as="h3">{intl.get("admin.edit_location")}</Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              {process.env.NODE_ENV !== 'development' && <Button primary onClick={handleRefresh} icon>
                Reload{' '}<Icon name="redo" />
              </Button>}
              {process.env.NODE_ENV === 'development' && <Button primary onClick={handleHardReload} icon>
                Refresh{' '}<Icon name="redo" />
              </Button>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment.Group>
          <Segment>
            <Segment.Group>
              <Segment as={Form} onSubmit={onSubmit} loading={isUpdatingLocation} error={!!formErrors} textAlign="left">
                <Grid columns="equal">
                  <LocationFormFields values={values} errors={formErrors} onChange={onChange} />
                  {Object.keys(formErrors).length === 0 && formErrors.constructor === Object ? false :
                    <Grid.Row>
                      <Grid.Column textAlign="left">
                        <Message error icon="warning sign" content={<ul>
                          {Object.keys(formErrors).map(key => formErrors[key].map((error, index) => <li key={index}>{error}</li>))}
                        </ul>} />
                      </Grid.Column>
                    </Grid.Row>
                  }
                </Grid>
              </Segment>
            </Segment.Group>
            <Segment.Group>
              <Segment>
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      <Button className="red" onClick={() => setIsModalOpen(true)} icon disabled={isUpdatingLocation}>{intl.get("button.delete")} <Icon name="trash" /></Button>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                      <Button primary onClick={onSubmit} disabled={isUpdatingLocation} icon>{intl.get("button.update")} <Icon name="check" /></Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Segment.Group>
          </Segment>
        </Segment.Group>
        <Modal open={isModalOpen} closeOnDimmerClick={deleteLocationMutation.loading}>
          <Modal.Header>{intl.get("admin.delete_location_confirm")}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Button className="red" onClick={handleDelete} icon disabled={deleteLocationMutation.loading}>{intl.get("button.delete")} <Icon name="trash" /></Button>
              <Button onClick={() => setIsModalOpen(false)} icon disabled={deleteLocationMutation.loading}>{intl.get("button.cancel")} <Icon name="cancel" /></Button>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Layout>
  )
}

export default LocationEditorPage;