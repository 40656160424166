import React, { useContext, useState, useEffect } from "react";
import { Router, Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { createHashHistory } from "history";
import { AuthContext } from "../context/auth";
import { removeToken, getRole } from "../utils";

// Components
import HeaderNav from "./HeaderNav";
import PrivateRoute from "./PrivateRoute";
import EasterEgg from "./EasterEgg";

// Pages
import AdminPage from "../pages/AdminPage";
import EditorPage from "../pages/EditorPage";
import IndexPage from "../pages/IndexPage";
import LoginPage from "../pages/LoginPage";
import LogoutPage from "../pages/LogoutPage";
import NoMatch from "../pages/NoMatch";
import ViewerPage from "../pages/ViewerPage";
import UserEditorPage from "../pages/UserEditorPage";
import UploadPage from "../pages/UploadPage";
import LocationEditorPage from "../pages/LocationEditorPage";
import GroupEditorPage from "../pages/GroupEditorPage";
import OTPVerificationPage from "../pages/OTPVerificationPage";

const browserHistory = createHashHistory({
  hashType: "slash"
});

function Routes(props) {
  const context = useContext(AuthContext);
  const { user } = context;
  const [hasGottenToken, setHasGottenToken] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [roleLevel, setRoleLevel] = useState(0)

  useEffect(() => {
    setHasGottenToken(false);
    user
      .then(userToken => {
        setCurrentUser(userToken);
        setHasGottenToken(true);
        if (userToken === null) removeToken();
      })
      .catch(err => {
        setHasGottenToken(true);
      });
  }, [user]);

  const updateUserToken = token => {
    // console.log(token);
    return "final";
  };

  useEffect(() => {
    // console.log('currentUser Changed', { user, currentUser });
  }, [user, currentUser]);


  useEffect(() => {
    if (currentUser) {
      let res = getRole(currentUser.userLevel.priority, currentUser.userLevel.accessType)
      setRoleLevel(parseInt(res))
    }
  }, [currentUser])

  return !hasGottenToken ? (
    false
  ) : (
    <Router history={browserHistory}>
      <Route
        render={props => (
          <HeaderNav hasGottenToken user={currentUser} {...props} />
        )}
      />
      <Switch>
        <Route
          exact
          path="/login"
          render={props => (
            <LoginPage
              hasGottenToken
              updateUserToken={updateUserToken}
              user={currentUser}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/otp-verification"
          hasGottenToken
          user={currentUser}
          component={OTPVerificationPage}
        />
        <Route
          exact
          path="/logout"
          hasGottenToken
          user={currentUser}
          component={LogoutPage}
        />
        <PrivateRoute
          exact
          path="/"
          hasGottenToken
          user={currentUser}
          userrole={roleLevel}
          component={IndexPage}
          testPriority={1}
        />
        <PrivateRoute
          exact
          path="/editor"
          hasGottenToken
          user={currentUser}
          userrole={roleLevel}
          component={EditorPage}
          testPriority={2}
        />
        <PrivateRoute
          exact
          path="/editor/:step"
          hasGottenToken
          user={currentUser}
          userrole={roleLevel}
          component={UploadPage}
          testPriority={2}
        />
        <PrivateRoute
          exact
          path="/editor/:step/:id"
          hasGottenToken
          user={currentUser}
          userrole={roleLevel}
          component={UploadPage}
          testPriority={2}
        />
        <Route
          exact
          path="/admin"
          render={() => <Redirect to="/admin/users" />}
          testPriority={3}
        />
        <PrivateRoute
          exact
          path="/viewer"
          hasGottenToken
          user={currentUser}
          userrole={roleLevel}
          component={ViewerPage}
          testPriority={1}
        />
        <PrivateRoute
          exact
          path="/admin/:adminroute"
          hasGottenToken
          user={currentUser}
          userrole={roleLevel}
          component={AdminPage}
          testPriority={3}
        />
        <PrivateRoute
          exact
          path="/admin/user/:id"
          hasGottenToken
          user={currentUser}
          userrole={roleLevel}
          component={UserEditorPage}
          testPriority={3}
        />
        <PrivateRoute
          exact
          path="/admin/location/:id"
          hasGottenToken
          user={currentUser}
          userrole={roleLevel}
          component={LocationEditorPage}
          testPriority={3}
        />
        <PrivateRoute
          exact
          path="/admin/group/:id"
          hasGottenToken
          user={currentUser}
          userrole={roleLevel}
          component={GroupEditorPage}
          testPriority={3}
        />
        <Route hasGottenToken user={currentUser} component={NoMatch} />
      </Switch>
      <EasterEgg />
    </Router>
  );
}

export default Routes;
