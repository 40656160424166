import gql from 'graphql-tag'

export default gql`
mutation cAccess($input: userAccessListInput!) {
  commonMutations {
    createUpdateUserAccessLayers(userAccessListInput: $input) {
      result
      message
      isSuccess
      isError
    }
  }
}
`

