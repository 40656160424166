import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Header, Grid, Segment, Form, Button, Icon, Table, Pagination } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import NewGroup from './NewGroup';
import GET_OFFICES from '../../graphql/getOffices';
import GET_CUSTOM_OFFICES from '../../graphql/getCustomOffices'
import GET_TOTAL_OFFICES from '../../graphql/getTotalOffices'
import Loader from '../Loader';
import intl from 'react-intl-universal';

function GroupEditor(props) {
  const { loading: totalofficeloading, data: totalofficedata, error: totalofficeerror } = useQuery(GET_TOTAL_OFFICES)
  const [state, setState] = useState({
    skip: 0,
    take: 9
  })
  const skip = state.skip
  const take = state.take
  const { loading: customofficeloading, data: customofficedata, error: customofficeerror } = useQuery(GET_CUSTOM_OFFICES, { variables: { skip, take } })
  const offices = useQuery(GET_OFFICES);
  const [getOffices, getOfficesQuery] = useLazyQuery(GET_OFFICES);
  const [isAddingOffice, toggleAddNewOffice] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [boundaryRange, setBoundaryRange] = useState(1)
  const [siblingRange, setSiblingRange] = useState(1)
  const [showEllipsis, setShowEllipis] = useState(true)
  const [showFirstAndLastNav, setShowFirstAndLastNav] = useState(true)
  const [showPreviousAndNextNav, setShowPreviousAndNextNav] = useState(true)
  const [count, setCount] = useState(0)
  const [hostdata, setHostdata] = useState()
  const handleToggleAddOffice = () => toggleAddNewOffice(!isAddingOffice);


  useEffect(() => {
    if (totalofficedata) {
      setCount(totalofficedata && totalofficedata.commonQueries && totalofficedata.commonQueries.getOfficesTotal && totalofficedata.commonQueries.getOfficesTotal.count)
    }
    if (customofficedata) {
      setHostdata(customofficedata && customofficedata.commonQueries && customofficedata.commonQueries.getOffices && customofficedata.commonQueries.getOffices)
    }
  }, [customofficedata, totalofficedata])


  if (offices.error) {
    return (
      <Grid>
        <Grid.Row>
          <Header as="h3">An Error has occured. Please try again.</Header>
        </Grid.Row>
        <Grid.Row>
          <Button
            onClick={() => getOffices()}
            loading={getOfficesQuery.loading}
            disabled={getOfficesQuery.loading}
            primary
            icon>Retry <Icon name="redo" />
          </Button>
        </Grid.Row>
      </Grid>
    )
  }

  const handlePaginationChange = async (e, { activePage }) => {
    setActivePage(activePage)
    setState({
      take: parseInt(9),
      skip: parseInt(activePage * 9 - 9)
    })
  }


  return (
    customofficeloading ?
      <Loader />
      :
      <>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column width={16}>
              {
                !isAddingOffice || !offices.data
                  ?
                  <Segment.Group className="adminToolBar">
                    <Segment>
                      <Button onClick={handleToggleAddOffice} primary>
                        <Icon name="add user" />{intl.get("admin.add_new_group")}
                      </Button>
                    </Segment>
                  </Segment.Group>
                  :
                  <Segment.Group className="addAdminItems">
                    <Segment>
                      <Grid>
                        <Grid.Column width={16}>
                          <Header as="h3" floated="left">{intl.get("admin.new_group")}</Header>
                          <Button icon="x" onClick={handleToggleAddOffice} color="red" floated="right" size="small" />
                        </Grid.Column>
                      </Grid>
                    </Segment>
                    <NewGroup cancelNewGroup={() => toggleAddNewOffice(false)} />
                  </Segment.Group>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Form>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{intl.get("admin.group_fields.department")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.group_fields.ministry")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.group_fields.level")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.group_fields.location")}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {hostdata && hostdata.map(office =>
                <Table.Row key={office._id}>
                  <Table.Cell>{office.department}</Table.Cell>
                  <Table.Cell>{office.ministry}</Table.Cell>
                  <Table.Cell>{office.level}</Table.Cell>
                  <Table.Cell>{office.location ? office.location.nameMM : ''}</Table.Cell>
                  <Table.Cell>
                    <Button as={Link} to={`/admin/group/${office._id}`}>
                      <span className="tgicon tgicon-edit"></span> {intl.get("button.edit")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          {
            Math.ceil(count && count / 9) <= 1 ? null : (<>
              <Pagination
                activePage={activePage}
                boundaryRange={1}
                siblingRange={1}
                onPageChange={handlePaginationChange}
                boundaryRange={boundaryRange}
                size='mini'
                siblingRange={siblingRange}
                ellipsisItem={showEllipsis ? undefined : null}
                firstItem={showFirstAndLastNav ? undefined : null}
                lastItem={showFirstAndLastNav ? undefined : null}
                prevItem={showPreviousAndNextNav ? undefined : null}
                nextItem={showPreviousAndNextNav ? undefined : null}
                totalPages={Math.ceil(count && count / 9)}
              />
            </>)
          }

        </Form>
      </>
  )
}

export default GroupEditor;
