import gql from 'graphql-tag'

export default gql`
query getLayerAccessesByUserID($where: JSON){
  commonQueries{
    getLayersAccesses(skip: 0, take: 100, where: $where){
      _id
      user{
        _id
        name
      }
      layer
      office{
        _id
        nameMM
        nameEN
      }
    }
  }
}
`
