import gql from 'graphql-tag';
export default gql
`query{
  userQueries{
  	getUserLevels{
      _id
      accessType
      priority
      titleEN
      titleMM
    }
  }
}`