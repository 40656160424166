import React, { useState } from 'react';
import { Accordion, Grid, Form, Icon, List, Header } from 'semantic-ui-react'
import intl from 'react-intl-universal';
import LockIndicator from '../LockIndicator';

function Step3(props) {

  const [accordionActiveIndex, setAccordionActiveIndex] = useState(0);
  const [authorization, setAuthorization] = useState(false)

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = accordionActiveIndex === index ? -1 : index;
    setAccordionActiveIndex(newIndex);
  }

  const { layer, layerID, layerData, renderMap, user } = props;
  console.log('layerData in Submit')
  console.log(layer)
  return (
    <>
      <Grid columns="equal" centered>
        <Grid.Row>
          <LockIndicator layer={layer} id={layerID} userid={user.id} authorization={authorization} setAuthorization={setAuthorization} />
        </Grid.Row>
      </Grid>
      <Grid.Row>
        <Grid.Column computer={6} widescreen={4}>
          <Accordion>
            <Accordion.Title active={accordionActiveIndex === 0} index={0} onClick={handleAccordionClick}>
              <span className="tgicon tgicon-approval-alt"></span>
              {intl.get("editor.submit.ask_approval")}
              <Icon name='dropdown' />
            </Accordion.Title>
            <Accordion.Content active={accordionActiveIndex === 0}>
              <Form>
                <Grid>
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      <Header size='small'>{intl.get("editor.submit.checklist")}</Header>
                      <List>
                        {
                          layer && layer.features && !!layer.features.length ? (
                            <List.Item>
                              <List.Content>
                                <span className="tgicon tgicon-checkmark"></span>
                          Upload File
                        </List.Content>
                            </List.Item>
                          ) : null
                        }
                        <List.Item>
                          <List.Content>
                            <span className="tgicon tgicon-checkmark"></span>
                          Fill Metadata
                        </List.Content>
                        </List.Item>
                        {
                          layer && layer.tableVars && !!layer.tableVars.length ? (
                            <List.Item>
                              <List.Content>
                                <span className="tgicon tgicon-checkmark"></span>
                          Table Variables
                        </List.Content>
                            </List.Item>
                          ) : null
                        }
                        {
                          layer && layer.tooltipVars && !!layer.tooltipVars.length ? (
                            <List.Item>
                              <List.Content>
                                <span className="tgicon tgicon-checkmark"></span>
                          Tooltips
                        </List.Content>
                            </List.Item>
                          ) : null
                        }
                        {
                          layer && layer.chartVars && !!layer.chartVars.length ? (
                            <List.Item>
                              <List.Content>
                                <span className="tgicon tgicon-checkmark"></span>
                          Filtercharts
                        </List.Content>
                            </List.Item>
                          ) : null
                        }
                        <List.Item>
                          <List.Content>
                            <span className="tgicon tgicon-task"></span>
                          Map Styling
                        </List.Content>
                        </List.Item>
                      </List>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Accordion.Content>
          </Accordion>
        </Grid.Column>
        <Grid.Column computer={10} widescreen={12}>
          {
            layer && layer._id
              ? renderMap([layer])
              : renderMap()
            // : <></>
          }
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default Step3;
