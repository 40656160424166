import React, { useState,useEffect } from 'react';
import { Checkbox, Accordion, Icon, Button } from 'semantic-ui-react';
import { barChartBar } from '../../utils';

function FilterCard(props) {

	// console.log("filter card props",props);
	const {groupBys,filterFunc,clearFunc,replaceFunc,filters,layerID,isThisItemChecked} = props;
	const [isAccordionOpen, setAccordionOpen] = useState(true);
	const handleAccordionToggle = event => setAccordionOpen(!isAccordionOpen);

	const [legendGroups, setLegendGroups] = useState({});
	const [nowPlaying, setNowPlaying] = useState(false);
	const [currentPlayItem, setCurrentPlayItem] = useState(0);
	const [currentPlayGroupBy, setCurrentPlayGroupBy] = useState();



	// console.log(groupBys);
	// console.log(props.uni.filters);
	// console.log(filters);


	useEffect(() => {
		console.log("Updating Groupbys for Charts");
		console.log(groupBys);
    setLegendGroups(groupBys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
	},[filters])

	useEffect(() => {
		if (nowPlaying) {
			const interval = setInterval(() => {
				if (currentPlayGroupBy.length > currentPlayItem + 1) {
					setCurrentPlayItem(currentPlayItem + 1);
					checkOnlyOneLegendItem(
						currentPlayGroupBy[currentPlayItem].groupBy,
						currentPlayGroupBy[currentPlayItem].key
					)
				} else {
					setCurrentPlayItem(0);
					// checkLegendItem(item.groupBy,item.key)
					// checkLegendItem(item.groupBy,item.key)
				}
			}, 200);
	
			return () => {
				clearInterval(interval);
			};
		}
	},[nowPlaying,currentPlayItem])


	const playThrough = (legendGroup,startStop) => {
		console.log(legendGroup);
		if (startStop === 'toggle') {
			if (!currentPlayGroupBy || currentPlayGroupBy[0].groupBy !== legendGroup[0].groupBy) {
				setCurrentPlayGroupBy(legendGroup);
				setCurrentPlayItem(0);
			}
			setNowPlaying(!nowPlaying);
		} else if (startStop === 'reset' && currentPlayGroupBy && currentPlayGroupBy[0].groupBy === legendGroup[0].groupBy) {
			clearLegendItems(
				currentPlayGroupBy[currentPlayItem].groupBy,
				[]
			)
			setCurrentPlayItem(0);

			// checkLegendItem(
			// 	currentPlayGroupBy[currentPlayItem].groupBy,
			// 	[]
			// )
		}
	}

	const getMax = (legendGroup) => {
    return Math.max(...legendGroup.map(item => item.value.count))
  }

	const checkLegendItem = (columnKey, filterObject) => {
		console.log(columnKey, filterObject, layerID);
		filterFunc(columnKey, filterObject, layerID);
	}

	const clearLegendItems = (columnKey, filterObject) => {
		console.log(columnKey, filterObject, layerID);
		clearFunc(columnKey, filterObject, layerID);
	}
	
	const checkOnlyOneLegendItem = (columnKey, filterObject) => {
		console.log(columnKey, filterObject, layerID);
		replaceFunc(columnKey, filterObject, layerID);
	}


	return (
		<Accordion className="LayerCard show">
		  <Accordion.Title onClick={handleAccordionToggle}>{props.title}</Accordion.Title>
		  <Accordion.Content active={isAccordionOpen}>
		    {/* <div className="layerControls">
		      <Checkbox toggle label="Chart Filters" />
		      <Checkbox toggle label="Data Table" />
		      <div className="opactiySlider">
		        <label>Opacity</label>
		        <input type="range" min="1" max="100" className="rangeSlider" />
		      </div>
		    </div> */}
		    {/* <Divider /> */}
				
		    <div className="mapLegend">
				{
					legendGroups.length > 0 
					?	(() => {
						return legendGroups.map((legendGroup,i) => 
							<div key={i}>
								{
									legendGroup[0].dataType === 'DATE'
									? <div className={"animationButtons"+i}>
											<Button icon
												key={"playButton"}
												className={"AnimateButton"}
												onClick={() => playThrough(legendGroup,"toggle")}
											>
												<i aria-hidden="true" className={
													(nowPlaying && currentPlayGroupBy && currentPlayGroupBy[0].groupBy === legendGroup[0].groupBy 
														? "pause" : "play") + " icon"}
												></i>
											</Button>
											<Button icon
												key={"resetButton"}
												className={"AnimateButton"}
												onClick={() => playThrough(legendGroup,"reset")}
												disabled={false}
											><i aria-hidden="true" className="undo icon"></i></Button>
											{
												currentPlayGroupBy && currentPlayGroupBy[0].groupBy === legendGroup[0].groupBy
												? <span class="content">{legendGroup[currentPlayItem].key}</span>
												: null
											}
										</div> 

									: null
									
								}
								
								{
									legendGroup.map((item,j) => {
										return <div key={i+"Sub"+j}>
											<Checkbox 
                        // label={item.key + ": " + item.value.count } 
                        className="filterChartCheckBox"
												checked={isThisItemChecked(item)} 
												disabled={nowPlaying}
                        onChange={(event, value) => checkLegendItem(item.groupBy,item.key)}
											/>
                      {barChartBar(item.key, 200, item.value.count, item.value.count, getMax(legendGroup))}
										</div>
									})
								}
							</div>		
						)})()
					:	null
						
				}
		    </div>
		  </Accordion.Content>
		</Accordion>
	);

}

export default FilterCard;