import React, { useContext } from 'react';
import { Button } from 'semantic-ui-react'
import { LanguageContext } from '../context/langProvider'

// import intl from 'react-intl-universal';

function LocaleSwitcher(props) {

	const currentLocale = localStorage.getItem('lang');
	const languageContext = useContext(LanguageContext)
	if (!currentLocale) {
		// localStorage.setItem('lang', 'my');
		localStorage.setItem('lang', 'en-US');
	}

	const otherLocale = (languageContext.language === 'my' ? { 'value': 'en-US', 'text': 'Change to English' } : { 'value': 'my', 'text': 'မြန်မာဘာသာသို့ပြောင်းလဲရန်' });

	const handleLocaleChange = (event, input) => {
		event.preventDefault();
		languageContext.setLanguage(event.target.value)
		localStorage.setItem('lang', event.target.value);
		// window.location.reload(false);
	}

	return (
		<>
			<Button secondary className="no-shadow" onClick={handleLocaleChange} value={otherLocale.value}>
				<span className="tgicon tgicon-language"></span>
				{otherLocale.text}
			</Button>
		</>
	)
}

export default LocaleSwitcher;