
import gql from 'graphql-tag';

export default gql`
query layerPropertiesQuery($id: String!){
  layersQuery{
    getLayerPropertyKeys(id: $id){
      _id
      nameEN
      nameMM
      source
      units
      fieldType
    }
  }
}
`;