import React, { useState, useEffect, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import { Grid, Container } from 'semantic-ui-react';
import Layout from '../components/Layout';
import StepBreadCrumbs from '../components/steps/StepBreadCrumbs';
import Steps from '../components/steps/Steps';
import StepNavigation from '../components/steps/StepNavigation';
import MapView from '../components/viewer/MapView';
import { LanguageContext } from '../context/langProvider';

import {
  defaultMapStylingsForMutation,
  l10nMain,
  defaultMapStylings,
  getKeyValuesForProperties,
  mapAggregateVizTypes,
} from '../utils';

// import LAYERS_QUERY from '../graphql/layersQuery';
import MUTATE_LAYER from '../graphql/mutateLayer';
import MUTATE_FIELD from '../graphql/mutateField';
import DELETE_FIELDS from '../graphql/deleteFields';
import LAYERS_DETAILS_QUERY from '../graphql/layersDetailsQuery';
import LAYER_FIELDS_QUERY from '../graphql/layerPropertiesQuery';
import USERS_QUERYS from '../graphql/usersQuery';
import LOCATION_QUERIES from '../graphql/getLocations';
import ACCESSES_QUERIES from '../graphql/getLayerAccesses';
import OFFICES_QUERIES from '../graphql/getOffices';
import LAYER_BYID from '../graphql/getLayerByID';

const defaultAlpha = defaultMapStylings.alpha;
const defaultVizType = mapAggregateVizTypes[0];
const l10n = (s, dontWrapInSpan) => l10nMain(s, 'en', dontWrapInSpan);

function UploadPage(props) {
  let layerData = [];
  const { step, id } = props.match.params;
  const [layer, setLayer] = useState({});
  const [isNewLayerData, setIsNewLayerData] = useState(false);
  const [layerID, setlayerID] = useState(null);
  const [fields, setFields] = useState([]);
  const [fieldsToDelete, setFieldsToDelete] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [userdata, setUserdata] = useState();
  const [locationdata, setLocationdata] = useState();
  const [accessdata, setAccessdata] = useState();
  const [officedata, setOfficedata] = useState();
  const [sharedstatus, setSharedStatus] = useState(null);
  const {
    loading: userloading,
    data: usersqueries,
    error: userserror,
  } = useQuery(USERS_QUERYS);
  const {
    loading: locationloading,
    data: locationsqueries,
    error: locationerror,
  } = useQuery(LOCATION_QUERIES);
  const {
    loading: accessloading,
    data: accessesqueries,
    error: accesseserror,
  } = useQuery(ACCESSES_QUERIES);
  const {
    loading: officeloading,
    data: officesqueries,
    error: officeserror,
  } = useQuery(OFFICES_QUERIES);
  const {
    loading: layerloading,
    data: layerdata,
    error: layererror,
  } = useQuery(LAYER_BYID, { variables: { id } });
  const layerQueries = useQuery(LAYERS_DETAILS_QUERY, {
    variables: { json: { _id: layerID } },
  });
  const layerFieldQueries = useQuery(LAYER_FIELDS_QUERY, {
    variables: { id: layerID },
  });

  const languageContext = useContext(LanguageContext);

  // console.log("params: step... " + step, "id... " + id);
  // console.log("layerID state... " + layerID);
  // console.log("redirect boolean... " + redirect);

  useEffect(() => {
    setRedirect(false);
  }, [step, id]);

  useEffect(() => {
    if (id) {
      setlayerID(id);
    }
  }, [id]);

  useEffect(() => {
    if (layerdata) {
      setLayer(
        layerdata &&
          layerdata.layersQuery &&
          layerdata.layersQuery.getLayerByID,
      );
    }
  }, [layerdata]);

  useEffect(() => {
    if (!layerFieldQueries.loading && layerFieldQueries.data) {
      // console.log("New Fields Data from DB...", layerFieldQueries.data.layersQuery.getLayerPropertyKeys);
      setFields(layerFieldQueries.data.layersQuery.getLayerPropertyKeys);
    }
  }, [layerFieldQueries]);

  useEffect(() => {
    if (usersqueries) {
      setUserdata(usersqueries.userQueries);
    }

    if (locationsqueries) {
      setLocationdata(locationsqueries.commonQueries);
    }

    if (accessesqueries) {
      setAccessdata(accessesqueries.commonQueries);
    }

    if (officesqueries) {
      setOfficedata(officesqueries.commonQueries);
    }
  }, [usersqueries, locationsqueries, accessesqueries, officesqueries]);

  const [mutateLayer] = useMutation(MUTATE_LAYER, {
    onCompleted: (data) => {
      const layerIDFromDB = data.layerMutations.createUpdateLayer.result._id;
      console.log(data.layerMutations.createUpdateLayer.result);
      console.log(layerIDFromDB);
      setlayerID(layerIDFromDB);
      if (!id) setRedirect(true);

      // setIsSubmitting(false);
      // overrideValues(initialState);
      // cancelNewLocation();
    },
    errorPolicy: 'all',
    onError: (err) => {
      console.log(err);
    },
  });

  const [mutateField] = useMutation(MUTATE_FIELD, {
    onCompleted: (data) => {
      // const layerIDFromDB = data.layerMutations.createUpdateLayerField.result._id;
      console.log(data.layerMutations.createUpdateLayerField.result);
      // console.log(layerIDFromDB);
      // setlayerID(layerIDFromDB);
      if (!id) setRedirect(true);

      // setIsSubmitting(false);
      // overrideValues(initialState);
      // cancelNewLocation();
    },
    errorPolicy: 'all',
    onError: (err) => {
      console.log(err);
    },
  });

  const [deleteFields] = useMutation(DELETE_FIELDS, {
    onCompleted: (data) => {
      console.log('Deleted fields...', data);
      setFieldsToDelete([]);

      // setIsSubmitting(false);
      // overrideValues(initialState);
      // cancelNewLocation();
    },
    errorPolicy: 'all',
    onError: (err) => {
      console.log(err);
    },
  });

  const updateLayerInDB = () => {
    console.log('Calling Update Layer in DB');
    console.log(layer);
    const {
      uploadedBy,
      publishedBy,
      createdAt,
      updatedAt,
      ...otherLayerProps
    } = layer;
    let layerForMutation = {};
    if (!layerID && !isNewLayerData) {
      // layer not yet written in DB and there is no layer data (i.e. just metadata)
      layerForMutation = {
        layer: {
          ...otherLayerProps,
          mapStyling: layer.mapStyling
            ? layer.mapStyling
            : defaultMapStylingsForMutation,
        },
        fields: fields.map((field) => {
          const { _id, ...otherProperties } = field;
          return otherProperties;
        }),
      };
    } else if (!layerID && isNewLayerData) {
      // layer not yet written in DB but there is layer data uploaded to front end
      layerForMutation = {
        layer: {
          ...otherLayerProps,
          mapStyling: layer.mapStyling
            ? layer.mapStyling
            : defaultMapStylingsForMutation,
          features: layer.features.map((feature) => ({
            ...feature,
            properties: feature.properties.map((property) => ({
              ...property,
              field: {
                nameEN: fields.filter(
                  (fieldsState) => fieldsState._id === property.field._id,
                )[0].nameEN,
              },
            })),
          })),
        },
        fields: fields.map((field) => {
          const { _id, ...otherProperties } = field;
          return otherProperties;
        }),
      };
    } else if (layerID && !isNewLayerData) {
      // layer is written in DB and there is no new layer data
      layerForMutation = {
        layer: {
          ...otherLayerProps,
          mapStyling: layer.mapStyling
            ? layer.mapStyling
            : defaultMapStylingsForMutation,
          _id: layerID,
          location: layer.location
            ? layer.location._id
              ? layer.location._id
              : layer.location
            : null,
          office: layer.office
            ? layer.office._id
              ? layer.office._id
              : layer.office
            : null,
        },
        fields: fields.map((field) => {
          return { ...field };
        }),
      };
    } else if (layerID && isNewLayerData) {
      // layer is written in DB and there is a new data layer uploaded
      layerForMutation = {
        layer: {
          ...otherLayerProps,
          _id: layerID,
          location: layer.location._id,
          office: layer.office._id,
          mapStyling: layer.mapStyling
            ? layer.mapStyling
            : defaultMapStylingsForMutation,
          features: layer.features.map((feature) => ({
            ...feature,
            properties: feature.properties.map((property) => ({
              ...property,
              field: {
                nameEN: fields.filter(
                  (fieldsState) => fieldsState._id === property.field._id,
                )[0].nameEN,
              },
            })),
          })),
        },
        fields: fields.map((field) => {
          return { ...field };
        }),
      };
    }

    // Make sure tooltipVars, tableVars, charVars fields only have arrays of ids

    layerForMutation = {
      ...layerForMutation,
      layer: {
        ...layerForMutation.layer,
        lockedBy:
          layerForMutation.layer.lockedBy &&
          layerForMutation.layer.lockedBy._id &&
          layerForMutation.layer.lockedBy._id,
        tooltipVars:
          layerForMutation.layer.tooltipVars &&
          layerForMutation.layer.tooltipVars.length > 0
            ? layerForMutation.layer.tooltipVars.map(
                (tooltipVar) => tooltipVar._id,
              )
            : [],
        tableVars:
          layerForMutation.layer.tableVars &&
          layerForMutation.layer.tableVars.length > 0
            ? layerForMutation.layer.tableVars.map((tableVar) => tableVar._id)
            : [],
        chartVars:
          layerForMutation.layer.chartVars &&
          layerForMutation.layer.chartVars.length > 0
            ? layerForMutation.layer.chartVars.map((chartVar) => ({
                ...chartVar,
                field: chartVar.field._id,
              }))
            : [],
        mapStyling: {
          ...layerForMutation.layer.mapStyling,
          stylingColumns:
            layerForMutation.layer.mapStyling.stylingColumns &&
            layerForMutation.layer.mapStyling.stylingColumns.length > 0
              ? layerForMutation.layer.mapStyling.stylingColumns.map((col) => ({
                  ...col,
                  field: col.field._id,
                }))
              : [],
        },
      },
    };

    setIsNewLayerData(false);
    console.log('Writing this layer to DB...', layerForMutation);
    console.log(layerForMutation);
    mutateLayer({
      variables: layerForMutation,
    });
    for (let i = 0; i < layerForMutation.fields.length; i++) {
      mutateField({
        variables: { field: layerForMutation.fields[i] },
      });
    }
    if (fieldsToDelete.length > 0) {
      console.log('Deleting these fields from DB...', fieldsToDelete);
      deleteFields({
        variables: { id: fieldsToDelete },
      });
    }
  };

  useEffect(() => {
    if (
      layer &&
      layer.features &&
      layer.features.length > 0 &&
      fields &&
      fields.length > 0
    ) {
      setLayer({
        ...layer,
        features: layer.features.map((feature) => ({
          ...feature,
          properties: feature.properties.map((property) => ({
            ...property,
            field: {
              ...fields.filter(
                (fieldsState) => fieldsState._id === property.field._id,
              )[0],
            },
          })),
        })),
      });
    }
  }, [fields]);

  const getDataFromLayersForMap = (layerDataSets) => {
    const layersData = {};
    layerDataSets.forEach((layerDataSet) => {
      layerData.push(
        (layersData[layerDataSet._id] = {
          ...layerDataSet,
          dataSet: [
            {
              features: getKeyValuesForProperties(layerDataSet.features),
            },
          ],
        }),
      );
      layersData[layerDataSet._id] = {
        ...layerDataSet,
        dataSet: [
          {
            features: getKeyValuesForProperties(layerDataSet.features),
          },
        ],
      };
    });

    return layersData;
  };

  const prepMapProps = (layerDataSets) => {
    const defaultProps = {
      height: '450px',
      // "height": "92vh",
      width: '100%',
      // "width": "100vw - 10",
      center: [19.75, 96.1],
      data: {},
      layersControlsData: [],
      layerSortOrder: [],
    };

    return !layerDataSets
      ? defaultProps
      : {
          ...defaultProps,
          data: getDataFromLayersForMap(layerDataSets),
          layersControlsData: layerDataSets.map((layerDataSet) => ({
            _id: layerDataSet._id,
            title: layerDataSet.nameEN,
            alpha: defaultAlpha,
            showing: true,
            mapVizType: defaultVizType,
          })),
          layerSortOrder: layerDataSets.map((layerDataSet) => ({
            _id: layerDataSet._id,
          })),
          center:
            layerDataSets.length > 0 &&
            layerDataSets[0].location &&
            layerDataSets[0].location.center &&
            layerDataSets[0].location.center.lat &&
            layerDataSets[0].location.center.lon
              ? [
                  layerDataSets[0].location.center.lat,
                  layerDataSets[0].location.center.lon,
                ]
              : defaultProps.center,
        };
  };

  const renderMap = (props) => {
    // console.log(props);
    const dataForMap = props ? prepMapProps(props) : prepMapProps();
    console.log(dataForMap);
    return (
      <MapView
        data={dataForMap.data}
        layersControlsData={dataForMap.layersControlsData}
        layerSortOrder={dataForMap.layerSortOrder}
        height={dataForMap.height}
        width={dataForMap.width}
        center={dataForMap.center}
        l10n={l10n}
        language={languageContext.language}
        softFilters={[]}
      />
    );
  };

  return layerID && redirect ? (
    <Redirect to={`/editor/${step}/${layerID}`} />
  ) : (
    // return (
    <div style={{ margin: '20px 20px' }}>
      <Grid columns="equal" centered>
        <Grid.Row>
          <StepBreadCrumbs
            step={step}
            updateLayer={updateLayerInDB}
            layerID={layerID}
            layer={layer}
            setSharedStatus={setSharedStatus}
          />
        </Grid.Row>
      </Grid>
      <Grid columns="equal">
        <StepNavigation
          step={step}
          updateLayer={updateLayerInDB}
          layerID={layerID}
          layer={layer}
          setSharedStatus={setSharedStatus}
        />
        <Steps
          step={step}
          layerID={layerID}
          layer={layer}
          setLayer={setLayer}
          layerData={layerData}
          fields={fields}
          setFields={setFields}
          setIsNewLayerData={setIsNewLayerData}
          setFieldsToDelete={setFieldsToDelete}
          accessData={accessdata}
          officeData={officedata}
          userData={userdata}
          user={props.user}
          renderMap={renderMap}
          sharedstatus={sharedstatus}
        />
      </Grid>
    </div>
  );
}

export default UploadPage;
