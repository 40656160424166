import gql from 'graphql-tag';

export default gql`
  query singleLocationQuery($id: ID!){
    commonQueries{
      getLocationById(id:$id){
        _id
        level
        center{
          lat
          lon
        }
        nameEN
        nameMM
        placeCode
        parent{
          _id
          nameMM
          nameEN
        }
        # baseLayers{
        #   _id
        # }
      }
    }
  }
`;