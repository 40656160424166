import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/auth';
import { LocalizationContext } from '../context/localization';
import { Button, Form, Message, Grid } from 'semantic-ui-react';
import axios from 'axios';
import Layout from '../components/Layout';
import '../styles/LoginPage.scss';

import PrimaryLogo from '../images/PrimaryLogo.png';
import LoginImage from '../images/Illustraions_LogIn.svg';

function LoginPage(props) {
  const context = useContext(AuthContext);
  const { locale } = useContext(LocalizationContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    isError: false,
    message: ''
  });

  const [values, setValues] = useState({
    email: '',
    password: ''
  });

  const { user, hasGottenToken, updateUserToken } = props;

  if (hasGottenToken && user) {
    props.history.push('/');
  }

  const onChange = (event) =>
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

  const onSubmit = () => {
    const loginEndpoint = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/auth/login' : '/auth/login';
    const config = { headers: { 'Content-Type': 'application/json', 'Accept-Language': locale } };
    setLoading(true);

    axios.post(loginEndpoint, {
      user: {
        ...values
      }
    }, config)
      .then(function (response) {
        setLoading(false)
        setErrors({
          isError: false,
          message: ''
        })
        if (response.data.otpRequired) {
          const { otpRequestToken } = response.data;
          context.verifyOTP(otpRequestToken,
            () => props.history.push('/otp-verification'));
        }
        else {
          const { user } = response.data;
          context.login(user)
            .then((data) => {
              console.log(data);
              updateUserToken(data);
            }).then(data => console.log(data))
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response === undefined) {
          setLoading(false);
          setErrors({
            isError: true,
            message: `There seems to be an error with our server. Please try again later.`
          })
        }
        else {
          const { message } = error.response.data
          setLoading(false);
          setErrors({
            isError: true,
            message: `Error: ${message}`
          })
        }
      });
  };

  return (
    <Layout className="LoginPage">
      <Grid>
        <Grid.Row>
          <Grid.Column width={5} className="LoginLeftRail">
            <div className="Illustration" style={{ backgroundImage: `url(${LoginImage})` }}></div>
          </Grid.Column>
          <Grid.Column width={11} className="LoginRightRail">
            <div className="LoginForm">
              <img src={PrimaryLogo} alt="TGIST Primary Logo" height="200px" />
              <Form onSubmit={onSubmit} noValidate className={loading ? 'loading' : ''}>
                <Message negative compact errors={errors} style={errors.isError ? {} : { display: 'none' }}>
                  <Message.Header>Login Error</Message.Header>
                  <p>{errors.message}</p>
                </Message>
                <Form.Input
                  label="Email"
                  placeholder="Email"
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={onChange}
                />
                <Form.Input
                  label="Password"
                  placeholder="Password.."
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={onChange}
                />
                <Button type="submit" primary><span className="tgicon tgicon-login"></span> <span>Login</span></Button>
              </Form>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  );
}

export default LoginPage;
