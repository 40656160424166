import React, {useState,useEffect} from 'react';
import { Input, Form, Dropdown, Button } from 'semantic-ui-react'
import intl from 'react-intl-universal';
import { SwatchesPicker } from 'react-color';
import {extent} from 'd3-array';
import { defaultMapStylings } from '../../utils';


function BinSelectorComponent(props) {
  const {field,fieldValues,bins,optionsForValues,optionsChangeHandler,type} = props;
  // console.log(props);

  const [uniqueVals, setUniqueVals] = useState([]);
  const [numericRangeBins, setNumericRangeBins] = useState();
  const [binsState, setBinsState] = useState(bins);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [currentBinIndex,setCurrentBinIndex] = useState();
  const [keyOptions, setKeyOptions] = useState([]);

  const handleColorPickerClick = (i) => {
    setCurrentBinIndex(i);
    setDisplayColorPicker(!displayColorPicker);
  }

  const getValuesToPopulateKeysDropDown = (fieldValues,binsState) => {
    // console.log(uniqueVals,fieldValues,binsState)
    let values = uniqueVals ? [...uniqueVals] : [];
    if (binsState && binsState.length > 0) {
      const binKeys = binsState.map(bin => bin.key);
      values = values.filter(value => {
        return !binKeys.includes(value);
      })
    }
    return values;
  }

  useEffect(() => {
    setBinsState(bins);
  },[bins])

  const addBins = (type) => {
    if (type !== 'CHARTVAR') {
      const defaultValue = defaultMapStylings[type];
      // console.log("Add a bin... ", type, defaultValue);
      const newBinsStateToSet = binsState && binsState.length > 0
        ? [...binsState, {min: "", max: "", key: "", value: defaultValue}]
        : [{min: "", max: "", key: "", value: defaultValue}]
      optionsChangeHandler(newBinsStateToSet.length-1,'value',defaultValue);
      setBinsState(newBinsStateToSet)
    } else {
      const newBinsStateToSet = binsState && binsState.length > 0
        ? [...binsState, {min: "", max: "", key: ""}]
        : [{min: "", max: "", key: ""}]
        setBinsState(newBinsStateToSet)
    }
  }

  useEffect(() => {
    if (field.fieldType === 'TEXT') {
      setKeyOptions(
        getValuesToPopulateKeysDropDown(fieldValues,binsState)
      );
    } else if (field.fieldType === 'NUMBER') {
      setKeyOptions([]);
    }
  },[binsState,uniqueVals])

  useEffect(() => {
    // console.log(fieldValues);
    if (field.fieldType === 'TEXT') {
      setUniqueVals(new Set(fieldValues));
      setNumericRangeBins();
    } else if (field.fieldType === 'NUMBER') {
      const minMax = extent(fieldValues.map(d => +d))
      setNumericRangeBins([{start: minMax[0], end: minMax[1], count: fieldValues.length}])
      setUniqueVals([]);
    }
  },[field._id])

  let returnJSX = null;
  const disabled = props.disabled === undefined ? false : props.disabled;

  // console.log(keyOptions);
  // console.log(numericRangeBins);

  const getValueSelector = (type,label,name,optionsForValues,val,i) => {
    // console.log(i);
    if (type === 'COLOR') {
      return <span>
        {/* <Form.Field> */}
          <Button
            style={{
              backgroundColor: (val ? val : defaultMapStylings.color),
              width: '20px',
              height: '40px'
            }}
            onClick={() => handleColorPickerClick(i)}
          />
          
          {/* </Form.Field> */}
      </span>
    } else if (type === 'CHARTVAR') {
      return <span>-</span>
    } else if (type !== 'COLOR' || type !== 'CHARTVAR') {
      return <Dropdown
        label={label}
        name={name}
        options={optionsForValues}
        value={val}
        selection
        onChange={(event, { value }) => optionsChangeHandler(i,'value',value)}
      />
    } 
  }


  returnJSX = <>
    {
      field 
        ? <label>{field.nameEN} is a {field.fieldType} column</label>
        : null
    } 
    {
      numericRangeBins
      ? <p>Range: {numericRangeBins[0].start} to {numericRangeBins[0].end}</p>
      : null
    }
    {
      binsState && binsState.length > 0 && uniqueVals && keyOptions
      ? field.fieldType === 'NUMBER'
        ? binsState.map((bin,i) => <Form.Group key={'bin'+i}>
            <Form.Field width={4}>
            From: <Input 
              value={bin.min}
              onChange={(event, { value }) => optionsChangeHandler(i,'min',value)}
              />
            </Form.Field>
            <Form.Field width={4}>
            To: <Input 
              value={bin.max} 
              onChange={(event, { value }) => optionsChangeHandler(i,'max',value)}
              />
            </Form.Field>
            <Form.Field width={6}>
            {
              type !== 'CHARTVAR'
              ? <>
                Value: {getValueSelector(type,"Display Option Select","displayOptionSelect",optionsForValues,bin.value,i)}
                </>
              : null
            }
            </Form.Field>
            <Form.Field width={2} align="right">
              <Button icon basic color="red" size="mini"
                onClick={() => optionsChangeHandler(i,'value','',true)}
              ><i aria-hidden="true" className="close icon"></i></Button>
            </Form.Field>
          </Form.Group>) 
        : binsState.map((bin,i) => <Form.Group key={'bin'+i}>
          <Form.Field width={8}>
            <Dropdown
              label={"Color Column"}
              name={"colorColumn"}
              options={
                [
                  {key: bin.key, value: bin.key, text: bin.key},
                  ...keyOptions.map(option => ({key: option, value: option, text: option}))
                ]
              }
              value={bin.key}
              selection
              onChange={(event, { value }) => optionsChangeHandler(i,'key',value)}
            />
          </Form.Field>
          <Form.Field width={6}>
          {
            type !== 'CHARTVAR'
            ? <>
                {getValueSelector(type,"Display Option Select","displayOptionSelect",optionsForValues,bin.value,i)}
              </>
            : null
          }
          </Form.Field>
          <Form.Field width={2} align="right">
            <Button icon basic color="red" size="mini"
              onClick={() => optionsChangeHandler(i,'value','',true)}
            ><i aria-hidden="true" className="close icon"></i></Button>
          </Form.Field>
        </Form.Group>) 
      : null
    }
    {
      displayColorPicker
        ? <span style={{
            position: 'absolute',
            zIndex: '10',
          }}>
            <div style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px'
            }} onClick={handleColorPickerClick} />
            <SwatchesPicker
              color={binsState[currentBinIndex].value ? binsState[currentBinIndex].value  : defaultMapStylings.color}
              onChange={(color) => optionsChangeHandler(currentBinIndex,'value',color.hex)}
            />
          </span>
        : null
    }

            
    <br/>
    {
      (field.fieldType === 'TEXT' && keyOptions && keyOptions.length <= 0) ||
      (field.fieldType !== 'TEXT' && field.fieldType !== 'NUMBER')
      ? null
      : <Button primary
          onClick={() => addBins(type)}
        ><i aria-hidden="true" className="chevron plus icon"></i> Add Value</Button>
      
    }
  </>


  return (
    returnJSX
  )

}

export default BinSelectorComponent;
