import React from 'react';
import DatePicker from "react-datepicker";
import { Input, Form, Dropdown, Grid } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import intl from 'react-intl-universal';

function LayerFieldComponent(props) {
  const {field,handleFieldChange} = props;

  // console.log(field);
  // if (inputType === 'text') {
  //   returnJSX = <Form.Field>
  //     <h4>{intl.get(label)}</h4>
  //     <Input 
  //       placeholder={intl.get(placeholder)}
  //       onChange={(event, { value }) => changeFunc(value)} 
  //       value={value}
  //       disabled={disabled}
  //       />
  //   </Form.Field>
  // } else if (inputType === 'dropdown') {
  //   returnJSX = <div className="field">
  //     <h4>{intl.get(label)}</h4>
  //     <Dropdown
  //       label={label}
  //       name={label}
  //       options={options}
  //       placeholder={placeholder}
  //       value={value}
  //       selection
  //       fluid
  //       disabled={disabled}
  //       onChange={(event, { value }) => changeFunc(value)} />
  //   </div>
  // }

  const returnJSX = <div key={"columnProps"+field._id} className="layerFieldContainer">
    <Grid.Row>
      <h4 style={{ textAlign:'center' }}> Column ID {field._id+1}</h4>
    </Grid.Row>
    <br/>
    <Grid.Row>
      <Form.Field>
        <label>{intl.get('editor.upload.column_name_en')}</label>
        <Input 
          value={field.nameEN}
          onChange={(event, { value }) => handleFieldChange('nameEN')(field._id,value)} />
      </Form.Field>
      <Form.Field>
        <label>{intl.get('editor.upload.column_name_mm')}</label>
        <Input 
          value={field.nameMM}
          onChange={(event, { value }) => handleFieldChange('nameMM')(field._id,value)} />
      </Form.Field>
      <Form.Field>
        <label>{intl.get('editor.upload.column_field_type')}</label>
        {/* <Input 
          value={field.fieldType}
          onChange={(event, { value }) => handleFieldChange('fieldType')(field.index,value)} /> */}
          <Dropdown
            label={'fieldType'}
            name={'fieldType'}
            options={['TEXT',
              'NUMBER',
              'DATE',
              'YEAR',
              'MONTHYEAR',
              'TIME'].map(d => ({key:d,value:d,text:d}))}
            defaultValue={field.fieldType}
            // placeholder={placeholder}
            selection
            fluid
            onChange={(event, { value }) => handleFieldChange('fieldType')(field._id,value)} />
      </Form.Field>
      <Form.Field>
        <label>{intl.get('editor.upload.column_source')}</label>
        <Input 
          value={field.source ? field.source : ""}
          onChange={(event, { value }) => handleFieldChange('source')(field._id,value)} />
      </Form.Field>
      <Form.Field>
        <label>{intl.get('editor.upload.column_unit')}</label>
        <Input 
          value={field.units ? field.units : ""}
          onChange={(event, { value }) => handleFieldChange('units')(field._id,value)} />
      </Form.Field>
    </Grid.Row>
    </div>

  return (
    returnJSX
  )

}

export default LayerFieldComponent;
