import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { readString } from 'react-papaparse';
// import DatePicker from 'react-datepicker';
import {
  Accordion,
  Grid,
  Input,
  Form,
  Segment,
  Icon,
  Dropdown,
  Button,
  Menu,
  Container,
} from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import intl from 'react-intl-universal';
import LayerPropertyComponent from './LayerPropertyComponent';
import LayerFieldComponent from './LayerFieldComponent';
import EditorTable from '../EditorTable';
import { prepDataForLayerDataTable, getGeoJSONType } from '../../utils';
import GET_OFFICES from '../../graphql/getOffices';
import GET_OFFICES_BY_PARENT from '../../graphql/getOfficesByParent';
import GET_LOCATIONS from '../../graphql/getLocations';
import GET_LAYERS_FOR_LOCATION from '../../graphql/getLayersByInput';
import LAYERS_DETAILS_QUERY from '../../graphql/getLayerByID';
import LockIndicator from '../LockIndicator';
import DataCleaningForColumnComponent from './DataCleaningForColumnComponent';
import { getRole } from '../../utils';
import { LanguageContext } from '../../context/langProvider';

function Step1(props) {
  const {
    layer,
    setLayer,
    fields,
    setFields,
    layerID,
    setIsNewLayerData,
    setFieldsToDelete,
    setLayerType,
    layerType,
    user,
    sharedstatus,
  } = props;
  const languageContext = useContext(LanguageContext);
  const [accordionActiveIndex, setAccordionActiveIndex] = useState(0);
  const [dataForPreview, setDataForPreview] = useState();
  const [currentField, setCurrentField] = useState(null);
  const locationData = useQuery(GET_LOCATIONS);
  const {
    loading: locations_loading,
    data: locations_data,
    error: locations_error,
  } = useQuery(GET_LOCATIONS);
  const officesFromQuery = useQuery(GET_OFFICES);
  const {
    loading: offices_loading,
    data: offices_data,
    error: offices_error,
  } = useQuery(GET_OFFICES);
  const {
    loading: getOfficesByParent_Loading,
    data: getOfficesByParent_data,
    error: getOfficesByParent_error,
  } = useQuery(GET_OFFICES_BY_PARENT, {
    variables: {
      skip: 0,
      take: 100,
      where: {
        parent: {
          _id: user.office,
        },
      },
    },
    skip: !user || user.office === '',
  });
  const [csvFileUploaded, setCsvFileUploaded] = useState(false);
  const [geometryPicked, setGeometryPicked] = useState(false);
  const [rawUploadedDataCache, setRawUploadedDataCache] = useState();
  const [latLonCols, setLatLonCols] = useState({ latCol: null, lonCol: null });
  const [existingLayerToJoinWith, setExistingLayerToJoinWith] = useState();
  const [currentLocation, setCurrentLocation] = useState();
  const [authorization, setAuthorization] = useState(false);
  const [roleLevel, setRoleLevel] = useState(0);
  const [LocationData, setLocationData] = useState(null);
  const [OfficeData, setOfficeData] = useState(null);
  const [OfficesFromParent, setOfficesFromParent] = useState(null);
  const layersFromLocation = useQuery(GET_LAYERS_FOR_LOCATION, {
    variables: {
      skip: 0,
      take: 100,
      where: {
        location: { _id: currentLocation ? currentLocation._id : null },
      },
    },
    skip: currentLocation === null || currentLocation === undefined,
  });
  const layerToJoinWithQuery = useQuery(LAYERS_DETAILS_QUERY, {
    variables: {
      id: existingLayerToJoinWith ? existingLayerToJoinWith._id : null,
    },
    skip:
      existingLayerToJoinWith === null || existingLayerToJoinWith === undefined,
  });
  const [
    mapJoinColValsFromOriginalGeoLayerToGeometry,
    setMapJoinColValsFromOriginalGeoLayerToGeometry,
  ] = useState();
  const [
    fieldFromNewLayerToJoinWith,
    setFieldFromNewLayerToJoinWith,
  ] = useState();
  const [offices, setOffices] = useState(null);
  const [tempoffices, setTempOffices] = useState(null);
  // console.log(props);

  const getCountsObjForField = (field, layer) => {
    const countsObj = {};
    if (layer && layer.features) {
      for (let i = 0; i < layer.features.length; i++) {
        const properties = layer.features[i].properties;
        // console.log(properties)
        const currentFieldIndexInRow = properties.findIndex((p) => {
          return p.field._id === field._id;
        });
        // console.log(currentFieldIndexInRow);
        if (currentFieldIndexInRow > -1) {
          properties[currentFieldIndexInRow].value in countsObj &&
          Array.isArray(countsObj[properties[currentFieldIndexInRow].value])
            ? countsObj[properties[currentFieldIndexInRow].value].push(i)
            : (countsObj[properties[currentFieldIndexInRow].value] = [i]);
        }
      }
    }

    return countsObj;
  };

  const getLocation = (layer, locationData, newLocationID) => {
    // console.log(layer, locationData);
    if (newLocationID) {
      const chosenLocationFromQuery = locationData.commonQueries.getLocations.filter(
        (d) => d._id === newLocationID,
      );

      return chosenLocationFromQuery.length > 0
        ? chosenLocationFromQuery[0]
        : null;
    } else if (layer.location) {
      if (layer.location._id) return layer.location;
      else if (locationData) {
        const chosenLocationFromQuery = locationData.commonQueries.getLocations.filter(
          (d) => d._id === layer.location,
        );

        return chosenLocationFromQuery.length > 0
          ? chosenLocationFromQuery[0]
          : null;
      }
    } else {
      return null;
    }
  };

  const getLocationName = () => {
    const location = currentLocation;
    if (location) {
      return (
        'Available layers to joing with in ' +
        location.nameEN +
        ' (' +
        location.level +
        ')'
      );
    } else {
      return 'To join with an existing layer you have to choose a location first.';
    }
  };

  useEffect(() => {
    if (user) {
      let res = getRole(user.userLevel.priority, user.userLevel.accessType);
      setRoleLevel(parseInt(res));
    }
  }, [user]);

  const createMapJoinColValsFromOriginalGeoLayerToGeometry = (
    existingGeoLayer,
    field,
  ) => {
    // console.log(existingGeoLayer, field);
    const fieldValueToGeos = existingGeoLayer.features.map((property) => {
      const fieldValPairWeAreLookingFor = property.properties.filter(
        (feature) => {
          return feature.field._id === field._id;
        },
      );

      if (fieldValPairWeAreLookingFor.length > 0) {
        return [fieldValPairWeAreLookingFor[0].value, property.geometry];
      } else {
        return null;
      }
    });

    const fieldValueToGeosObj = Object.assign(
      ...fieldValueToGeos.map(([key, val]) => ({ [key]: val })),
    );
    console.log(fieldValueToGeosObj);
    setMapJoinColValsFromOriginalGeoLayerToGeometry({
      type: existingGeoLayer.type,
      mapToGeos: fieldValueToGeosObj,
    });
  };

  const replaceValues = (field, valuesTracker, countsObj) => {
    console.log(field, valuesTracker, countsObj);
    const colIndexToChange = getFieldIndex(fields, field);
    const newFeatures = [...layer.features];
    Object.keys(valuesTracker).forEach((trackedValue) => {
      countsObj[trackedValue].forEach((rowIndexToChange) => {
        newFeatures[rowIndexToChange].properties[colIndexToChange].value =
          valuesTracker[trackedValue];
      });
    });
    setLayer({
      ...layer,
      features: newFeatures,
    });
  };

  useEffect(() => {
    if (fields.length > 0 && !currentField) {
      setCurrentField(fields[0]);
    }
  }, [fields]);

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = accordionActiveIndex === index ? -1 : index;
    setAccordionActiveIndex(newIndex);
  };

  const handlePropertyChange = (property) => (value) => {
    if (property === 'location') {
      setCurrentLocation(getLocation(layer, LocationData, value));
    }
    // console.log(layer);
    setLayer({ ...layer, [property]: value });
    console.log(layer);
  };

  const handleFieldChange = (key) => (index, value) => {
    // console.log("changing fields...", index, value)
    const editedFields = [...fields];
    editedFields.filter((f) => f._id === index)[0][key] = value;
    // console.log(editedFields);
    setFields(editedFields);
  };

  const showTable = (data, filename) => {
    const rows = prepDataForLayerDataTable(data.features);
    return (
      <>
        <EditorTable
          rowsProp={rows}
          minHeight={600}
          geoFormatted={true}
          downloadable={true}
          // layerID={data.layersquery.getlayerbyid.field}
          editable={true}
          appendable={false}
          // handleDataSetChange={handleDataSetChange}
          // l10n={l10n}
          // setParentFilters={setFilters}
        />
      </>
    );
  };

  useEffect(() => {
    // console.log("Layer change - update data table preview")
    if (layer && layer.features && layer.features.length > 0) {
      const featuresObj = layer.features.map((feature) => ({
        ...feature,
        properties: Object.assign(
          ...feature.properties.map(({ field, value }) => ({
            [field.nameEN]: value,
          })),
        ),
      }));

      // console.log(featuresObj);
      setDataForPreview({ type: 'FeatureCollection', features: featuresObj });
    }
  }, [layer]);

  // useEffect(() => {
  //   if (getOfficesByParent_data) {
  //     if (
  //       user.userLevel.accessType === 'EDIT' &&
  //       user.userLevel.priority === 3 &&
  //       getOfficesByParent_data &&
  //       getOfficesByParent_data.commonQueries &&
  //       getOfficesByParent_data.commonQueries.getOffices.length > 0
  //     ) {
  //       let host = [];
  //       getOfficesByParent_data &&
  //         getOfficesByParent_data.commonQueries &&
  //         getOfficesByParent_data.commonQueries.getOffices.map((off) => {
  //           return host.push(off);
  //         });
  //       let resp = offices && offices.find((x) => x._id === user.office);
  //       console.log('resp', resp);
  //       if (resp) {
  //         host.push(resp);
  //       }
  //       setTempOffices(host);
  //     }
  //   }
  // }, [getOfficesByParent_data, offices]);

  useEffect(() => {
    if (locations_data) {
      setLocationData(locations_data);
    }
  }, [locations_data]);

  useEffect(() => {
    if (offices_data) {
      setOfficeData(offices_data);
    }
  }, [offices_data]);

  useEffect(() => {
    if (getOfficesByParent_data && getOfficesByParent_data !== undefined) {
      setOfficesFromParent(getOfficesByParent_data);
    }
  }, [getOfficesByParent_data]);

  useEffect(() => {
    if (OfficeData) {
      setOffices(
        OfficeData.commonQueries &&
          OfficeData.commonQueries.getOffices &&
          OfficeData.commonQueries.getOffices,
      );
    }
  }, [OfficeData]);

  useEffect(() => {
    if (OfficesFromParent) {
      if (
        user.userLevel.accessType === 'EDIT' &&
        user.userLevel.priority === 3 &&
        OfficesFromParent &&
        OfficesFromParent.commonQueries &&
        OfficesFromParent.commonQueries.getOffices.length > 0
      ) {
        let host = [];
        OfficesFromParent &&
          OfficesFromParent.commonQueries &&
          OfficesFromParent.commonQueries.getOffices.map((off) => {
            return host.push(off);
          });
        let resp = offices && offices.find((x) => x._id === user.office);
        console.log('resp', resp);
        if (resp) {
          host.push(resp);
        }
        setTempOffices(host);
      }
    }
  }, [OfficesFromParent, offices]);

  const handleUpdateFieldsInTable = () => {
    // console.log("Update Preview");
    // console.log(fields);
    // let data = dataForPreview;
    // console.log(data);
  };

  const getFieldIndex = (fields, currentField) => {
    return fields.map((d) => d._id).indexOf(currentField._id);
  };

  const handleCurrentFieldShift = (shift) => () => {
    if (fields.length > 0 && currentField) {
      const currentIndex = getFieldIndex(fields, currentField);
      // console.log(currentIndex);
      if (currentIndex + shift >= 0 && currentIndex + shift < fields.length) {
        setCurrentField(fields[currentIndex + shift]);
      }
    }
  };

  // const csvMIMETypes = [
  //   "text/plain",
  //   "text/x-csv",
  //   "application/vnd.ms-excel",
  //   "application/csv",
  //   "application/x-csv",
  //   "text/csv",
  //   "text/comma-separated-values",
  //   "text/x-comma-separated-values",
  //   "text/tab-separated-values"
  // ]

  const permissableExtensions = {
    csv: ['csv'],
    geojson: ['json', 'geojson'],
  };

  const addNewDataForLayer = (data, extension, joinWithThisMapToGeos) => {
    setIsNewLayerData(true);
    // setLayerType(getGeoJSONType(data.features[0].geometry.type));
    if (fields.length > 0) {
      setFieldsToDelete(fields.map((field) => field._id));
    }

    let dataType = '';
    let parsedData = {};

    // console.log(data);

    if (joinWithThisMapToGeos) {
      dataType = joinWithThisMapToGeos.type;
      parsedData = {
        features: data.map((row) => {
          return {
            type: 'Feature',
            geometry:
              joinWithThisMapToGeos.mapToGeos[row[fieldFromNewLayerToJoinWith]],
            properties: row,
          };
        }),
      };
    } else {
      if (permissableExtensions['csv'].includes(extension)) {
        dataType = 'POINT';
        parsedData = {
          features: data.map((row) => {
            return {
              type: 'Feature',
              geometry: {
                coordinates: [
                  row[latLonCols.lonCol],
                  row[latLonCols.latCol],
                  0,
                ],
                type: 'Point',
              },
              properties: row,
            };
          }),
        };
      } else if (permissableExtensions['geojson'].includes(extension)) {
        dataType = getGeoJSONType(data.features[0].geometry.type);
        parsedData = data;
      }
    }

    let templayerdata = {
      ...layer,
      type: dataType,
      features: parsedData.features.map((feature) => ({
        type: feature.type,
        geometry: feature.geometry,
        properties: Object.keys(feature.properties).map(
          (propertyKey, index) => ({
            value: feature.properties[propertyKey],
            field: { _id: index },
          }),
        ),
      })),
    };

    console.log('PROCESSING');
    console.log(
      layer.office
        ? layer.office._id
          ? layer.office._id
          : layer.office
        : offices !== null
        ? offices.find((x) => x._id === user.office)._id
        : null,
    );
    console.log('END PROCESSING');

    if (roleLevel <= 3) {
      templayerdata.office =
        layer && layer.office
          ? layer.office._id
            ? layer.office._id
            : layer.office
          : offices !== null
          ? offices.find((x) => x._id === user.office)._id
          : null;
      // templayerdata.office = offices !== null ? offices.find((x) => x._id === user.office)._id: null;
    } else if (
      !templayerdata.office ||
      templayerdata.office === undefined ||
      templayerdata.office === null
    ) {
      templayerdata.office = layer.office
        ? layer.office._id
          ? layer.office._id
          : layer.office
        : offices !== null
        ? offices.find((x) => x._id === user.office)._id
        : null;
    }
    setLayer(templayerdata);
    setFields(
      Object.keys(parsedData.features[0].properties).map((field, index) => {
        return {
          _id: index,
          nameEN: field,
          nameMM: field,
          fieldType: 'TEXT',
        };
      }),
    );
    setGeometryPicked(true);
  };

  const handleChangeFile = async (e) => {
    let reader = new FileReader();

    setCsvFileUploaded(false);
    setGeometryPicked(false);
    setLatLonCols({ latCol: null, lonCol: null });

    if (e.target.files.length > 0) {
      // console.log(e.target.files)

      const filename = e.target.files[0].name;
      const filenameParts = e.target.files[0].name.split('.');
      const extension = filenameParts[filenameParts.length - 1];
      const filetype = e.target.files[0].type
        ? e.target.files[0].type
        : extension;

      // console.log(filename, filenameParts, extension, filetype);

      reader.onload = (event) => {
        let binary = '';
        // const bytes = new TextEncoder("utf-8").encode(new Uint8Array(event.target.result));
        const bytes = new Uint8Array(event.target.result);
        const length = bytes.byteLength;

        binary = new TextDecoder('utf-8').decode(bytes);
        let data = [];
        // if (filetype === "text/csv") {
        if (permissableExtensions['csv'].includes(extension)) {
          data = readString(binary, { header: true }).data;
          setCsvFileUploaded(true);
          setRawUploadedDataCache(data);
        } else if (permissableExtensions['geojson'].includes(extension)) {
          data = JSON.parse(binary);
          setGeometryPicked(true);

          if (data && data.features && data.features.length > 0) {
            addNewDataForLayer(data, extension);
          }
        }
      };

      reader.readAsArrayBuffer(e.target.files[0], 'UTF-8');
    }
  };

  return (
    <>
      <Grid columns="equal" centered>
        <Grid.Row>
          <LockIndicator
            layer={layer}
            id={layerID}
            userid={user.id}
            authorization={authorization}
            setAuthorization={setAuthorization}
          />
        </Grid.Row>
      </Grid>
      <Grid.Row>
        <Grid.Column className="left-rail" computer={6} widescreen={4}>
          <Accordion>
            <Accordion.Title
              active={accordionActiveIndex === 0}
              index={0}
              onClick={handleAccordionClick}
            >
              <span className="tgicon tgicon-metadata-alt"></span>
              {intl.get('editor.upload.metadata')}
              <Icon name="dropdown" />
            </Accordion.Title>
            <Accordion.Content active={accordionActiveIndex === 0}>
              <Form>
                <Grid>
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      <LayerPropertyComponent
                        propertyName="nameEN"
                        inputType="text"
                        label="editor.upload.nameEN"
                        placeholder="editor.upload.nameENPlaceholder"
                        changeFunc={handlePropertyChange('nameEN')}
                        value={layer ? layer.nameEN : null}
                        className={
                          sharedstatus === null
                            ? ''
                            : sharedstatus && sharedstatus.nameEN
                            ? ''
                            : 'highlight_input'
                        }
                      />
                      {/* <Form.Field>
                      <h4>{intl.get("editor.upload.nameEN")}</h4>
                      <Input placeholder="Input Layer Name" />
                    </Form.Field> */}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      <LayerPropertyComponent
                        propertyName="nameMM"
                        inputType="text"
                        label="editor.upload.nameMM"
                        placeholder="editor.upload.nameMMPlaceholder"
                        changeFunc={handlePropertyChange('nameMM')}
                        value={layer ? layer.nameMM : null}
                        className={
                          sharedstatus === null
                            ? ''
                            : sharedstatus && sharedstatus.nameMM
                            ? ''
                            : 'highlight_input'
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      <LayerPropertyComponent
                        propertyName="type"
                        inputType="dropdown"
                        label="editor.upload.type"
                        // placeholder='editor.upload.typePlaceholder'
                        options={['POINT', 'LINE', 'POLYGON'].map((d) => ({
                          key: d,
                          value: d,
                          text: d,
                        }))}
                        // changeFunc={handlePropertyChange('type')}
                        value={layer ? layer.type : null}
                        disabled={true}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      <LayerPropertyComponent
                        propertyName="office"
                        inputType="dropdown"
                        label="editor.upload.office"
                        // placeholder='editor.upload.locationPlaceholder'
                        options={
                          tempoffices &&
                          tempoffices !== null &&
                          tempoffices !== undefined
                            ? tempoffices !== null
                              ? tempoffices.map((d) => {
                                  return {
                                    key: d._id,
                                    value: d._id,
                                    text:
                                      languageContext.language === 'my'
                                        ? `${d.department} ( ${d.ministry}), ${
                                            d.location && d.location.nameMM
                                          }`
                                        : `${d.department} ( ${d.ministry}), ${
                                            d.location && d.location.nameEN
                                          }`,
                                  };
                                })
                              : []
                            : offices !== null
                            ? offices.map((d) => {
                                return {
                                  key: d._id,
                                  value: d._id,
                                  text:
                                    languageContext.language === 'my'
                                      ? `${d.department} ( ${d.ministry}), ${
                                          d.location && d.location.nameMM
                                        }`
                                      : `${d.department} ( ${d.ministry}), ${
                                          d.location && d.location.nameEN
                                        }`,
                                };
                              })
                            : []
                        }
                        // value={
                        //   tempoffices &&
                        //   tempoffices !== null &&
                        //   tempoffices !== undefined
                        //     ? layer.office
                        //       ? layer.office._id
                        //         ? layer.office._id
                        //         : layer.office
                        //       : tempoffices &&
                        //       tempoffices !== null &&
                        //       tempoffices !== undefined
                        //       ? tempoffices.find((x) => x._id === user.office)
                        //           ._id
                        //       : []
                        //     : layer.office
                        //     ? layer.office._id
                        //       ? layer.office._id
                        //       : layer.office
                        //     : offices !== null
                        //     ? offices.find((x) => x._id === user.office)._id
                        //     : []
                        // }
                        value={
                          offices !== null
                            ? offices.find((x) => x._id === user.office)._id
                            : ''
                        }
                        changeFunc={handlePropertyChange('office')}
                        disabled={roleLevel <= 3 ? true : false}
                        className={
                          sharedstatus === null
                            ? ''
                            : sharedstatus && sharedstatus.office
                            ? ''
                            : 'highlight_input'
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      <LayerPropertyComponent
                        propertyName="location"
                        inputType="dropdown"
                        label="editor.upload.location"
                        // placeholder='editor.upload.locationPlaceholder'
                        options={
                          LocationData
                            ? LocationData.commonQueries.getLocations.map(
                                (d) => {
                                  return {
                                    key: d._id,
                                    value: d._id,
                                    text: d.nameEN + ' (' + d.level + ')',
                                  };
                                },
                              )
                            : []
                        }
                        value={
                          layer &&
                          !!Object.entries(layer).length &&
                          layer.location
                            ? layer.location._id
                              ? layer.location._id
                              : layer.location
                            : null
                        }
                        changeFunc={handlePropertyChange('location')}
                        className={
                          sharedstatus === null
                            ? ''
                            : sharedstatus && sharedstatus.location
                            ? ''
                            : 'highlight_input'
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      <LayerPropertyComponent
                        propertyName="source"
                        inputType="text"
                        label="editor.upload.source"
                        placeholder="editor.upload.sourcePlaceholder"
                        changeFunc={handlePropertyChange('source')}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  {/* <Grid.Row>
                  <h4>{intl.get("editor.upload.date_range")}</h4>
                </Grid.Row>
                <Grid.Row columns="equal">
                  <Grid.Column textAlign="left">
                    <h4>Start Date</h4>
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      inline
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="left">
                    <h4>End Date</h4>
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      inline
                    />
                  </Grid.Column>
                </Grid.Row> */}
                </Grid>
              </Form>
            </Accordion.Content>

            <Accordion.Title
              active={accordionActiveIndex === 1}
              index={1}
              onClick={handleAccordionClick}
            >
              <span className="tgicon tgicon-edit-column"></span>
              {intl.get('editor.upload.edit_properties')}
              <Icon name="dropdown" />
            </Accordion.Title>
            <Accordion.Content active={accordionActiveIndex === 1}>
              <Form onSubmit={handleUpdateFieldsInTable}>
                <Grid>
                  <Grid.Column textAlign="left">
                    {/* {
                  fields.length > 0
                  ? fields.map(field => <LayerFieldComponent
                      field={field}
                      handleFieldChange={handleFieldChange}
                    />)
                  : null
                } */}
                    {fields.length > 0 && currentField ? (
                      <div>
                        <h4 style={{ textAlign: 'center' }}>
                          {getFieldIndex(fields, currentField) + 1} out of{' '}
                          {fields.length} fields.
                        </h4>
                        <Grid columns={3}>
                          <Grid.Row>
                            <Grid.Column width={3}>
                              <button
                                className="ui icon button"
                                onClick={handleCurrentFieldShift(-1)}
                                disabled={
                                  getFieldIndex(fields, currentField) > 0
                                    ? false
                                    : true
                                }
                              >
                                <i
                                  aria-hidden="true"
                                  className="chevron left icon"
                                ></i>
                              </button>
                            </Grid.Column>
                            <Grid.Column width={10}>
                              <Dropdown
                                label={'currentFieldPicker'}
                                name={'currentFieldPicker'}
                                options={fields.map((d) => ({
                                  key: d._id,
                                  value: d._id,
                                  text: d.nameEN,
                                }))}
                                value={currentField._id}
                                selection
                                fluid
                                onChange={(event, { value }) =>
                                  setCurrentField(
                                    fields.filter((d) => d._id === value)[0],
                                  )
                                }
                              />
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <button
                                className="ui icon button"
                                onClick={handleCurrentFieldShift(1)}
                                disabled={
                                  getFieldIndex(fields, currentField) <
                                  fields.length - 1
                                    ? false
                                    : true
                                }
                              >
                                <i
                                  aria-hidden="true"
                                  className="chevron right icon"
                                ></i>
                              </button>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <LayerFieldComponent
                          field={currentField}
                          handleFieldChange={handleFieldChange}
                        />
                        {currentField.fieldType === 'TEXT' ? (
                          <DataCleaningForColumnComponent
                            countsObj={getCountsObjForField(
                              currentField,
                              layer,
                            )}
                            valueChangeHandler={replaceValues}
                            field={currentField}
                          />
                        ) : null}
                      </div>
                    ) : (
                      'No data uploaded yet.'
                    )}
                  </Grid.Column>
                </Grid>
                {/* <Grid>
                <Grid.Column>
                  <Grid.Row>
                    {
                      fields.length > 0
                      ? <Form.Button type="submit" content='Update Preview' />
                      : null
                    }
                  </Grid.Row>
                </Grid.Column>
              </Grid> */}
              </Form>
            </Accordion.Content>
          </Accordion>
        </Grid.Column>
        <Grid.Column className="upload-main" computer={10} widescreen={12}>
          <Segment>
            {/* <CSVReader
            cssclassName="react-csv-input"
            onFileLoaded={data => false}
          /> */}
            <input
              type="file"
              id={'inputId'}
              accept={'.json,.geojson,.csv'}
              onChange={(e) => handleChangeFile(e)}
            />
          </Segment>
          {csvFileUploaded && !geometryPicked ? (
            <>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <strong>CSV file detected</strong>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    Select Latitude and Longitude columns
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}></Grid.Column>

                  <Grid.Column width={4}>
                    Latitude
                    <br />
                    <Dropdown
                      label={'latCol'}
                      name={'latCol'}
                      options={
                        rawUploadedDataCache && rawUploadedDataCache.length > 0
                          ? Object.keys(rawUploadedDataCache[0]).map((d) => ({
                              key: d,
                              value: d,
                              text: d,
                            }))
                          : []
                      }
                      selection
                      fluid
                      onChange={(event, { value }) =>
                        setLatLonCols({ ...latLonCols, latCol: value })
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    Longitude
                    <br />
                    <Dropdown
                      label={'latCol'}
                      name={'latCol'}
                      options={
                        rawUploadedDataCache && rawUploadedDataCache.length > 0
                          ? Object.keys(rawUploadedDataCache[0]).map((d) => ({
                              key: d,
                              value: d,
                              text: d,
                            }))
                          : []
                      }
                      selection
                      fluid
                      onChange={(event, { value }) =>
                        setLatLonCols({ ...latLonCols, lonCol: value })
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={4}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <button
                      className="ui icon button"
                      disabled={!latLonCols.latCol || !latLonCols.lonCol}
                      onClick={() =>
                        addNewDataForLayer(rawUploadedDataCache, 'csv')
                      }
                    >
                      <i aria-hidden="true" className="check icon"></i> Parse
                      Data
                    </button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <strong>OR</strong>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    Join with another map based layer that is already in TGIST
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    {getLocationName(layer, LocationData)}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}></Grid.Column>
                  <Grid.Column width={8}>
                    {layersFromLocation &&
                    layersFromLocation.data &&
                    layersFromLocation.data.layersQuery &&
                    layersFromLocation.data.layersQuery.getLayers &&
                    layersFromLocation.data.layersQuery.getLayers.length > 0 ? (
                      <Dropdown
                        label={'layersFromLocation'}
                        name={'layersFromLocation'}
                        options={layersFromLocation.data.layersQuery.getLayers.map(
                          (l) => ({ key: l._id, value: l, text: l.nameEN }),
                        )}
                        selection
                        fluid
                        onChange={(event, { value }) =>
                          setExistingLayerToJoinWith(value)
                        }
                      />
                    ) : (
                      'No Layers available'
                    )}
                  </Grid.Column>
                  <Grid.Column width={4}></Grid.Column>
                </Grid.Row>
                {existingLayerToJoinWith ? (
                  <Grid.Row>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={4}>
                      Column from Existing Layer
                      <br />
                      <Dropdown
                        label={'latCol'}
                        name={'latCol'}
                        options={
                          layerToJoinWithQuery &&
                          layerToJoinWithQuery.data &&
                          layerToJoinWithQuery.data.layersQuery &&
                          layerToJoinWithQuery.data.layersQuery.getLayerByID
                            ? layerToJoinWithQuery.data.layersQuery.getLayerByID.features[0].properties.map(
                                (d) => {
                                  return {
                                    key: d.field._id,
                                    value: d.field,
                                    text: d.field.nameEN,
                                  };
                                },
                              )
                            : []
                        }
                        selection
                        fluid
                        onChange={(event, { value }) =>
                          createMapJoinColValsFromOriginalGeoLayerToGeometry(
                            layerToJoinWithQuery.data.layersQuery.getLayerByID,
                            value,
                          )
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      Column from new Uploaded File
                      <br />
                      <Dropdown
                        label={'latCol'}
                        name={'latCol'}
                        options={
                          rawUploadedDataCache &&
                          rawUploadedDataCache.length > 0
                            ? Object.keys(rawUploadedDataCache[0]).map((d) => ({
                                key: d,
                                value: d,
                                text: d,
                              }))
                            : []
                        }
                        selection
                        fluid
                        onChange={(event, { value }) =>
                          setFieldFromNewLayerToJoinWith(value)
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={4}></Grid.Column>
                  </Grid.Row>
                ) : null}
                {existingLayerToJoinWith ? (
                  <Grid.Row>
                    <Grid.Column>
                      <button
                        className="ui icon button"
                        disabled={
                          !mapJoinColValsFromOriginalGeoLayerToGeometry ||
                          !fieldFromNewLayerToJoinWith
                        }
                        onClick={() =>
                          addNewDataForLayer(
                            rawUploadedDataCache,
                            null,
                            mapJoinColValsFromOriginalGeoLayerToGeometry,
                          )
                        }
                      >
                        <i aria-hidden="true" className="check icon"></i>Parse
                        Data
                      </button>
                    </Grid.Column>
                  </Grid.Row>
                ) : null}
              </Grid>
            </>
          ) : null}
          {dataForPreview ? showTable(dataForPreview) : null}
        </Grid.Column>
      </Grid.Row>
    </>
  );
}

export default Step1;
