export default [
  {
    text: 'Union',
    value: 'Union'
  },
  {
    text: 'StateRegion',
    value: 'StateRegion'
  },
  {
    text: 'District',
    value: 'District'
  },
  {
    text: 'Township',
    value: 'Township'
  },
  {
    text: 'Ward',
    value: 'Ward'
  },
  {
    text: 'VillageTrack',
    value: 'VillageTrack'
  },
]