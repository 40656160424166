import gql from 'graphql-tag';

export default gql`
query layersCustomQuery($skip: Int, $take: Int){
  userQueries{
    users(skip:$skip, take:$take){
        _id
        email
        phone
        name
        position
        rank
        otpRequired
        userLevel{
          _id
          accessType
          priority
          titleEN
          titleMM
        }
        office{
          _id
          ministry
          department
        }
        location{
          _id
          level
          nameEN
          nameMM
        }
    }
  }
}
`
