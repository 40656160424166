import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Header, Grid, Icon } from 'semantic-ui-react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import JwtDecode from 'jwt-decode';
import moment from 'moment';

// import { LocalizationContext } from '../context/localization';
import GET_SINGLE_LOCATION from '../graphql/singleLocationQuery';
import GET_SINGLE_OFFICE from '../graphql/singleOfficeQuery';
import Layout from '../components/Layout';
import { getToken } from '../utils/'
import '../styles/IndexPage.scss';
import Loader from '../components/Loader'
import intl from 'react-intl-universal';

import ViewerImage from '../images/VIEWER.svg';
import EditorImage from '../images/EDITOR.svg';


function IndexPage(props) {
  const { user } = props;
  const locationQuery = useQuery(GET_SINGLE_LOCATION, { variables: { id: user.location } });
  const officeQuery = useQuery(GET_SINGLE_OFFICE, { variables: { id: user.office } });
  const [getLazyLocation, lazylocationQuery] = useLazyQuery(GET_SINGLE_LOCATION, { variables: { id: user.location } });
  const [getLazyOffice, lazyofficeQuery] = useLazyQuery(GET_SINGLE_OFFICE, { variables: { id: user.office } });
  const token = (getToken('expressGraphQLAuth') || '');
  const iat = JwtDecode(token).iat * 1000;
  const fromNow = moment(iat).fromNow();

  const handleRetry = () => {
    getLazyLocation();
    getLazyOffice();
  }

  if (officeQuery.error || locationQuery.error) {
    return (
      <Layout>
        <Header as="h3">An Error Has Occured</Header>
        <Button onClick={handleRetry} icon primary loading={lazylocationQuery.loading || lazyofficeQuery.loading}>Retry<Icon name="redo" /></Button>
      </Layout>
    )
  }

  return (
    <Layout className="IndexPage">
      <Grid relaxed='very'>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header as="h1" className="welcomeMsg">{intl.get("index.hello", { name: (user.name ? user.name : user.email) })}</Header>

          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            {/* {
              !locationQuery.loading
                ?
                <Header as="sub" className="userInfo">
                  {locationQuery.data.commonQueries.getLocationById ? 
                    <>
                    <Icon name="map marker alternate" /> 
                    {locationQuery.data.commonQueries.getLocationById.placeCode}
                    </>
                    : ''}
                </Header>
                : <Loader isPaddingless />
            } */}
          </Grid.Column>
          <Grid.Column>
            {/* {
              !officeQuery.loading
                ? <Header as="sub" className="userInfo">
                  {officeQuery.data.commonQueries.getOfficeById ? 
                    <Icon name="building outline" />
                    `${officeQuery.data.commonQueries.getOfficeById.department}, ` 
                  : ''}
                  {officeQuery.data.commonQueries.getOfficeById ? officeQuery.data.commonQueries.getOfficeById.ministry : ''}
                </Header>
                : <Loader isPaddingless />
            } */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} stretched>
          <Grid.Column>
            <Link to="/viewer" className="introBox viewerIntro">
              <Header as="h2" className="primary-color-text">{intl.get("index.viewer.long")}</Header>
              <p className="navDesc">{intl.get("index.viewer.desc")}</p>
              <img src={ViewerImage} alt="Viewer Illustration" width="70%" />
            </Link>
          </Grid.Column>
          <Grid.Column>
            <Link to="/editor" className="introBox editorIntro">
              <Header as="h2">{intl.get("index.editor.long")}</Header>
              <p className="navDesc">{intl.get("index.editor.desc")}</p>
              <img src={EditorImage} alt="Editor Illustration" width="70%" />
            </Link>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column style={{ textAlign:'right' }}>
            {iat ? <><Icon name="clock" /> {intl.get("index.last_logined", { date: fromNow })}</> : false}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  )
}

export default IndexPage;
