import gql from 'graphql-tag';
export default gql`
  mutation mutateField($field: LayerFieldInput!){
    layerMutations{
      createUpdateLayerField(field: $field){
        result
        message
        isError
        isSuccess
      }
    }
  }
`