import gql from 'graphql-tag';

export default gql`
  mutation deleteGroup($id: IdOrIds!){
    commonMutations{
      deleteOffice(id:$id){
        result
        message
        isError
        isSuccess
      }
    }
  }
`;