import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Grid, Segment, Form, Message, Icon } from 'semantic-ui-react';
import Loader from '../Loader';
import ADD_GROUP from '../../graphql/createUpdateOffice';
import GET_OFFICES from '../../graphql/getOffices';
import { useForm, shouldLogState as SHOULD_LOG_STATE, validate } from '../../utils';
import GroupFormFields from './GroupFormFields';
import intl from 'react-intl-universal';

function NewGroup(props) {
  const { cancelNewGroup } = props;

  const initialState = { level: '', ministry: '', department: '', location: '' };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [addGroup, { loading }] = useMutation(ADD_GROUP, {
    onCompleted: () => {
      setIsSubmitting(false);
      overrideValues(initialState);
      cancelNewGroup();
    },
    errorPolicy: 'all',
    onError: (err) => {
      setErrors({ network: ['There was an error with your submission', ...err.graphQLErrors, err.networkError.response.statusText] })
    }
  })

  const { onChange, onSubmit, overrideValues, values } = useForm((newOffice) => {
    setIsSubmitting(true);
    const officeInput = { ...newOffice }
    const validation = validate(officeInput);
    if (validation.isValid) {
      setErrors({});
      addGroup({
        variables: { officeInput },
        refetchQueries: [{ query: GET_OFFICES }]
      });
      setIsSubmitting(false);
    }
    else {
      setErrors(validation.errors);
      setIsSubmitting(false);
    }

  }, initialState, SHOULD_LOG_STATE)

  return (
    isSubmitting
      ?
      <Loader />
      :
      <>
        <Segment>
          <Form error={!!errors} onSubmit={onSubmit} noValidate loading={loading}>
            <Grid centered columns="equal">
              <GroupFormFields values={values} errors={errors} onChange={onChange} />
              <Grid.Row>
                <Grid.Column textAlign="right">
                  <Form.Field>
                    <Button type="submit" onClick={onSubmit} primary disabled={isSubmitting}>
                      <Icon name="checkmark"/> {intl.get("admin.add_group")}
                    </Button>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>

              {Object.keys(errors).length === 0 && errors.constructor === Object ? false :
                <Grid.Row>
                  <Grid.Column textAlign="left">
                    <Message error icon="warning sign" content={<ul>
                      {Object.keys(errors).map(key => errors[key].map((error, index) => <li key={index}>{error}</li>))}
                    </ul>} />
                  </Grid.Column>
                </Grid.Row>
              }
            </Grid>
          </Form>
        </Segment>
      </>
  )
};

export default NewGroup;