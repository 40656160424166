import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Grid, Form, Dropdown, Checkbox, Label, Input, Icon, Button } from 'semantic-ui-react';
import GET_LOCATIONS from '../../graphql/getLocations';
import GET_OFFICES from '../../graphql/getOffices';
import GET_USER_LEVELS from '../../graphql/getUserLevels';
import Loader from '../Loader';
import intl from 'react-intl-universal';

function UserFormFields(props) {

	const { values, errors, onChange, actionType } = props;

	const userLevelsQuery = useQuery(GET_USER_LEVELS);
	const locationsQuery = useQuery(GET_LOCATIONS);
	const officesQuery = useQuery(GET_OFFICES);

	const [offices, setOffices] = useState([]);
	const [locations, setLocations] = useState([]);
	const [userLevels, setUserLevels] = useState([]);

	const [isEditingPassword, setEditingPassword] = useState(false);

	const handleDropdownChange = (name, value) => {
	    return onChange({ target: { name, value, focus: () => false }, preventDefault: () => false })
	  }

	const onHandleCheckboxChange = (name, value) => {
		return onChange({ target: { name, value: value.checked, focus: () => false }, preventDefault: () => false })
	}

	useEffect(() => {
	  if (userLevelsQuery.data
	    && userLevelsQuery.data.userQueries
	    && userLevelsQuery.data.userQueries.getUserLevels
	  ) {
	    const { getUserLevels } = userLevelsQuery.data.userQueries;
	    console.log(getUserLevels);
	    const userLevels = getUserLevels.map(user => ({ key: user._id, value: user._id, text: user.titleMM }));
	    console.log(userLevels);
	    setUserLevels(userLevels);
	  }
	}, [userLevelsQuery.data]);

	useEffect(() => {
	  if (officesQuery.data
	    && officesQuery.data.commonQueries
	    && officesQuery.data.commonQueries.getOffices) {
	    const { getOffices } = officesQuery.data.commonQueries;
	    const offices = getOffices.map(office => ({ value: office._id, text: `${office.department}, ${office.ministry}` }));
	    setOffices(offices);
	  }
	}, [officesQuery.data]);

	useEffect(() => {
	  if (locationsQuery.data
	    && locationsQuery.data.commonQueries
	    && locationsQuery.data.commonQueries.getLocations) {
	    const { getLocations } = locationsQuery.data.commonQueries;
	    const locations = getLocations.map(location => ({ value: location._id, text: `${location.nameMM} ${location.level}` }));
	    setLocations(locations)
	  }
	}, [locationsQuery.data]);

	return (
		locationsQuery.loading || officesQuery.loading || userLevelsQuery.loading
		?
		<Loader />
		:
		<>
			<Grid.Row>
			  <Grid.Column textAlign="left">
			    <Form.Input label={intl.get("admin.users_fields.name")} name="name" value={values.name} onChange={onChange} placeholder="Name" error={errors.name} required />
			  </Grid.Column>
			  <Grid.Column textAlign="left">
			    <Form.Input label={intl.get("admin.users_fields.email")} name="email" value={values.email} onChange={onChange} placeholder="Email" error={errors.email} required />
			  </Grid.Column>
			</Grid.Row>
			<Grid.Row>
			  <Grid.Column textAlign="left">
			    <Form.Field required>
			      <label>{intl.get("admin.users_fields.user_level")}</label>
			      <Dropdown
			        label={intl.get("admin.users_fields.user_level")}
			        name="userLevel"
			        options={userLevels}
			        placeholder={intl.get("admin.users_fields.choose_user_level")}
			        selection
			        fluid
			        value={values.userLevel}
			        error={errors.userLevel}
			        onChange={(event, { value }) => handleDropdownChange('userLevel', value)} />
			      {errors.userLevel ? <Label basic color='red' pointing>{errors.userLevel[0]}</Label> : false}
			    </Form.Field>
			  </Grid.Column>
			  <Grid.Column>
			  	{
			  		actionType === 'edit'
			  		?
			  		isEditingPassword
			  		  ?
			  		  <div className="field">
			  		    <label>&nbsp;</label>
			  		    <div className="password-container">
			  		      <Input autoComplete="off" name="password" type="password" value={values.password || ''} onChange={onChange} placeholder="Enter to edit password" error={errors.password} />
			  		      <Icon name="remove circle" size="large" onClick={() => setEditingPassword(false)} color="red" />
			  		    </div>
			  		  </div>
			  		  :
			  		  <div className="field">
			  		    <label>&nbsp;</label>
			  		    <Button onClick={() => setEditingPassword(true)} primary fluid>{intl.get("admin.users_fields.edit_password")} </Button>
			  		  </div>
			  		 :
			  		 <Form.Input label={intl.get("admin.users_fields.password")} name="password" value={values.password} onChange={onChange} placeholder="Password" error={errors.password} required />
			  	}
			    
			  </Grid.Column>
			</Grid.Row>
			<Grid.Row>
			  <Grid.Column textAlign="left">
			    <Form.Input label={intl.get("admin.users_fields.position")} name="position" value={values.position} onChange={onChange} placeholder="Position" error={errors.position} required />
			  </Grid.Column>
			  <Grid.Column textAlign="left">
			    <Form.Input label={intl.get("admin.users_fields.rank")} name="rank" value={values.rank} onChange={onChange} placeholder="Rank" error={errors.rank} required />
			  </Grid.Column>
			</Grid.Row>
			<Grid.Row>
			  <Grid.Column textAlign="left">
			    <Form.Input label={intl.get("admin.users_fields.phone")} name="phone" value={values.phone} onChange={onChange} placeholder="95 123456" fluid error={errors.phone} required />
			    {/* {errors.phone ? <Label basic color='red' pointing>{errors.phone[0]}</Label>:false} */}
			  </Grid.Column>
			  <Grid.Column textAlign="left">
			    <Form.Field required>
			      <label>{intl.get("admin.users_fields.location")}</label>
			      <Dropdown
			        label={intl.get("admin.users_fields.location")}
			        name="location"
			        options={locations}
			        placeholder="Location"
			        selection
			        onChange={(event, { value }) => handleDropdownChange('location', value)}
			        value={values.location || ''} fluid />
			      {errors.location ? <Label basic color='red' pointing>{errors.location[0]}</Label> : false}
			    </Form.Field>
			  </Grid.Column>
			</Grid.Row>
			<Grid.Row>
			  <Grid.Column textAlign="left">
			    <Form.Field required>
			      <label>{intl.get("admin.users_fields.office")}</label>
			      <Dropdown
			        label={intl.get("admin.users_fields.office")}
			        name="office"
			        options={offices}
			        placeholder="Office"
			        selection
			        onChange={(event, { value }) => handleDropdownChange('office', value)}
			        value={values.office || ''} fluid />
			      {errors.office ? <Label basic color='red' pointing>{errors.office[0]}</Label> : false}
			    </Form.Field>
			  </Grid.Column>
			</Grid.Row>
			<Grid.Row>
			  <Grid.Column textAlign="left" verticalAlign="middle">
			    <Form.Field>
			      <label>{intl.get("admin.users_fields.otp_required")}</label>
			      <Checkbox toggle name="otpRequired" checked={values.otpRequired} onChange={(event, value) => onHandleCheckboxChange('otpRequired', value)} />
			    </Form.Field>
			  </Grid.Column>
			</Grid.Row>
		</>
	)
}

export default UserFormFields;