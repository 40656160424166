import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Step, Icon } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import breadcrumbSteps from './BreadcrumbSteps';

function StepBreadCrumbs({
  step,
  updateLayer,
  layerID,
  layer,
  setSharedStatus,
}) {
  let history = useHistory();
  const currentStep = step;
  // console.log(layerID);

  const handleClick = (step) => {
    updateLayer();
    history.push(`/editor/${step.stepIndex}` + (layerID ? `/` + layerID : ``));
  };

  const doNothing = () => {
    setSharedStatus({
      nameEN: layer.nameEN !== undefined,
      nameMM: layer.nameMM !== undefined,
      office: layer.office !== undefined,
      location: layer.location !== undefined,
    });
  };

  return (
    <Step.Group>
      {breadcrumbSteps.map((step) => (
        <Step
          key={`step_${step.stepIndex}`}
          active={step.stepIndex === currentStep}
          onClick={
            ((layer && layer.nameEN !== undefined && layer.nameEN !== null) || (layer && layer.nameMM !== undefined && layer.nameMM !== null)) &&
            layer.office !== undefined &&
            layer.location !== undefined
              ? () => handleClick(step)
              : () => doNothing()
          }
        >
          <span className={'tgicon ' + step.icon + ' big-icon'}></span>
          <Step.Content>
            <Step.Title>
              <Link
                to={
                  ((layer && layer.nameEN !== undefined && layer.nameEN !== null) || (layer && layer.nameMM !== undefined && layer.nameMM !== null)) &&
                  layer.office !== undefined &&
                  layer.location !== undefined
                    ? `/editor/${step.stepIndex}` +
                      (layerID ? `/` + layerID : ``)
                    : '#'
                }
                onClick={
                  ((layer && layer.nameEN !== undefined && layer.nameEN !== null) || (layer && layer.nameMM !== undefined && layer.nameMM !== null)) &&
                  layer.office !== undefined &&
                  layer.location !== undefined
                    ? updateLayer
                    : () => doNothing()
                }
              >
                {intl.get(step.title)}
              </Link>
            </Step.Title>
          </Step.Content>
        </Step>
      ))}
    </Step.Group>
  );
}

export default StepBreadCrumbs;
