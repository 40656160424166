import {scaleOrdinal} from 'd3-scale';

const colorCategories20 = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231',
 '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', 
 '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080'];

const severityScale = scaleOrdinal()
  .domain(["Catastrophic (fatal)", "Critical (hospitalized)", "Marginal (injured)", "Negligible crash","No Data"])
  .range(["#E76F51","#F4A261","#E9C46A","#2A9D8F","#CCCCCC"]);

const severityScaleHpaan = scaleOrdinal()
  .domain(["Catastrophic (fatal)", "Critical (hospitalized)", "Marginal Injured", "Negligible crash","No Data"])
  .range(["#E76F51","#F4A261","#E9C46A","#2A9D8F","#CCCCCC"]);
  
const severityScaleStandard = scaleOrdinal()
  .domain(["Catastrophic (fatal)", "Critical (hospitalized)", "Marginal (injured)", "Negligible crash","No Data","Nan"])
  .range(["#E76F51","#F4A261","#E9C46A","#2A9D8F","#CCCCCC","#CCCCCC"]);

const garbageScale = scaleOrdinal()
  .range(["#69B578","#04724D","#181D27"]);

const roadScale = scaleOrdinal()
  .domain(["Concrete", "asphalt","Asphalt", "Earth","None"])
  .range(["#3a332d","#516c8c","#516c8c","#a8532b","#777777"]);

const landUseScale = scaleOrdinal()
  .domain(["Government Office","Commercial","Industry","Residential",
    "Water Body","Religious","Green Area","Playground","Health",
    "Military","Education","Identify","Water Body","Transporation",
    "Agricultural","Park","Cemetary"])
  .range(["#ed1c24","#ebd416","#f48fa8","#959799",
    "#006ab6","#ffd48d","#a6bd38","#d6e491","#fbc7b2",
    "#d689ba","#b9c4e4","#fff68f","#006ab6","#6a7c62",
    "#bc746d","#bc746d","#53ae82"])

const genericScale = scaleOrdinal().range(colorCategories20);

const allScales = {
  'severity': severityScale,
  'severityHpaan': severityScaleHpaan,
  'severityStandard': severityScaleStandard,
  'garbage': garbageScale,
  'generic': genericScale,
  'road': roadScale,
  'landUse': landUseScale
}

export default allScales;