import gql from 'graphql-tag';
export default gql`
  mutation mutateLayer($layer: LayerInput!, $fields: [LayerFieldInput!]){
    layerMutations{
      createUpdateLayer(layer: $layer, fields: $fields){
        result
        message
        isError
        isSuccess
      }
    }
  }
`