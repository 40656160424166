import gql from 'graphql-tag';

export default gql`
query locationsTotalQuery{
    commonQueries{
        getLocationsTotal{
          count
        }
    }
}
`