import React, { useState, useEffect, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Accordion, Grid, Form, Icon, Header, List, Button, Dropdown } from 'semantic-ui-react'
import CREATE_UPDATE_USER_ACCESS_LAYERS from '../../graphql/createUpdateUserAccessesLayers'
import GET_LAYERS_ACCESS_BYID from '../../graphql/getLayerAccessesById'
import { Link, useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import moment from 'moment';
import { AuthContext } from '../../context/auth'
import { SocketContext } from '../../context/socketProvider'
import { LanguageContext } from '../../context/langProvider'
import Loader from '../Loader';
import LockIndicator from '../LockIndicator';
import { getRole } from '../../utils'

import CongratImage from '../../images/CONGRATS.svg';

function Step4(props) {
  const { officeData, userData, accessData, layerID, layer, user } = props
  let departmentList = []
  let userList = []
  let layer_id = layerID && layerID
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedDepartments, setSelectedDepartments] = useState([])
  const [selectedDepartmentNames, setSelectedDepartmentNames] = useState([])
  const [selectedUserNames, setSelectedUserNames] = useState([])
  const [accordionActiveIndex, setAccordionActiveIndex] = useState(0);
  const [layeraccess, setLayeraccess] = useState()
  const [hostusers, setHostusers] = useState([])
  const [hostdepartments, setHostdepartments] = useState([])
  const [idHost, setIdhost] = useState([])
  const [unlock, setUnlock] = useState(false)
  const [authorization, setAuthorization] = useState(false)
  const [roleLevel, setRoleLevel] = useState(0)
  const authContext = useContext(AuthContext)
  const socketContext = useContext(SocketContext)
  const languageContext = useContext(LanguageContext)
  const { socket } = socketContext
  let history = useHistory();

  const { loading: layerloading, data: layerdata, error: layererror } = useQuery(GET_LAYERS_ACCESS_BYID, {
    variables: {
      where: {
        "layer": layer_id
      }
    },
    skip: (layer_id === "" || layer_id === null || layer_id === undefined)
  })
  const [createAccess, { loading, data, error }] = useMutation(CREATE_UPDATE_USER_ACCESS_LAYERS);

  useEffect(() => {
    if (layerdata) {
      setLayeraccess(layerdata.commonQueries && layerdata.commonQueries.getLayersAccesses)
    }
  }, [layerdata])

  useEffect(() => {
    if (user) {
      let res = getRole(user.userLevel.priority, user.userLevel.accessType)
      setRoleLevel(parseInt(res))
    }
  }, [user])

  useEffect(() => {
    layeraccess && layeraccess.map(lay => {
      return lay.user === null ? null : (setHostusers(res => [...res, lay.user._id]), setIdhost(res => [...res, { key: lay._id, userid: lay.user._id, officeid: null }])),
        lay.office === null ? null : (setHostdepartments(res => [...res, lay.office._id]), setIdhost(res => [...res, { key: lay._id, userid: null, officeid: lay.office._id }]))
    })
  }, [layeraccess])


  useEffect(() => {
    let accesseddepartments = [...new Set(hostdepartments && hostdepartments)];
    let accessedusers = [...new Set(hostusers && hostusers)]
    setSelectedUsers(accessedusers)
    setSelectedDepartments(accesseddepartments)
  }, [hostusers, hostdepartments, layeraccess])

  useEffect(() => {
    const { OPEN, readyState } = socket;
    if (readyState === OPEN && unlock && layer.lockedBy && layer.lockedBy._id === user.id) {
      let message = {
        type: 'unlock-data',
        message: {
          model: 'layer',
          id: props.layerID,
        }
      }
      socket.send(JSON.stringify(message))
      setUnlock(false)
    }

  }, [unlock])

  useEffect(() => {
    let departmentnames = [];

    selectedDepartments.filter(function (sdval) {
      return departmentList && departmentList.filter(function (dl) {
        if (sdval === dl.key) {
          departmentnames.push({ key: dl.key, text: dl.text, value: dl.value })
        }
      })
    });
    setSelectedDepartmentNames(departmentnames)
  }, [selectedDepartments])


  useEffect(() => {
    let usernames = [];
    selectedUsers.filter(function (suval) {
      return userList && userList.filter(function (ul) {
        if (suval === ul.key) {
          usernames.push({ key: ul.key, text: ul.text, value: ul.value })
        }
      })
    });
    setSelectedUserNames(usernames)
  }, [selectedUsers])

  useEffect(() => {
    console.log(`socket.readyState : ${socket.readyState}`)
    console.log(`socket.OPEN : ${socket.OPEN}`)
    if (socket == null || socket === undefined) {
      socketContext.externalRefresh()
    }

    if (socket && socket.readyState !== socket.OPEN) {
      socketContext.externalRefresh()
    }

    if (socket && socket.readyState !== 1) {
      socketContext.externalRefresh()
    }

    if (socket && socket.OPEN !== 1) {
      socketContext.externalRefresh()
    }
  }, [socket])

  if (socket.readyState !== socket.OPEN) {
    socketContext.externalRefresh()
  }

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = accordionActiveIndex === index ? -1 : index;
    setAccordionActiveIndex(newIndex);
  }
  officeData && officeData.getOffices && officeData.getOffices.map(office => {
    console.log(office)
    let formattedoffice = { key: office._id, text: office.department, value: office._id }
    return departmentList.push(formattedoffice)
  })
  userData && userData.users && userData.users.map(user => {
    let formatteduser = { key: user._id, text: user.name, value: user._id }
    return userList.push(formatteduser)
  })

  const getUsers = async (e, { value }) => {
    let temp = [...value]
    setSelectedUsers([...new Set(temp)])
  }

  const getDepartments = async (e, { value }) => {
    let temp = [...value]
    setSelectedDepartments([...new Set(temp)])
  }
  const handleSharedButtonClick = async (e) => {
    e.preventDefault();
    const input = {
      "users": selectedUsers,
      "offices": selectedDepartments,
      "layerId": layer_id,
      "accessType": "VIEW"
    }
    try {
      console.log("createUpdateLayerInput")
      console.log({ variables: { input } })
      await createAccess({ variables: { input } })
      setAccordionActiveIndex(2);
    } catch (err) {
    }
  }
  const handleApproveButton = (e) => {
    setAccordionActiveIndex(1);

  }
  const handleSkipSharedButton = (e) => {
    setAccordionActiveIndex(2);
  }

  const handleDone = async () => {
    await setUnlock(true);
    history.push('/editor');
  }

  return (
    <>
      <Grid columns="equal" centered>
        <Grid.Row>
          <LockIndicator layer={layer} id={layerID} userid={user.id} authorization={authorization} setAuthorization={setAuthorization} />
        </Grid.Row>
      </Grid>
      <Grid.Row>
        <Grid.Column textAlign="left" width={16}>
          <Form>
            <Accordion fluid>
              <Accordion.Title active={accordionActiveIndex === 0} index={0} onClick={handleAccordionClick}>
                <span className="tgicon tgicon-review"></span>
                {intl.get("editor.publish.review_approve")}
                <Icon name='dropdown' />
              </Accordion.Title>
              <Accordion.Content active={accordionActiveIndex === 0}>
                <Grid centered columns={2}>
                  <Grid.Column>
                    {
                      layer._id ?
                        <>
                          <Header size='medium'>{languageContext.language === 'my' ? layer.nameMM : layer.nameEN}</Header>
                          <p>{intl.get("editor.publish.uploaded_by")} : <strong>{layer.uploadedBy.email}</strong></p>
                          <p>{intl.get("editor.publish.last_edited", { date: moment(layer.updatedAt).format('LLL') })}</p>
                          <Header size='tiny'>{intl.get("editor.publish.data_columns")}</Header>
                          <List bulleted>
                            {layer.features.length != 0 ?
                              layer.features[0].properties.map((property, key) =>
                                <List.Item key={key}>{languageContext.language === 'my' ? property.field.nameMM : property.field.nameEN}</List.Item>
                              )
                              : ''}
                          </List>
                          <p>{intl.get("editor.publish.number_of_columns")} : <strong>{layer.features[0].properties.length} columns</strong></p>
                          <p>{intl.get("editor.publish.number_of_rows")} : <strong>{layer.features.length} rows</strong></p>
                          <Header size='tiny'>{intl.get("editor.publish.checklist")}</Header>
                          <List>
                            {
                              layer && layer.features && !!layer.features.length ? (
                                <List.Item>
                                  <List.Content>
                                    <span className="tgicon tgicon-checkmark"></span>
                          Upload File
                        </List.Content>
                                </List.Item>
                              ) : null
                            }
                            <List.Item>
                              <List.Content>
                                <span className="tgicon tgicon-checkmark"></span>
                          Fill Metadata
                        </List.Content>
                            </List.Item>
                            {
                              layer && layer.tableVars && !!layer.tableVars.length ? (
                                <List.Item>
                                  <List.Content>
                                    <span className="tgicon tgicon-checkmark"></span>
                          Table Variables
                        </List.Content>
                                </List.Item>
                              ) : null
                            }
                            {
                              layer && layer.tooltipVars && !!layer.tooltipVars.length ? (
                                <List.Item>
                                  <List.Content>
                                    <span className="tgicon tgicon-checkmark"></span>
                          Tooltips
                        </List.Content>
                                </List.Item>
                              ) : null
                            }
                            {
                              layer && layer.chartVars && !!layer.chartVars.length ? (
                                <List.Item>
                                  <List.Content>
                                    <span className="tgicon tgicon-checkmark"></span>
                          Filtercharts
                        </List.Content>
                                </List.Item>
                              ) : null
                            }
                            <List.Item>
                              <List.Content>
                                <span className="tgicon tgicon-task"></span>
                          Map Styling
                        </List.Content>
                            </List.Item>
                          </List>
                        </>
                        : <Loader />
                    }
                    <p>{intl.get("editor.publish.approve_notice")}</p>
                    <Grid columns={3}>
                      <Grid.Column>
                        <Button secondary disabled>
                          <span className="tgicon tgicon-viewer"></span>
                          {intl.get("editor.publish.preview_map")}
                        </Button>
                      </Grid.Column>
                      <Grid.Column>
                        <Link to={`/editor/upload/${layer_id}`}>
                          <Button secondary>
                            <span className="tgicon tgicon-editor"></span>
                            {intl.get("editor.publish.edit")}
                          </Button>
                        </Link>
                      </Grid.Column>
                      <Grid.Column>
                        <Button primary onClick={handleApproveButton}>
                          <span className="tgicon tgicon-checkmark"></span>
                          {intl.get("editor.publish.approve")}
                        </Button>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Accordion.Content>
              <>
                {
                  roleLevel <= 2 ? (
                    <>
                      <Accordion.Title active={accordionActiveIndex === 1} index={1} onClick={handleAccordionClick}>
                        <span className="tgicon tgicon-share"></span>
                        {intl.get("editor.publish.share_data")}
                        <Icon name='dropdown' />
                      </Accordion.Title>
                      <Accordion.Content active={accordionActiveIndex === 1}>
                        <h3>{intl.get("editor.publish.publish_level_warning")}</h3>
                      </Accordion.Content>
                    </>
                  ) : (
                      <>
                        <Accordion.Title active={accordionActiveIndex === 1} index={1} onClick={handleAccordionClick}>
                          <span className="tgicon tgicon-share"></span>
                          {intl.get("editor.publish.share_data")}
                          <Icon name='dropdown' />
                        </Accordion.Title>
                        <Accordion.Content active={accordionActiveIndex === 1}>
                          <Form.Field>
                            <label>{intl.get("editor.publish.share_departments")}</label>
                            <Dropdown
                              placeholder='Departments'
                              value={selectedDepartments}
                              fluid
                              multiple
                              search
                              selection
                              options={departmentList}
                              onChange={getDepartments}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>{intl.get("editor.publish.share_individual")}</label>
                            <Dropdown
                              placeholder='Individual user'
                              value={selectedUsers}
                              fluid
                              multiple
                              search
                              selection
                              options={userList}
                              onChange={getUsers}
                            />
                          </Form.Field>
                          <Grid centered columns={5}>
                            <Grid.Column>
                              <Button secondary onClick={handleSkipSharedButton}>{intl.get("editor.publish.skip_sharing")}</Button>
                            </Grid.Column>
                            <Grid.Column>
                              <Button primary onClick={handleSharedButtonClick} disabled={selectedDepartments.length === 0 && selectedUsers.length === 0 ? true : false}>{intl.get("editor.publish.finish_sharing")}</Button>
                            </Grid.Column>
                          </Grid>
                        </Accordion.Content>
                      </>
                    )
                }
              </>
              <Accordion.Title active={accordionActiveIndex === 2} index={2} onClick={handleAccordionClick}>
                <span className="tgicon tgicon-publish"></span>
                {intl.get("editor.publish.publish_data")}
                <Icon name='dropdown' />
              </Accordion.Title>
              <Accordion.Content active={accordionActiveIndex === 2} style={{ background: '#b5cde1' }}>
                <Grid centered columns={2}>
                  <Grid.Column>
                    <Header size='huge'>{intl.get("editor.publish.congrat_message")}</Header>
                    <div className="ImgWrapper" align="center">
                      <img src={CongratImage} alt="Congrats Illustration" width="400px" />
                    </div>
                    <Grid centered columns={2}>
                      <Grid.Column>
                        <Header size='small'>{intl.get("editor.publish.sharing_departments")}</Header>
                        <List bulleted>
                          {/* Get Layer Access */}
                          {
                            selectedDepartmentNames && selectedDepartmentNames.map((ditem, key) => {
                              return <List.Item key={key}>{ditem.text}</List.Item>
                            })
                          }
                        </List>
                      </Grid.Column>
                      <Grid.Column>
                        <Header size='small'>{intl.get("editor.publish.sharing_individuals")}</Header>
                        <List bulleted>
                          {
                            selectedUserNames && selectedUserNames.map((uitem, key) => {
                              return <List.Item key={key}>{uitem.text}</List.Item>
                            })
                          }
                        </List>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <Button primary onClick={handleDone}><span className="tgicon tgicon-checkmark"></span> Done</Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Accordion.Content>
            </Accordion>
          </Form>
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default Step4;
