import gql from 'graphql-tag';

export default gql`
query getLayerById($id:String!){
    layersQuery{
      getLayerByID(id:$id){
        isLocked
        lockedBy {
          _id
          email
          name
        }
      }
    }
  }
`
