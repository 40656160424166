import React, { useState, useEffect, useCallback, useRef }  from 'react';
import { Button } from 'semantic-ui-react'
import { sentenceCase, getPropertiesArrayFromKeyVals } from '../utils'
import style from '../styles/EditorTable.scss';
import { Toolbar, Data, Filters } from 'react-data-grid-addons';
import ReactDataGrid from 'react-data-grid';
import { CSVLink } from 'react-csv';


export default function EditorTable(props) {
  const { rowsProp, minHeight, actions, geoFormatted, downloadable, otherButtons, 
  layerId, editable, appendable, handleDataSetChange, columnsToHide, l10n,
  setParentFilters } = props;
  const [rows, setRows] = useState([]);
  // const [editedRows, setEditedRows] = useState(rows);
  const [filters, setFilters] = useState({});
  const selectors = Data.Selectors;
  const { MultiSelectFilter } = Filters;
  const filteredRows = getRows(rows, filters);

  // console.log("datatable rows...",rows);
  // console.log("datatable filters...",filters);
  // console.log("datatable geoFormatted...",geoFormatted);
  // console.log(otherButtons)

  const grid = useRef(null);


  // Handle data loading

  useEffect(() => {
    if (actions) {
      Object.keys(actions).forEach(action => {
        if (actions[action].columnPrep) setRows(actions[action].columnPrep(rowsProp));
      })
    } else
      setRows(rowsProp);
  },[rowsProp,setRows]);

  // Handle sort rows

  const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
  };

  // Handle parse cells

  const parseCell = columnHeaderStr => ({ value }) => {

    // Render boolean as string
    value = typeof value === "boolean" ? (value ? "True" : "False") : value;

    // Render Geometry columns with special cell formatting
    value = geoFormatted && ["Geometry","Vertices","Latitude","Longitude"].includes(columnHeaderStr) 
      ? <div title={value} className={"geoColumn"}>{value}</div>
      : value;

    return value;
  }

  // Handle cell actions

  const getCellActions = (column, row) => {
    let cellActions = {}
    Object.keys(actions).forEach(action => cellActions[action] = actions[action].actionFunc(row) )
    return cellActions[column.key];
  }

  // Handle filtering 

  const handleFilterChange = filter => filters => {
    const newFilters = { ...filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    if (setParentFilters) setParentFilters(newFilters);
    return newFilters;
  };
  
  function getValidFilterValues(rows, columnId) {
    return rows
      .map(r => r[columnId])
      .filter((item, i, a) => {
        return i === a.indexOf(item);
      });
  }
  
  // Handle row rendering

  function getRows(rows, filters) {
    return selectors.getRows({ rows, filters });
  }


  // Handle column rendering

  const getColumns = (firstRow) => {
    const columnHeaders = Object.keys(firstRow)
      .filter(columnHeader => {
        if (!columnsToHide) 
          return true;
        else {
          return columnsToHide.includes(columnHeader) ? false : true;
        }
      })
      .map(columnHeader => {
        const columnHeaderStr = columnHeader.toString();
        const columnDataType = typeof(firstRow[columnHeader]);
        return {
          key:columnHeaderStr, 
          name: sentenceCase(columnHeaderStr), 
          formatter: parseCell(columnHeaderStr), 
          filterRenderer: columnDataType === "string" ? MultiSelectFilter : null,
          filterable: columnDataType === "string" ? true : false,
          resizable:true, 
          sortable:true,
          editable: ["Geometry","Vertices"].includes(columnHeaderStr) ? false : editable
        }
      })
    return columnHeaders
  }


  // Edit cells

  const onGridRowsUpdated = useCallback(({ fromRow, toRow, updated }) => {
    console.log(fromRow, toRow, updated);
    setRows(rows => {
      console.log(rows);
      const withEdited = [...rows];
      for (let i = fromRow; i <= toRow; i++) {
        withEdited[i] = { ...withEdited[i], ...updated };
      }
      if (handleDataSetChange) handleDataSetChange(withEdited);
      return withEdited;
    });
  }, []);

  // Scroll to row 

  const scrollToRow = (idx) => {
    var top = grid.current.getRowOffsetHeight() * idx;
    var gridCanvas = grid.current.getDataGridDOMNode().querySelector('.react-grid-Canvas');
    gridCanvas.scrollTop = top;
  }

  // Add new row

  const addNewRow = () => {
    console.log("add row...")
    const newRow = {}
    Object.keys(rows[0]).forEach(k=>newRow[k]="");
    const newRows = [...rows, newRow];
    setRows(rows => (newRows));
    scrollToRow(newRows.length);
    if (handleDataSetChange) handleDataSetChange(newRows);
  };



  // Render 

  return (
    rows.length ? <ReactDataGrid
      ref={grid}
      columns={getColumns(rows[0])}
      rowGetter={i => filteredRows[i]}
      rowsCount={filteredRows.length}
      onGridSort={(sortColumn, sortDirection) => {
          setRows(sortRows(rows, sortColumn, sortDirection));
        }
      }
      getCellActions={actions ? getCellActions : null}
      minHeight={minHeight}
      toolbar={<Toolbar enableFilter={true}>
          {downloadable 
            ? <Button className='btn' secondary>
                <CSVLink data={filteredRows} filename={"table_data.csv"}>{"Download as CSV"}</CSVLink>
              </Button> 
            : null}
          {appendable
            ? <Button className='btn' onClick={addNewRow} secondary>
                {"Add Row"}
              </Button>
            :null}
          {otherButtons
            ? otherButtons.map((b,i) => <span key={i}>{b}</span>)
            : null}
          <span>{"Rows Count:"} {filteredRows.length}</span>
        </Toolbar>}
      onAddFilter={filter => setFilters(handleFilterChange(filter))}
      onClearFilters={() => setFilters({})}
      getValidFilterValues={columnKey => getValidFilterValues(rows, columnKey)}
      enableCellSelect={editable ? true : false}
      onGridRowsUpdated={editable ? onGridRowsUpdated : null}
    />
    : null
  )
}