import gql from 'graphql-tag';

export default gql`
  mutation deleteFields($id: IdOrIds!){
    layerMutations{
      deleteLayerField(id:$id){
        result
        message
        isError
        isSuccess
      }
    }
  }
`;