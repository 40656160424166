import React, { useState, useEffect } from 'react';
import { Accordion, Button, Grid, Input, Form, Select, Icon, Checkbox, Dropdown, Radio, Header, Segment } from 'semantic-ui-react';
import { SwatchesPicker } from 'react-color';
import intl from 'react-intl-universal';
import {
  defaultMapStylings,
  iconList,
  radiiList
} from '../../utils';

import BinsSelectorComponent from './BinsSelectorComponent';
import LockIndicator from '../LockIndicator';
// import layersCustomQuery from '../../graphql/layersCustomQuery';

function Step2(props) {

  // console.log(props);
  // console.log(props.layerID)

  const { layer, setLayer, fields, layerID, renderMap, user } = props;

  const [accordionActiveIndex, setAccordionActiveIndex] = useState(0);
  const [colorFixed, setColorFixed] = useState(true);
  const [symbolFixed, setSymbolFixed] = useState(true);
  const [radiusFixed, setRadiusFixed] = useState(true);
  const [symbolColumn, setSymbolColumn] = useState();
  const [colorColumn, setColorColumn] = useState();
  const [radiusColumn, setRadiusColumn] = useState();
  const [displayFixedColorPicker, setDisplayFixedColorPicker] = useState(false);
  const [newTooltipToAdd, setNewTooltipToAdd] = useState();
  const [newChartVarToAdd, setNewChartVarToAdd] = useState();
  const [authorization, setAuthorization] = useState(false)
  // console.log("symbolColumn",symbolColumn,"radiusColumn",radiusColumn,"colorColumn",colorColumn);
  // console.log(layer);

  useEffect(() => {

    const stylingColTypes = layer.mapStyling && layer.mapStyling.stylingColumns && layer.mapStyling.stylingColumns.length > 0
      ? layer.mapStyling.stylingColumns.map(col => col.type)
      : [];

    const stylingColIndices = {
      'COLOR': -1, 'SYMBOL': -1, 'RADIUS': -1
    }
    Object.keys(stylingColIndices).forEach(type => {
      stylingColIndices[type] = stylingColTypes.findIndex(colType => colType === type);
    })

    console.log(stylingColIndices);

    if (stylingColIndices['COLOR'] >= 0) {
      setColorFixed(false);
      setColorColumn(layer.mapStyling.stylingColumns[stylingColIndices['COLOR']].field._id);
    }
    else {
      setColorFixed(true);
    }
    if (stylingColIndices['SYMBOL'] >= 0) {
      setSymbolFixed(false);
      setSymbolColumn(layer.mapStyling.stylingColumns[stylingColIndices['SYMBOL']].field._id);
    }
    else {
      setSymbolFixed(true);
    }
    if (stylingColIndices['RADIUS'] >= 0) {
      setRadiusFixed(false);
      setRadiusColumn(layer.mapStyling.stylingColumns[stylingColIndices['RADIUS']].field._id);
    }
    else {
      setRadiusFixed(true);
    }

  }, [layer])

  useEffect(() => {
    if (colorFixed) {
      setColorColumn();
      handleResetLayerStylingToFixed('COLOR');
    }
  }, [colorFixed])
  useEffect(() => {
    if (symbolFixed) {
      setSymbolColumn();
      handleResetLayerStylingToFixed('SYMBOL');
    }
  }, [symbolFixed])
  useEffect(() => {
    if (radiusFixed) {
      setRadiusColumn();
      handleResetLayerStylingToFixed('RADIUS');
    }
  }, [radiusFixed])


  const handleResetLayerStylingToFixed = (type) => {
    console.log("resetting to fixed...", type);
    // We call the handler for setting styling columns but with only the inputs type and remove = true
    handleSetStylingColumnsInLayer(type, "")("", "", true)
  }

  const handleSetStylingColumnField = (type) => (field) => {
    if (type === 'COLOR') setColorColumn(field);
    if (type === 'SYMBOL') setSymbolColumn(field);
    if (type === 'RADIUS') setRadiusColumn(field);
  }

  const handleSetStylingColumnsInLayer = (type, field) => (bins, func, remove) => {

    console.log(type, field, bins, func);
    let currentCols = layer && layer.mapStyling && layer.mapStyling.stylingColumns
      ? [...layer.mapStyling.stylingColumns]
      : null;

    const stylingInput = {
      type: type,
      field: field,
      bins: bins,
      func: func
    }

    if (currentCols) {
      const currentColForType = currentCols.map(col => col.type).findIndex(colType => colType === type)
      console.log(currentCols, currentColForType);

      if (currentColForType >= 0 && currentColForType < fields.length) {
        if (remove) {
          currentCols.splice(currentColForType, 1)
        } else {
          currentCols[currentColForType] = stylingInput;
        }
      } else {
        if (remove) {
          // do nothing
        } else {
          currentCols.push(stylingInput);
        }
      }

      console.log(currentCols);

      setLayer({
        ...layer,
        mapStyling: {
          ...layer.mapStyling,
          stylingColumns: currentCols
        }
      });
    }

  }

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = accordionActiveIndex === index ? -1 : index;
    setAccordionActiveIndex(newIndex);
  }

  const handleMapStylingPropChange = (property) => (value) => {
    console.log(layer);
    setLayer({
      ...layer,
      mapStyling: {
        ...layer.mapStyling,
        [property]: value
      }
    });
  }

  const handleMapStylingBinChange = (type, field) => (binIndex, binKey, binVal, remove) => {
    console.log(binIndex, binKey, binVal, type, field);

    // Check if there's already an entry for this type of styling
    const typeIndexInStylingColumns = layer.mapStyling.stylingColumns
      .map(col => col.type)
      .findIndex(colType => colType === type);

    // If there is already an entry for this type of styling
    if (typeIndexInStylingColumns >= 0) {
      const oldBins = layer.mapStyling.stylingColumns[typeIndexInStylingColumns].bins;
      const newBins = [...oldBins];

      console.log(newBins);
      // If we are deleteing the bin
      if (remove) {
        newBins.splice(binIndex, 1);

        // If not deleting the bin
      } else {

        newBins[binIndex] = { ...newBins[binIndex], [binKey]: binVal };

        // If new bin entry have min and max values
        if (newBins[binIndex].min || newBins[binIndex].max) {
          newBins[binIndex] = { ...newBins[binIndex], "key": (newBins[binIndex].min + " - " + newBins[binIndex].max) }
        }
      }
      console.log(newBins);
      handleSetStylingColumnsInLayer(type, field)(newBins)

      // If there is no entry of this type of styling
    } else {
      const newBins = [{ [binKey]: binVal }]

      // If new bin entry have min and max values
      if (!newBins[0].key && (newBins[0].min || newBins[0].max)) {
        newBins[0] = { ...newBins[0], "key": (newBins[0].min + " - " + newBins[0].max) }
      }
      console.log(newBins);
      handleSetStylingColumnsInLayer(type, field)(newBins)
    }
  }

  const handleFixedColorPickerClick = () => {
    setDisplayFixedColorPicker(!displayFixedColorPicker);
  }

  const getFieldValues = (col) => {
    // layer.features.map(feature => feature.properties.filter(prop => prop.field._id === symbolColumn).length > 0)
    //   ? layer.features.map(feature => feature.properties.filter(prop => prop.field._id === symbolColumn)[0].value)
    //   : null
    // console.log(layer.features)
    // console.log(col)
    // console.log(layer.features.map(feature => feature.properties.filter(prop => prop.field._id === col)[0]))
    return layer.features.map(feature => feature.properties.filter(prop => prop.field._id === col)[0].value)
  }


  //**************************************
  // Tooltip functions
  //**************************************


  const handleAddTooltip = (newTooltipsStateToSet) => {
    setLayer({
      ...layer,
      tooltipVars: newTooltipsStateToSet
    });
  }

  const addToolTip = (fieldID) => {
    console.log(fields);
    // const defaultValue = defaultMapStylings[type];
    console.log("Add a tooltip... ", fieldID);
    if (fields.map(field => field._id).includes(fieldID)) {
      const field = fields.filter(field => field._id === fieldID)[0]
      const newTooltipsStateToSet = layer.tooltipVars && layer.tooltipVars.length > 0
        ? [...layer.tooltipVars, field]
        : [field];
      handleAddTooltip(newTooltipsStateToSet);
    }
  }

  const removeToolTip = (fieldToRemove) => {
    console.log(fields);
    console.log("Remove a tooltip... ", fieldToRemove);
    if (fields.map(field => field._id).includes(fieldToRemove._id)
      && layer.tooltipVars.map(field => field._id).includes(fieldToRemove._id)) {
      const newTooltipsStateToSet = layer.tooltipVars.filter(field => field._id !== fieldToRemove._id);
      handleAddTooltip(newTooltipsStateToSet);
    }
  }


  //**************************************
  // Chart filter functions
  //**************************************

  const handleAddChartVar = (newChartVarsStateToSet) => {
    console.log("set new chart vars to layer...", newChartVarsStateToSet)
    setLayer({
      ...layer,
      chartVars: newChartVarsStateToSet
    });
  }

  const addChartFilter = (fieldID) => {
    console.log(fields);
    console.log("Add a chart filter... ", fieldID);
    if (fields.map(field => field._id).includes(fieldID)) {
      const field = fields.filter(field => field._id === fieldID)[0];

      const newChartVarObj = { "field": field };
      if (field.fieldType === 'DATE') newChartVarObj['grouping'] = 'DatePicker';

      const newChartVarsStateToSet = layer.chartVars && layer.chartVars.length > 0
        ? [...layer.chartVars, newChartVarObj]
        : [newChartVarObj];
      handleAddChartVar(newChartVarsStateToSet);
    }
  }

  const addPropertiesToChartVar = (propertiesObj) => {
    console.log(propertiesObj);

    if (propertiesObj.field && propertiesObj.field._id) {
      const fieldID = propertiesObj.field._id;


      // Look for index of new chart var object
      const fieldIndexInChartVarsArray = layer.chartVars && layer.chartVars.length > 0
        ? layer.chartVars.findIndex(chartVar => chartVar.field._id === fieldID)
        : null;

      // initialise new chart vars state with current chart vars
      let newChartVarsStateToSet = layer.chartVars && layer.chartVars.length > 0
        ? [...layer.chartVars]
        : [];

      console.log(layer.chartVars, fieldIndexInChartVarsArray);


      // if there is already an entry for this chart var, then update the properties of that chart var
      if (fieldIndexInChartVarsArray > -1) {
        newChartVarsStateToSet[fieldIndexInChartVarsArray] = { ...newChartVarsStateToSet[fieldIndexInChartVarsArray], ...propertiesObj }
        // otherwise add new chart var with given properties
      } else {
        newChartVarsStateToSet = [...newChartVarsStateToSet, propertiesObj]
      }

      // update layer state
      handleAddChartVar(newChartVarsStateToSet);
    }
  }

  const handleChartVarsBinChange = (field) => (binIndex, binKey, binVal, remove) => {
    console.log(binIndex, binKey, binVal, field);

    // Check if there's already an entry for this Chart Var
    const chartVarIndexInChartVarsArray = layer.chartVars
      .findIndex(chartVar => chartVar.field._id === field._id);

    // If there is already an entry for this Chart Var
    if (chartVarIndexInChartVarsArray >= 0) {
      const oldBins = layer.chartVars[chartVarIndexInChartVarsArray].bins;
      const newBins = oldBins && oldBins.length > 0 ? [...oldBins] : [];

      console.log(newBins);
      // If we are deleteing the bin
      if (remove) {
        newBins.splice(binIndex, 1);

        // If not deleting the bin
      } else {

        newBins[binIndex] = { ...newBins[binIndex], [binKey]: binVal };

        // If new bin entry have min and max values
        if (newBins[binIndex].min || newBins[binIndex].max) {
          newBins[binIndex] = { ...newBins[binIndex], "key": (newBins[binIndex].min + " - " + newBins[binIndex].max) }
        }
      }
      console.log(newBins);
      addPropertiesToChartVar({ "field": field, "bins": newBins })

      // If there is no entry of this type of bin
    } else {
      const newBins = [{ [binKey]: binVal }]

      // If new bin entry have min and max values
      if (!newBins[0].key && (newBins[0].min || newBins[0].max)) {
        newBins[0] = { ...newBins[0], "key": (newBins[0].min + " - " + newBins[0].max) }
      }
      console.log(newBins);
      addPropertiesToChartVar({ "field": field, "bins": newBins })
    }
  }

  const removeChartVar = (fieldToRemove) => {
    console.log(fields);
    console.log("Remove a chart var... ", fieldToRemove);
    if (fields.map(field => field._id).includes(fieldToRemove._id)
      && layer.chartVars.map(chartVar => chartVar.field._id).includes(fieldToRemove._id)) {
      const newChartVarsStateToSet = layer.chartVars.filter(chartVar => chartVar.field._id !== fieldToRemove._id);
      handleAddChartVar(newChartVarsStateToSet);
    }
  }
  
  return (
    <>
      <Grid columns="equal" centered>
        <Grid.Row>
          <LockIndicator layer={layer} id={layerID} userid={user.id} authorization={authorization} setAuthorization={setAuthorization} />
        </Grid.Row>
      </Grid>
      <Grid.Row>
        <Grid.Column computer={6} widescreen={4}>
          <Accordion>
            <Accordion.Title active={accordionActiveIndex === 0} index={0} onClick={handleAccordionClick}>
              <span className="tgicon tgicon-map-style"></span>
              {intl.get("editor.visualize.map_style")}
              <Icon name='dropdown' />
            </Accordion.Title>


            {/*  ---------------------------------------------*/}
            {/*  Map Styling  ------------------------------- */}
            {/*  ---------------------------------------------*/}

            <Accordion.Content active={accordionActiveIndex === 0}>
              {
                layer.mapStyling
                  ? <Form>
                    {/* Map Styling - Fill Opacity */}
                    <Segment textAlign='left' vertical>
                      <Header size='small'>{intl.get('editor.visualize.fill_opacity')}</Header>
                      <Form.Group>
                        <Form.Field>
                          <input
                            type="range"
                            min="0"
                            max="100"
                            value={layer.mapStyling.fillMultiplier ? layer.mapStyling.fillMultiplier * 100 : defaultMapStylings.fillMultiplier * 100}
                            className="rangeSlider"
                            onChange={(event) => handleMapStylingPropChange('fillMultiplier')(parseFloat(+event.target.value) / 100.0)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>{layer.mapStyling.fillMultiplier ? layer.mapStyling.fillMultiplier * 100 : defaultMapStylings.fillMultiplier * 100}</label>
                        </Form.Field>
                      </Form.Group>
                    </Segment>
                    {/* Map Styling - Color */}
                    <Segment textAlign='left' vertical>
                      <Header size='small'>{intl.get('editor.visualize.color.color')}</Header>
                      <Form.Group inline>
                        <Form.Field
                          control={Radio}
                          label={intl.get('editor.visualize.fixed')}
                          checked={colorFixed}
                          onChange={() => setColorFixed(true)}
                        />
                        <Form.Field
                          control={Radio}
                          label={intl.get('editor.visualize.by_value')}
                          checked={!colorFixed}
                          onChange={() => setColorFixed(false)}
                        />
                      </Form.Group>
                      {
                        colorFixed
                          ?
                          <Form.Field>
                            <Button
                              style={{
                                backgroundColor: (layer.mapStyling.color ? layer.mapStyling.color : defaultMapStylings.color),
                                width: '20px',
                                height: '20px'
                              }}
                              onClick={handleFixedColorPickerClick}
                            />
                            {displayFixedColorPicker
                              ? <div style={{
                                position: 'absolute',
                                zIndex: '10',
                              }}>
                                <div style={{
                                  position: 'fixed',
                                  top: '0px',
                                  right: '0px',
                                  bottom: '0px',
                                  left: '0px'
                                }} onClick={handleFixedColorPickerClick} />
                                <SwatchesPicker
                                  color={layer.mapStyling.color ? layer.mapStyling.color : defaultMapStylings.color}
                                  onChange={(color) => handleMapStylingPropChange('color')(color.hex)}
                                />
                              </div>
                              : null
                            }
                            {
                              colorFixed
                                ? <span style={{ color: layer.mapStyling.color }}>{layer.mapStyling.color}</span>
                                : ""
                            }
                          </Form.Field>
                          :
                          <>
                            <Form.Field>
                              <label>{intl.get('editor.visualize.color.choose_column_label')}</label>
                              {
                                fields && fields.length > 0
                                  ? <Dropdown
                                    label={"Color Column"}
                                    name={"colorColumn"}
                                    options={fields.map(option => ({ key: option._id, value: option._id, text: option.nameEN }))}
                                    selection
                                    fluid
                                    value={colorColumn}
                                    onChange={(event, { value }) => handleSetStylingColumnField('COLOR')(value)}
                                  />
                                  : null
                              }
                            </Form.Field>
                            <Form.Field>
                              {
                                colorColumn && layer && layer.features && fields && fields.length > 0
                                  ? <BinsSelectorComponent
                                    type={"COLOR"}
                                    field={fields.filter(field => field._id === colorColumn)[0]}
                                    fieldValues={getFieldValues(colorColumn)}
                                    bins={colorColumn && layer.mapStyling.stylingColumns.filter(col => col.field._id === colorColumn).length > 0
                                      ? layer.mapStyling.stylingColumns.filter(col => col.field._id === colorColumn)[0].bins : null}

                                    optionsChangeHandler={handleMapStylingBinChange('COLOR', fields.filter(field => field._id === colorColumn)[0])}
                                  />
                                  : null
                              }
                            </Form.Field>
                          </>
                      }
                    </Segment>
                    {/* Map Styling - Radius */}
                    {
                      layer.type === 'POINT'
                        ? <Segment textAlign='left' vertical>
                          <Header size='small'>{intl.get('editor.visualize.radius')}</Header>
                          <Form.Group inline>
                            <Form.Field
                              control={Radio}
                              label={intl.get('editor.visualize.fixed')}
                              checked={radiusFixed}
                              onChange={() => setRadiusFixed(true)}
                            />
                            <Form.Field
                              control={Radio}
                              label={intl.get('editor.visualize.by_value')}
                              checked={!radiusFixed}
                              onChange={() => setRadiusFixed(false)}
                            />
                          </Form.Group>
                          {
                            radiusFixed
                              ?
                              <Form.Group inline>
                                <Form.Field>
                                  <input
                                    type="range"
                                    min="1"
                                    max="20"
                                    value={
                                      layer.mapStyling.radius.min && layer.mapStyling.radius.max
                                        ? (layer.mapStyling.radius.min + layer.mapStyling.radius.max) / 2
                                        : (defaultMapStylings.radius.min + defaultMapStylings.radius.max) / 2
                                    }
                                    className="rangeSlider"
                                    onChange={(event) => handleMapStylingPropChange('radius')({ min: parseFloat(+event.target.value), max: parseFloat(+event.target.value) })}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <label>
                                    {
                                      radiusFixed
                                        ? layer.mapStyling.radius.min && layer.mapStyling.radius.max
                                          ? (layer.mapStyling.radius.min + layer.mapStyling.radius.max) / 2
                                          : (defaultMapStylings.radius.min + defaultMapStylings.radius.max) / 2
                                        : ""
                                    }
                                  </label>
                                </Form.Field>
                              </Form.Group>
                              :
                              <>
                                <Form.Field>
                                  <label>{intl.get('editor.visualize.choose_radius_column')}</label>
                                  {
                                    fields && fields.length > 0
                                      ? <Dropdown
                                        label={"Radius Column"}
                                        name={"radiusColumn"}
                                        options={fields.map(option => ({ key: option._id, value: option._id, text: option.nameEN }))}
                                        value={radiusColumn}
                                        selection
                                        fluid
                                        onChange={(event, { value }) => handleSetStylingColumnField('RADIUS')(value)}
                                      />
                                      : null
                                  }
                                </Form.Field>
                                <Form.Field>
                                  {
                                    radiusColumn && layer && layer.features && fields && fields.length > 0
                                      ? <BinsSelectorComponent
                                        type={"RADIUS"}
                                        field={fields.filter(field => field._id === radiusColumn)[0]}
                                        fieldValues={getFieldValues(radiusColumn)}
                                        bins={radiusColumn && layer.mapStyling.stylingColumns.filter(col => col.field._id === radiusColumn).length > 0
                                          ? layer.mapStyling.stylingColumns.filter(col => col.field._id === radiusColumn)[0].bins : null}
                                        optionsForValues={radiiList.map(radius => ({ key: radius, value: radius, text: radius }))}
                                        optionsChangeHandler={handleMapStylingBinChange('RADIUS', fields.filter(field => field._id === radiusColumn)[0])}
                                      />
                                      : null
                                  }
                                </Form.Field>
                              </>
                          }
                        </Segment>
                        : null
                    }
                    {/* Map Styling - Symbol */}
                    {
                      layer.type === 'POINT'
                        ? <Segment textAlign='left' vertical>
                          <Header size='small'>{intl.get("editor.visualize.symbol")}</Header>
                          <Form.Group inline>
                            <Form.Field
                              control={Radio}
                              label={intl.get('editor.visualize.fixed')}
                              checked={symbolFixed}
                              onChange={() => setSymbolFixed(true)}
                            />
                            <Form.Field
                              control={Radio}
                              label={intl.get('editor.visualize.by_value')}
                              checked={!symbolFixed}
                              onChange={() => setSymbolFixed(false)}
                            />
                          </Form.Group>
                          {
                            symbolFixed
                              ?
                              <Form.Field>
                                <Dropdown
                                  label={"Symbol"}
                                  name={"Symbol"}
                                  options={Object.keys(iconList).map(icon => ({ key: icon, value: icon, text: icon }))}
                                  value={layer.mapStyling.symbol ? layer.mapStyling.symbol : defaultMapStylings.symbol}
                                  selection
                                  fluid
                                  onChange={(event, { value }) => handleMapStylingPropChange('symbol')(value)}
                                />
                              </Form.Field>
                              :
                              <>
                                <Form.Field>
                                  <label>{intl.get('editor.visualize.choose_symbol_column')}</label>
                                  {
                                    fields && fields.length > 0
                                      ? <Dropdown
                                        label={"Symbol Column"}
                                        name={"symbolColumn"}
                                        options={fields.map(option => ({ key: option._id, value: option._id, text: option.nameEN }))}
                                        value={symbolColumn}
                                        selection
                                        fluid
                                        onChange={(event, { value }) => handleSetStylingColumnField('SYMBOL')(value)}
                                      />
                                      : null
                                  }
                                </Form.Field>
                                <Form.Field>
                                  {
                                    symbolColumn && layer && layer.features && fields && fields.length > 0
                                      ? <BinsSelectorComponent
                                        type={"SYMBOL"}
                                        field={fields.filter(field => field._id === symbolColumn)[0]}
                                        fieldValues={getFieldValues(symbolColumn)}
                                        bins={symbolColumn && layer.mapStyling.stylingColumns.filter(col => col.field._id === symbolColumn).length > 0
                                          ? layer.mapStyling.stylingColumns.filter(col => col.field._id === symbolColumn)[0].bins : null}
                                        optionsForValues={Object.keys(iconList).map(icon => ({ key: icon, value: icon, text: icon }))}
                                        optionsChangeHandler={handleMapStylingBinChange('SYMBOL', fields.filter(field => field._id === symbolColumn)[0])}
                                      />
                                      : null
                                  }
                                </Form.Field>
                              </>
                          }
                        </Segment>
                        : null
                    }
                  </Form>
                  : null
              }
            </Accordion.Content>

            {/*  ---------------------------------------------*/}
            {/*  Tooltips ----------------------------------- */}
            {/*  ---------------------------------------------*/}

            <Accordion.Title active={accordionActiveIndex === 1} index={1} onClick={handleAccordionClick}>
              <span className="tgicon tgicon-tooltip"></span>
              {intl.get("editor.visualize.tooltips")}
              <Icon name='dropdown' />
            </Accordion.Title>
            <Accordion.Content active={accordionActiveIndex === 1}>
              <Form>
                <Grid>
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      {
                        layer.tooltipVars && layer.tooltipVars.length > 0
                          ? layer.tooltipVars.map((tooltipVar, i) => {
                            return <Segment textAlign='left' vertical key={i}>
                              <Form.Group key={'tooltip' + i}>
                                <Form.Field width={10}>
                                  <h3>{tooltipVar.nameEN}</h3>
                                </Form.Field>
                                <Form.Field width={6} align="right">
                                  <Button basic icon color="red" size="mini"
                                    onClick={() => removeToolTip(tooltipVar)}
                                  >
                                    <i aria-hidden="true" className="remove icon"></i>
                                  </Button>
                                </Form.Field>
                              </Form.Group>
                            </Segment>
                          })
                          : null
                      }
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Group key={'tooltipNewValPicker'}>
                        <Form.Field width={10}>
                          {fields && fields.length > 0 && layer
                            ? <Dropdown
                              placeholder={"Pick New Tooltip"}
                              options={
                                fields
                                  .filter(currentField => {
                                    return !layer.tooltipVars || !layer.tooltipVars.map(field => field._id).includes(currentField._id)
                                  })
                                  .map(field => ({ key: field._id, value: field._id, text: field.nameEN }))
                              }
                              selection
                              onChange={(event, { value }) => setNewTooltipToAdd(value)}
                            />
                            : ""
                          }
                        </Form.Field>
                        <Form.Field width={6}>
                          <Button primary
                            onClick={() => addToolTip(newTooltipToAdd)}
                          >
                            <i aria-hidden="true" className="chevron plus icon"></i>
                            {intl.get("button.add_more")}
                          </Button>
                        </Form.Field>
                      </Form.Group>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Accordion.Content>


            {/*  ---------------------------------------------*/}
            {/*  Filter Charts ------------------------------ */}
            {/*  ---------------------------------------------*/}

            <Accordion.Title active={accordionActiveIndex === 2} index={2} onClick={handleAccordionClick}>
              <span className="tgicon tgicon-filter-chart"></span>
              {intl.get("editor.visualize.filter_charts")}
              <Icon name='dropdown' />
            </Accordion.Title>
            <Accordion.Content active={accordionActiveIndex === 2}>
              <Form>
                {/* <Grid>
                <Grid.Row>
                  <Grid.Column textAlign="left">
                    <Form.Field>
                      <Form.Group>
                        <Select placeholder="Choose" options={[{ key: 'test-1', text: 'test-1' }, { key: 'test-2', text: 'test-2' }]} />
                        <Button>{intl.get("button.add_more")}</Button>
                      </Form.Group>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid> */}
                <Grid>
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      {
                        layer.chartVars && layer.chartVars.length > 0
                          ? layer.chartVars.map((chartVar, i) => {
                            return <>
                              <Segment textAlign='left' vertical>
                                <Form.Group key={'chartvar' + i}>
                                  <Form.Field width={10}>
                                    <h3>{chartVar.field.nameEN}</h3>
                                  </Form.Field>
                                  <Form.Field width={6} align="right">
                                    <Button basic icon color="red"
                                      onClick={() => removeChartVar(chartVar.field)}
                                    ><i aria-hidden="true" className="remove icon"></i> Remove Chart Filter</Button>
                                  </Form.Field>
                                </Form.Group>
                                <br />
                                <Form.Group key={'chartvarbins' + i}>
                                  <Form.Field width={16}>
                                    {
                                      layer && layer.features && fields && fields.length > 0
                                        ? <BinsSelectorComponent
                                          type={"CHARTVAR"}
                                          field={fields.filter(field => field._id === chartVar.field._id)[0]}
                                          fieldValues={getFieldValues(chartVar.field._id)}
                                          bins={
                                            chartVar.bins && chartVar.bins.length > 0
                                              ? chartVar.bins
                                              : null
                                          }
                                          // optionsChangeHandler={handleMapStylingBinChange('CHARTVAR',fields.filter(field => field._id === radiusColumn)[0])}
                                          // handleChartVarsBinChange(chartVar)
                                          optionsChangeHandler={handleChartVarsBinChange(chartVar.field)}
                                        />
                                        : null
                                    }
                                  </Form.Field>
                                </Form.Group>
                              </Segment>
                            </>
                          })
                          : null
                      }
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Group key={'charVarNewValPicker'}>
                        <Form.Field width={10}>
                          {fields && fields.length > 0 && layer
                            ? <Dropdown
                              placeholder={"Pick New Chart Filter"}
                              options={
                                fields
                                  .filter(currentField => {
                                    return !layer.chartVars || !layer.chartVars.map(chartVar => chartVar.field._id).includes(currentField._id)
                                  })
                                  .map(field => ({ key: field._id, value: field._id, text: field.nameEN }))
                              }
                              selection
                              onChange={(event, { value }) => setNewChartVarToAdd(value)}
                            />
                            : ""
                          }
                        </Form.Field>
                        <Form.Field width={6}>
                          <Button primary
                            onClick={() => addChartFilter(newChartVarToAdd)}
                          ><i aria-hidden="true" className="chevron plus icon"></i> {intl.get("button.add_more")}</Button>
                        </Form.Field>
                      </Form.Group>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Accordion.Content>
          </Accordion>
        </Grid.Column>
        <Grid.Column computer={10} widescreen={12}>
          {
            layer && layer._id
              ? renderMap([layer])
              : renderMap()
            // : <></>
          }
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default Step2;
