import React, { useState, useEffect, useContext } from "react";
// import Layout from '../components/Layout';
import { useQuery } from "@apollo/react-hooks";
import {
  Grid
  // Button,
  // Checkbox,
  // Accordion,
  // Radio,
  // Icon,
  // Divider
} from "semantic-ui-react";
import MapView from "../components/viewer/MapView";
import AddLayers from "../components/viewer/AddLayers";
import LayerCard from "../components/viewer/LayerCard";
import FilterCard from "../components/viewer/FilterCard";
// import { LocalizationContext } from '../context/localization';
import {
  l10nMain,
  getKeyValuesForProperties,
  getKeyValuesForPropertiesWithGeometry,
  makeUniverse,
  mapAggregateVizTypes,
  defaultMapStylings
} from "../utils";
import { LanguageContext } from '../context/langProvider'
import "../styles/ViewerPage.scss";
// import SplitPane, { Pane } from 'react-split-pane';
// import { splitResizer } from '../styles/SplitResizer';

// import SINGLE_LAYER_QUERY from '../graphql/singleLayerQuery';
// import LAYERS_DETAILS_QUERY from '../graphql/layersDetailsQuery';
import LAYER_BYID from "../graphql/getLayerByID";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function ViewerPage(props) {
  const { user } = props;
  // const { locale, updateLocalization } = useContext(LocalizationContext);
  const l10n = (s, dontWrapInSpan) => l10nMain(s, "en", dontWrapInSpan);
  // Dawei burglaries                 | 5e7377af6fffe007380ee98c
  // Dawei Wards and Village Tracts   | 5e79b312ef67933a185da2e5
  // Demoso Villages                  | 5e82217fe58e12080c24beb9
  // Demoso Public Facilities         | 5e82257de58e12080c24c2c6
  // const ids = ["5e82217fe58e12080c24beb9","5e82257de58e12080c24c2c6"];
  // const ids = ["5e79b312ef67933a185da2e5","5e7377af6fffe007380ee98c"];
  const [layers, setLayers] = useState([]);
  const [dataMultiverse, setDataMultiverse] = useState([]);
  const [dataMultiverseFilters, setDataMultiverseFilters] = useState({});
  const [selectedIDs, setSelectedIDs] = useState({});
  const [layerToGetFromQuery, setLayerToGetFromQuery] = useState();
  const [currentLayerID, setCurrentLayerID] = useState("");
  const [mapAlphas, setMapAlphas] = useState({});
  const [mapVizTypes, setMapVizTypes] = useState({});
  const [showAddLayersModal, setShowAddLayersModal] = useState(false);
  const [layerOrdering, setLayerOrdering] = useState([]);

  const [centerOnLayer, setCenterOnLayer] = useState();

  const getCheckedIDs = selectedIDs => {
    const ids = selectedIDs
      ? Object.keys(selectedIDs).filter(id => selectedIDs[id] === true)
      : [];
    console.log(ids);
    return !!ids.length ? ids : [null];
  };

  const getCheckedIDsOrdered = selectedIDs => {
    const checkedIDs = getCheckedIDs(selectedIDs);
    return layerOrdering.filter(l => checkedIDs.includes(l));
  };

  const {
    loading: layerloading,
    data: layerdata,
    error: layererror
  } = useQuery(LAYER_BYID, { variables: { id: layerToGetFromQuery }, skip: (layerToGetFromQuery === null || layerToGetFromQuery === undefined) });
  const defaultAlpha = defaultMapStylings.alpha;
  const defaultVizType = mapAggregateVizTypes[0];
  const maxRowsInDataSet = 10000;

  const makeUniverseWithThisLayer = singleLayerData => {
    // console.log(dataMultiverse, dataMultiverseFilters, mapAlphas, mapVizTypes);
    console.log("ViewerPage HAHA")
    console.log(singleLayerData)
    const currentLoopLayerName = singleLayerData.nameEN;
    const currentLoopLayerData = singleLayerData;
    const currentLoopLayerID = singleLayerData._id;
    // console.log(currentLoopLayerData);
    return makeUniverse(
      currentLoopLayerName,
      getKeyValuesForPropertiesWithGeometry(currentLoopLayerData.features),
      currentLoopLayerData.chartVars,
      currentLoopLayerData.mapStyling.stylingColumns
    ).then(results => {
      // console.log(results);

      return {
        uniWithGroupBys: {
          key: currentLoopLayerID,
          title: currentLoopLayerData.nameEN,
          isLocked: currentLoopLayerData.isLocked,
          lockedBy: currentLoopLayerData.lockedBy,
          name: languageContext.language === 'my' ? currentLoopLayerData.nameMM : currentLoopLayerData.nameEN,
          nameMM: currentLoopLayerData.nameMM,
          nameEN: currentLoopLayerData.nameEN,
          type: currentLoopLayerData.type,
          location: currentLoopLayerData.location && currentLoopLayerData.location,
          uploadedBy: currentLoopLayerData.uploadedBy && currentLoopLayerData.uploadedBy,
          dateRange: currentLoopLayerData.dateRange && currentLoopLayerData.dateRange,
          updatedAt: currentLoopLayerData.updatedAt,
          groupBys: results.groupBys,
          uni: results.uni,
          mapStyling: singleLayerData.mapStyling,
          type: singleLayerData.type
        },
        uniFilters: results.uni.filter
          ? results.uni.filter
          : results.uni.filters
      };
    });
  };

  const languageContext = useContext(LanguageContext)

  useEffect(() => {
    if (!layerloading && layerdata) {
      // const checkedIDs = getCheckedIDs(selectedIDs);

      const currentLayers = layers.filter(
        layer => selectedIDs && selectedIDs[layer._id]
      );
      const queriedLayerID = layerdata.layersQuery.getLayerByID
        ? layerdata.layersQuery.getLayerByID._id
        : null;

      if (!currentLayers.includes(currentLayerID)) setCurrentLayerID("");

      console.log(
        "Query result...",
        layerdata,
        "Selected IDs...",
        selectedIDs,
        "layers...",
        layers,
        "Current Layers...",
        currentLayers
      );

      if (
        selectedIDs &&
        queriedLayerID &&
        selectedIDs[queriedLayerID] &&
        !currentLayers.map(layer => layer._id).includes(queriedLayerID)
      ) {
        console.log(
          "Setting layers with new layer (" +
          queriedLayerID +
          ") " +
          layerdata.layersQuery.getLayerByID.nameEN
        );
        const layersToSet = [...currentLayers, layerdata.layersQuery.getLayerByID];
        // setLayers([...currentLayers, layerdata.layersQuery.getLayerByID]);
        setLayers(layersToSet);
        setLayerOrdering([...layersToSet.map(l => l._id)])
      } else {
        console.log("Setting layers with old layers...", currentLayers);
        setLayers([...currentLayers]);
        setLayerOrdering([...currentLayers.map(l => l._id)])
      }
    }
  }, [layerdata, getCheckedIDs(selectedIDs).toString()]);



  useEffect(() => {
    const checkedIDs = getCheckedIDs(selectedIDs);
    const idsInLayers = !!layers.length ? layers.map(layer => layer._id) : [];

    // console.log("Checking Layer IDs...","checked...",checkedIDs,"In Layers...",idsInLayers);

    let layerToSetForQuery = "";

    for (let i = 0; i < checkedIDs.length; i++) {
      if (!idsInLayers.includes(checkedIDs[i]))
        layerToSetForQuery = checkedIDs[i];
    }

    setLayerToGetFromQuery(layerToSetForQuery);
  }, [getCheckedIDs(selectedIDs).toString()]);

  useEffect(() => {
    if (layers) {
      console.log("Enough Layers Now - Making Universe");
      // console.log(layers,dataMultiverse,dataMultiverseFilters);

      let toAddToMultiverse = [];
      let toRemoveFromMultiverse = [];

      const idsInMultiverse = dataMultiverse.map(uni => uni.key);
      const idsInLayers = layers.map(layer => layer._id);

      for (let i = 0; i < idsInLayers.length; i++) {
        if (!idsInMultiverse.includes(idsInLayers[i]))
          toAddToMultiverse.push(layers[i]);
      }

      for (let i = 0; i < idsInMultiverse.length; i++) {
        if (!idsInLayers.includes(idsInMultiverse[i]))
          toRemoveFromMultiverse.push(idsInMultiverse[i]);
      }

      Promise.all(
        toAddToMultiverse.map(async singleLayerData => {
          console.log(
            "Making Universe with new layer (" +
            singleLayerData._id +
            ") " +
            singleLayerData.nameEN
          );

          const currentLoopLayerID = singleLayerData._id;
          const {
            uniWithGroupBys,
            uniFilters
          } = await makeUniverseWithThisLayer(singleLayerData);
          return {
            currentLoopLayerID,
            uniWithGroupBys,
            uniFilters: { [currentLoopLayerID]: uniFilters },
            mapAlpha: { [currentLoopLayerID]: defaultAlpha },
            mapVizType: { [currentLoopLayerID]: defaultVizType }
          };
        })
      ).then(dataForSettingMultiverse => {
        const oldUnisWithUncheckedRemoved = dataMultiverse
          .filter(uni => {
            if (toRemoveFromMultiverse.includes(uni.key)) {
              console.log(
                "Removing layer (" +
                uni.key +
                ") " +
                uni.title +
                " from Universe"
              );
              return false;
            }
            return true;
          })
          .map(uni => {
            return {
              currentLoopLayerID: uni.key,
              uniWithGroupBys: uni,
              uniFilters: { [uni.key]: dataMultiverseFilters[uni.key] },
              mapAlpha: { [uni.key]: mapAlphas[uni.key] },
              mapVizType: { [uni.key]: mapVizTypes[uni.key] }
            };
          });

        // console.log(dataForSettingMultiverse, oldUnisWithUncheckedRemoved);

        if (
          !!dataForSettingMultiverse.length ||
          !!oldUnisWithUncheckedRemoved.length
        )
          updateMultiverseAndMapViz(
            dataForSettingMultiverse.concat(oldUnisWithUncheckedRemoved)
          );
        else updateMultiverseAndMapViz([]);
      });
    }
  }, [layers ? layers.map(layer => layer._id).toString() : null]);

  const updateMultiverseAndMapViz = dataForSettingMultiverse => {
    // console.log(dataForSettingMultiverse);
    let uniFilters = {};
    let mapAlphas = {};
    let mapVizTypes = {};
    for (let i = 0; i < dataForSettingMultiverse.length; i++) {
      uniFilters = {
        ...uniFilters,
        ...dataForSettingMultiverse[i].uniFilters
      };
      mapAlphas = { ...mapAlphas, ...dataForSettingMultiverse[i].mapAlpha };
      mapVizTypes = {
        ...mapVizTypes,
        ...dataForSettingMultiverse[i].mapVizType
      };
    }
    setDataMultiverse(dataForSettingMultiverse.map(d => d.uniWithGroupBys));
    setDataMultiverseFilters(uniFilters);
    setMapAlphas(mapAlphas);
    setMapVizTypes(mapVizTypes);
  };

  useEffect(() => {
    // console.log("*----- Universe Updated -----*");
    // console.log(dataMultiverse);
  }, [dataMultiverse]);

  // Filters and Legends Prep

  const setFilter = (toClearToggleOrReplace) => (columnKey, filterObject, layerID) => {
    console.log("Doing: ", toClearToggleOrReplace, "Filtering with: ", columnKey, filterObject, layerID);

    if (toClearToggleOrReplace === 'clear') {
      dataMultiverse
        .filter(layer => layer.key === layerID)[0]
        .uni.filter(columnKey)
        .then(uni => {
          // console.log(uni.filters);
          setDataMultiverseFilters({
            ...dataMultiverseFilters,
            [layerID]: uni.filters
          });
        });
    } else if (toClearToggleOrReplace === 'toggle') {
      dataMultiverse
        .filter(layer => layer.key === layerID)[0]
        .uni.filter(columnKey, filterObject, false, false)
        .then(uni => {
          // console.log(uni.filters);
          setDataMultiverseFilters({
            ...dataMultiverseFilters,
            [layerID]: uni.filters
          });
        });
    } else if (toClearToggleOrReplace === 'replace') {
      dataMultiverse
        .filter(layer => layer.key === layerID)[0]
        .uni.filter(columnKey, filterObject, false, true)
        .then(uni => {
          // console.log(uni.filters);
          setDataMultiverseFilters({
            ...dataMultiverseFilters,
            [layerID]: uni.filters
          });
        });
    }

  };

  const genFunIsThisItemChecked = filters => {
    return item => {
      if (filters && Object.keys(filters).includes(item.groupBy)) {
        if (
          filters[item.groupBy].value === item.key ||
          filters[item.groupBy].value.includes(item.key)
        )
          return true;
      }
      return false;
    };
  };

  // Rearrange Layers

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // console.log(result,layers || layers.map(d=>d.nameEN),dataMultiverse || dataMultiverse.map(d=>d.title));

    if (!result.destination) {
      return;
    }

    const reorderedLayers = reorder(
      layerOrdering,
      result.source.index,
      result.destination.index
    );

    // console.log(reorderedLayers || reorderedLayers.map(d=>d.nameEN),reorderedMultiverse || reorderedMultiverse.map(d=>d.title));
    // console.log(reorderedLayers);

    setLayerOrdering(reorderedLayers)

  }

  // Map Prep

  const setSpecificMapAlpha = layerID => {
    return newAlpha => {
      setMapAlphas({ ...mapAlphas, [layerID]: newAlpha });
    };
  };

  const setSpecificVizType = layerID => {
    return newVizType => {
      // console.log(mapVizTypes)
      setMapVizTypes({ ...mapVizTypes, [layerID]: newVizType });
    };
  };

  const getDataFromLayersForMap = layerDataSets => {
    const layersData = {};
    layerDataSets.forEach(layerDataSet => {
      layersData[layerDataSet._id] = {
        ...layerDataSet,
        dataSet: [
          {
            features: dataMultiverse.filter(
              layer => layer.key === layerDataSet._id
            ).length
              ? dataMultiverse
                .filter(layer => layer.key === layerDataSet._id)[0]
                .uni.columns[0].dimension.top(maxRowsInDataSet)
                .map(row => {
                  const { geometry, ...properties } = row;
                  return {
                    type: "Feature",
                    properties: properties,
                    geometry: geometry
                  };
                })
              : getKeyValuesForProperties(layerDataSet.features)
          }
        ]
      };
    });


    return layersData;
  };

  const prepMapProps = layerDataSets => {
    // console.log(dataMultiverse);
    // console.log(layerDataSets);

    const defaultProps = {
      height: "92vh",
      // "width": "100%",
      width: "100vw - 10",
      center: [8.9806, 38.7578],
      data: {},
      layersControlsData: [],
      layerSortOrder: []
    };

    const getCenter = () => {
      // console.log(layerDataSets);
      let center = defaultProps.center;
      if (centerOnLayer && layerDataSets.map(l => l._id).includes(centerOnLayer)) {
        const centerLayer = layerDataSets
          .filter(l => l._id === centerOnLayer)[0]

        if (centerLayer.location && centerLayer.location.center && centerLayer.location.lat && centerLayer.location.lon) {
          center = [centerLayer.location.lat, centerLayer.location.lon];
        }

      } else if (layerDataSets.length > 0 && layerDataSets[0].location && layerDataSets[0].location.center
        && layerDataSets[0].location.center.lat && layerDataSets[0].location.center.lon) {
        center = [layerDataSets[0].location.center.lat, layerDataSets[0].location.center.lon]
      }

      return center;
    }


    return !layerDataSets
      ? defaultProps
      : {
        ...defaultProps,
        data: getDataFromLayersForMap(layerDataSets),
        layersControlsData: layerDataSets.map(layerDataSet => ({
          _id: layerDataSet._id,
          title: layerDataSet.nameEN,
          alpha: mapAlphas[layerDataSet._id],
          showing: true,
          mapVizType: mapVizTypes[layerDataSet._id]
        })),
        // layerSortOrder: layerDataSets.map(layerDataSet => ({
        //   _id: layerDataSet._id
        // })),
        layerSortOrder: layerOrdering.map(l => ({ _id: l })),
        center: getCenter()
      };
  };

  const renderMap = layersInput => {
    // console.log(layersInput);

    const dataForMap = layersInput ? prepMapProps(layersInput) : prepMapProps();
    // const dataForMap = layersInput ? prepMapProps(layersOrdered) : prepMapProps();
    // console.log(dataForMap);
    return (
      <MapView
        data={dataForMap.data}
        layersControlsData={dataForMap.layersControlsData}
        layerSortOrder={dataForMap.layerSortOrder}
        height={dataForMap.height}
        width={dataForMap.width}
        center={dataForMap.center}
        l10n={l10n}
        language={languageContext.language}
        softFilters={[]}
      />
    );
  };

  // const Wrapper = splitResizer;

  return (
    <Grid className="ViewerPage">
      <Grid.Row>
        <Grid.Column>
          {/* <Wrapper> */}
          {/* <SplitPane defaultSize={currentLayerID ? 600 : 300} maxSize={600} minSize={300} style={{ height: '92vh'}}> */}
          {/* <Pane> */}
          {/* <SplitPane defaultSize={300} maxSize={300} minSize={300} style={{ overflow: 'visible !important' }}> */}
          {/* <Pane className="LayerPanel"> */}
          <div className="LayerPanel">
            <div id="layerScrollingPanel" className="scrolling content">
              <div>
                <h4 className="primary-color-text">
                  {(dataMultiverse && dataMultiverse.length) + ` layers open`}
                </h4>
              </div>
              <AddLayers
                selectedIDs={selectedIDs}
                setSelectedIDs={setSelectedIDs}
                showAddLayersModal={showAddLayersModal}
                setShowAddLayersModal={setShowAddLayersModal}
                dataMultiverse={dataMultiverse}
              />
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    // style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {

                        dataMultiverse && dataMultiverse.length > 0
                          // ? getCheckedIDs(selectedIDs).map((layerID,index) => {
                          ? getCheckedIDsOrdered(selectedIDs).map((layerID, index) => {
                            // console.log(layerID);
                            const layer = dataMultiverse.filter(
                              uni => uni.key === layerID
                            )[0];
                            console.log(layer);
                            return layer
                              ? (
                                <Draggable key={"draggable" + layer.key} draggableId={layer.key} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <LayerCard
                                        user={user}
                                        isLocked={layer.isLocked}
                                        lockedBy={layer.lockedBy}
                                        title={layer.title}
                                        layerID={layer.key}
                                        uni={layer.uni}
                                        key={"legendcard" + layer.key}
                                        groupBys={layer.groupBys.filter(gb => {
                                          // console.log(gb);
                                          return gb[0].category === "legend";
                                        })}
                                        nameMM={layer.nameMM}
                                        nameEN={layer.nameEN}
                                        location={layer.location}
                                        type={layer.type}
                                        uploadedBy={layer.uploadedBy}
                                        updatedAt={layer.updatedAt}
                                        dateRange={layer.dateRange}
                                        filterFunc={setFilter('toggle')}
                                        clearFunc={setFilter('clear')}
                                        replaceFunc={setFilter('replace')}
                                        filters={dataMultiverseFilters[layer.key]}
                                        setMapAlpha={setSpecificMapAlpha(layer.key)}
                                        mapAlpha={mapAlphas[layer.key]}
                                        currentLayerID={currentLayerID}
                                        setCurrentLayerID={setCurrentLayerID}
                                        isThisItemChecked={genFunIsThisItemChecked(
                                          dataMultiverseFilters[layer.key]
                                        )}
                                        mapVizType={mapVizTypes[layer.key]}
                                        dataType={layer.type}
                                        setMapVizType={setSpecificVizType(layer.key)}
                                        mapStyling={layer.mapStyling}
                                        selectedIDs={selectedIDs}
                                        setSelectedIDs={setSelectedIDs}
                                      />
                                    </div>
                                  )}
                                </Draggable>

                              )
                              : <div></div>;
                          })

                          : <div></div>
                      }
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {/* {dataMultiverse && dataMultiverse.length > 0
                ? getCheckedIDs(selectedIDs).map(layerID => {
                  const layer = dataMultiverse.filter(
                    uni => uni.key === layerID
                  )[0];
                  return layer ? (
                    <LayerCard
                      user={user}
                      isLocked={layer.isLocked}
                      lockedBy={layer.lockedBy}
                      title={layer.title}
                      layerID={layer.key}
                      uni={layer.uni}
                      key={"legendcard" + layer.key}
                      groupBys={layer.groupBys.filter(gb => {
                        return gb[0].category === "legend";
                      })}
                      filterFunc={setFilter('toggle')}
                      clearFunc={setFilter('clear')}
                      replaceFunc={setFilter('replace')}
                      filters={dataMultiverseFilters[layer.key]}
                      setMapAlpha={setSpecificMapAlpha(layer.key)}
                      mapAlpha={mapAlphas[layer.key]}
                      currentLayerID={currentLayerID}
                      setCurrentLayerID={setCurrentLayerID}
                      isThisItemChecked={genFunIsThisItemChecked(
                        dataMultiverseFilters[layer.key]
                      )}
                      mapVizType={mapVizTypes[layer.key]}
                      dataType={layer.type}
                      setMapVizType={setSpecificVizType(layer.key)}
                      mapStyling={layer.mapStyling}
                      selectedIDs={selectedIDs}
                      setSelectedIDs={setSelectedIDs}
                    />
                  ) : null;
                })
                : null} */}
            </div>
          </div>

          {/* </Pane> */}
          {currentLayerID ? (
            <div className="LayerPanel FilterPanel">
              <div
                className="scrolling content "
                style={{ overflowY: "auto", height: "92vh" }}
              >
                {dataMultiverse &&
                  dataMultiverse.length > 0 &&
                  dataMultiverse.filter(
                    layer => layer.key === currentLayerID
                  )[0] ? (
                    (layer => {
                      console.log(layer)
                      return layer.groupBys
                        .filter(gb => gb[0].category === "chart")
                        .map((gb, index) => {
                          return (
                            <FilterCard
                              key={"filtercard" + layer.key + "index" + index}
                              layerID={layer.key}
                              title={gb[0].title}
                              uni={layer.uni}
                              dataType={gb[0].dataType}
                              groupBys={[gb]}
                              filterFunc={setFilter('toggle')}
                              clearFunc={setFilter('clear')}
                              replaceFunc={setFilter('replace')}
                              filters={dataMultiverseFilters[layer.key]}
                              isThisItemChecked={genFunIsThisItemChecked(
                                dataMultiverseFilters[layer.key]
                              )}
                            />
                          );
                        });
                    })(
                      dataMultiverse.filter(
                        layer => layer.key === currentLayerID
                      )[0]
                    )
                  ) : (
                    <></>
                  )}
              </div>
            </div>
          ) : (
              <></>
            )
            // <Pane className="emptyPane">&nbsp;</Pane>
          }
          {/* </SplitPane> */}
          {/* <Pane className="MapPanel" > */}
          {dataMultiverse &&
            dataMultiverse.length > 0 &&
            !layers.filter(layer => layer === null).length
            ? renderMap(layers)
            : renderMap()
            // : <></>
          }
          {/* </Pane> */}
          {/* </SplitPane> */}
          {/* </Wrapper> */}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default ViewerPage;
