import gql from 'graphql-tag';

export default gql`
query layersTotalQuery{
    layersQuery{
        getLayersTotal{
          count
        }
    }
}
`