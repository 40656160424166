import gql from 'graphql-tag';

export default gql`
  query{
    commonQueries{
      getOffices(take:100){
        _id
        level
        ministry
        department
        location{
          _id
          nameMM
          nameEN
        }
        parent{
          _id
          nameEN
          nameMM
        }
      }
    }
  }
`