import React, { useContext } from 'react';
import { Checkbox, Popup, Icon } from 'semantic-ui-react'
import moment from 'moment';
import intl from 'react-intl-universal';
import { LanguageContext } from '../../context/langProvider'

function AddLayerCheckbox(props) {

	const { data, onChange, checked } = props;
	const languageContext = useContext(LanguageContext)

	return (
		<div className="addLayerCheckbox">
			<Checkbox label={languageContext.language === 'my' ? data.nameMM : data.nameEN} onChange={onChange} checked={checked} />
			<Popup
				trigger={
					<span className="tgicon tgicon-info teal" style={{ marginLeft: '10px' }}></span>
				}
				position='right center'
				large
				style={{ background: '#f1f1f1' }}
			>
				<Popup.Header className="header green-text">{intl.get('viewer.layer_info')}</Popup.Header>
				<Popup.Content>
					<p>{intl.get('viewer.info_type', { level: data.location ? data.location.level : '', type: data.type })}</p>
					<p>{data.location && data.location.nameEN ? (intl.get('viewer.info_location', { location: data.location.nameEN })) : ''}</p>
					<p>{intl.get('viewer.info_uploaded', { name: data.uploadedBy ? data.uploadedBy.email : '', date: moment(data.updatedAt).format('LLL') })}</p>
					<p>{(data.dateRange.start && data.dateRange.end) ? intl.get('viewer.info_coverage', { from_date: moment(data.dateRange.start).format('LL'), to_date: moment(data.dateRange.start).format('LL') }) : ''}</p>
				</Popup.Content>
			</Popup>
		</div>
	);

}
export default AddLayerCheckbox;