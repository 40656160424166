import React from 'react';
import addElipses from './addElipses';

export default (caption,width,totalVal,filteredVal,maxVal) => {
    const barheight = 20;
    return <svg className='charFiltersBar' width={width} height={barheight} key={'barCharBar'+caption}>
      <g key={'barCharBar'+caption+'Wrapper'}>
        <rect key={'barCharBar'+caption+'selectedRect'} x="20" y="0" width={filteredVal/maxVal*width} height={barheight} className="barChartBar"></rect>
        {/* <rect key={'barCharBar'+caption+'unselectedRect'} x={filteredVal/maxVal*width} y="0" width={(totalVal-filteredVal)/maxVal*width} height={barheight} className="barChartBar notSelected"></rect> */}
        <text key={'barCharBar'+caption+'selectedLabel'} x="25" y={barheight*.66} className="barChartBarLabel">{addElipses(caption)}</text>
        {/* <text key={'barCharBar'+caption+'totalLabel'} x={width - 5} y={barheight*.66} className="barChartBarLabel count">{filteredVal} / {totalVal}</text> */}
        <text key={'barCharBar'+caption+'totalLabel'} x={width - 5} y={barheight*.66} className="barChartBarLabel count">{filteredVal}</text>
      </g>
    </svg>
  }