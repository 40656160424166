import React, { useReducer, createContext } from 'react';
import { getToken, setToken } from '../utils';
import JwtDecode from 'jwt-decode';

const user = new Promise((resolve, reject) => {
  const token = getToken('expressGraphQLAuth');
  if (!!token) {
    console.log('has token');
    return resolve(JwtDecode(token));
  } else {
    console.log('no token');
    return reject(null);
  }
});

const initialState = {
  user,
  isVerifyingOTP: false,
  otpRequestToken: null,
  exp: null,
};

const AuthContext = createContext({
  user: null,
  login: userData => {
    console.log(userData);
  },
  logout: () => { },
});

function authReducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: new Promise((resolve, reject) => {
          const token = getToken('expressGraphQLAuth');
          if (!!token) {
            console.log('has token');
            window.location.reload()
            return resolve(JwtDecode(token));
          } else {
            console.log('no token');
            return reject(null);
          }
        }),
        isVerifyingOTP: false,
        otpRequestToken: null,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: new Promise(resolve => {
          console.log('LOGOUT');
          return resolve(null);
        }),
        isVerifyingOTP: false,
        otpRequestToken: null,
      };
    case 'VERIFY_OTP':
      return {
        ...state,
        isVerifyingOTP: true,
        otpRequestToken: action.otpRequestToken,
      };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  function login(userData) {
    return new Promise((resolve, reject) => {
      if (userData) {
        setToken(
          userData.token,
          'expressGraphQLAuth',
          console.log.bind(this, 'complete'),
        );
        setToken(
          userData.refreshToken,
          'expressGraphQLAuthRefresh',
          console.log.bind(this, 'complete'),
        );
        setToken(
          userData.socketToken,
          'expressGraphQLAuthSocket',
          console.log.bind(this, 'complete'),
        );
        dispatch({
          type: 'LOGIN',
          payload: userData,
        });
        return resolve('passing');
      } else reject(null);
    });
  }

  function logout() {
    return dispatch({ type: 'LOGOUT' });
  }

  function verifyOTP(otpRequestToken, callback) {
    dispatch({
      type: 'VERIFY_OTP',
      otpRequestToken,
    });
    if (callback && typeof callback === 'function') {
      callback();
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        login,
        logout,
        verifyOTP,
        isVerifyingOTP: state.isVerifyingOTP,
        otpRequestToken: state.otpRequestToken,
      }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
