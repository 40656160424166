import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Header, Grid, Segment, Table, Form, Button, Icon, Pagination } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import NewUser from './NewUser';
import GET_USERS from '../../graphql/usersQuery';
import GET_TOTAL_USERS from '../../graphql/usersTotalQuery';
import GET_CUSTOM_USERS from '../../graphql/usersCustomQuery';
import Loader from '../Loader';
import intl from 'react-intl-universal';

function UserEditor(props) {
  const { loading: totaluserloading, data: totaluserdata, error: totalusererror } = useQuery(GET_TOTAL_USERS)
  const [state, setState] = useState({
    skip: 0,
    take: 9
  })
  const skip = state.skip
  const take = state.take
  const { loading: customuserloading, data: customuserdata, error: customusererror } = useQuery(GET_CUSTOM_USERS, { variables: { skip, take } })
  // const users = useQuery(GET_USERS);
  const [getUsers, getUsersQuery] = useLazyQuery(GET_USERS);
  const [isAddingUsers, toggleAddNewUser] = useState(false)
  const [count, setCount] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [boundaryRange, setBoundaryRange] = useState(1)
  const [siblingRange, setSiblingRange] = useState(1)
  const [showEllipsis, setShowEllipis] = useState(true)
  const [showFirstAndLastNav, setShowFirstAndLastNav] = useState(true)
  const [showPreviousAndNextNav, setShowPreviousAndNextNav] = useState(true)
  const [hostdata, setHostdata] = useState()


  useEffect(() => {
    if (totaluserdata) {
      setCount(totaluserdata && totaluserdata.userQueries && totaluserdata.userQueries.getUsersTotal && totaluserdata.userQueries.getUsersTotal.count)
    }
    if (customuserdata) {
      setHostdata(customuserdata && customuserdata.userQueries && customuserdata.userQueries && customuserdata.userQueries.users && customuserdata.userQueries.users)
    }
  }, [customuserdata, totaluserdata])

  const handleToggleAddOffice = () => toggleAddNewUser(!isAddingUsers);

  if (customusererror) {
    return (
      <Grid>
        <Grid.Row>
          <Header as="h3">An Error has occured. Please try again.</Header>
        </Grid.Row>
        <Grid.Row>
          <Button
            onClick={() => getUsers()}
            loading={getUsersQuery.loading}
            disabled={getUsersQuery.loading}
            primary
            icon>Retry <Icon name="redo" />
          </Button>
        </Grid.Row>
      </Grid>
    )
  }

  const handlePaginationChange = async (e, { activePage }) => {
    setActivePage(activePage)
    setState({
      take: parseInt(9),
      skip: parseInt(activePage * 9 - 9)
    })
  }


  return (
    customuserloading ?
      <Loader />
      :
      <>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column width={16}>
              {
                !isAddingUsers || !customuserdata
                  ?
                  <Segment.Group className="adminToolBar">
                    <Segment>
                      <Button onClick={handleToggleAddOffice} primary>
                        <Icon name="add user" /> {intl.get('admin.add_new_user')}
                      </Button>
                    </Segment>
                  </Segment.Group>
                  :
                  <Segment.Group className="addAdminItems">
                    <Segment>
                      <Grid>
                        <Grid.Column width={16}>
                          <Header as="h3" floated="left">{intl.get("admin.new_user")}</Header>
                          <Button icon="x" onClick={handleToggleAddOffice} color="red" floated="right" size="small" />
                        </Grid.Column>
                      </Grid>
                    </Segment>
                    <NewUser cancelNewUser={() => toggleAddNewUser(false)} />
                  </Segment.Group>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Form>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{intl.get("admin.users_fields.name")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.users_fields.email")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.users_fields.user_level")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.users_fields.position")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.users_fields.office")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.users_fields.location")}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {hostdata && hostdata.map(user =>
                <Table.Row key={user._id}>
                  <Table.Cell>{user.name}</Table.Cell>
                  <Table.Cell>{user.email}</Table.Cell>
                  <Table.Cell>{user.userLevel.titleMM}</Table.Cell>
                  <Table.Cell>{user.position}</Table.Cell>
                  <Table.Cell>{user.office ? <>{user.office.department || ''} {`, ${user.office.ministry}` || ''}</> : ''}</Table.Cell>
                  <Table.Cell>{user.location ? user.location.nameMM : ''}</Table.Cell>
                  <Table.Cell>
                    <Button as={Link} to={`/admin/user/${user._id}`}>
                      <span className="tgicon tgicon-edit"></span> {intl.get("button.edit")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          {
            Math.ceil(count && count / 9) <= 1 ? null : (<>
              <Pagination
                activePage={activePage}
                boundaryRange={1}
                siblingRange={1}
                onPageChange={handlePaginationChange}
                boundaryRange={boundaryRange}
                size='mini'
                siblingRange={siblingRange}
                ellipsisItem={showEllipsis ? undefined : null}
                firstItem={showFirstAndLastNav ? undefined : null}
                lastItem={showFirstAndLastNav ? undefined : null}
                prevItem={showPreviousAndNextNav ? undefined : null}
                nextItem={showPreviousAndNextNav ? undefined : null}
                totalPages={Math.ceil(count && count / 9)}
              />
            </>)
          }

        </Form>
      </>
  )
}

export default UserEditor;
