import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Button, Header, Modal, Icon, Grid, Input, Segment, Label } from 'semantic-ui-react'
import AddLayerCheckbox from './AddLayerCheckbox'
import LAYERS_QUERY_INPUT from '../../graphql/getLayersByInput'
import LAYERS_CUSTOM_QUERY from '../../graphql/layersCustomQuery'
import TOTAL_LAYERS_QUERY from '../../graphql/getTotalLayers'
import TOTAL_SEARCH_LAYERS from '../../graphql/getTotalLayersByInput'
import DEFAULT_COUNTS_QUERY from '../../graphql/getFilterTotals'
import GET_LAYERS_BY_NESTED_PROP from '../../graphql/getLayersByNestedProperties'
import { useDebounce } from '../customHooks/useDebounce'
import FilterAccordian from '../editor/FilterAccordian'
import moment from 'moment';
import intl from 'react-intl-universal';
import { LanguageContext } from '../../context/langProvider'

function AddLayers(props) {
	const [skip, setSkip] = useState(0)
	const [sskip, setSskip] = useState(0)
	const [uskip, setUskip] = useState(0)
	const [activePage, setActivePage] = useState(2)
	const [sactivePage, setsActivePage] = useState(2)
	const [uactivePage, setuActivePage] = useState(2)
	const [sortBy, setSortby] = useState("updatedAt")
	const [sortAscending, setSortAscending] = useState(false)
	const [count, setCount] = useState(0)
	const [upcount, setUpCount] = useState(0)
	const [updatedata, setUpdatedata] = useState([])
	const [rescount, setRescount] = useState(0)
	const [loading, setLoading] = useState(false)
	const [loadmore, setLoadmore] = useState(false)
	const [layers, setLayers] = useState([]);
	const [searchedlayers, setSearchedlayers] = useState([])
	const [offices, setOffices] = useState([])
	const [locations, setLocations] = useState([])
	const [levels, setLevels] = useState([])
	let [searchedvalue, setSearchedvalue] = useState("")
	let debouncedValue = useDebounce(searchedvalue, 220)
	const [query, setQuery] = useState({})
	const [filterby, setFilterby] = useState("")
	const languageContext = useContext(LanguageContext)
	const { loading: customlayersloading, data: customlayersdata, error: customlayerserror } = useQuery(LAYERS_CUSTOM_QUERY, { variables: { skip, take: 10, sortBy, sortAscending } })
	const { loading: default_count_loading, data: default_count_data, error: default_count_error } = useQuery(DEFAULT_COUNTS_QUERY)
	const { data: totallayers } = useQuery(TOTAL_LAYERS_QUERY)
	const { data: totalsearchlayers } = useQuery(TOTAL_SEARCH_LAYERS, {
		variables: {
			where: languageContext.language === 'my' ? {
				"nameMM": debouncedValue
			} : {
					"nameEN": debouncedValue
				}
		},
		skip: (searchedvalue === "" || debouncedValue <= 2)
	})
	const { loading: searchloading, data: searchresults } = useQuery(LAYERS_QUERY_INPUT, {
		variables: {
			skip: sskip,
			take: parseInt(10),
			sortBy,
			sortAscending,
			where: languageContext.language === 'my' ? {
				"nameMM": debouncedValue === "" || debouncedValue.length <= 2 ? null : debouncedValue
			} : {
					"nameEN": debouncedValue === "" || debouncedValue.length <= 2 ? null : debouncedValue
				}
		},
		skip: (searchedvalue === "" || debouncedValue <= 2)
	})
	const { loading: layers_by_child_loading, data: layers_by_child_data, error: layers_by_child_error } = useQuery(GET_LAYERS_BY_NESTED_PROP, {
		variables: {
			skip: uskip,
			take: Object.keys(query).length > 0 && parseInt(10),
			where: query
		},
		skip: Object.keys(query).length === 0
	})
	const { selectedIDs, setSelectedIDs, checkedid, uncheckedid, cbflag, layerflag, setCheckedid, setUncheckedid, removedlayerid, setCbflag, showAddLayersModal, setShowAddLayersModal, idslist, dataMultiverse } = props

	useEffect(() => {
		if (default_count_data) {
			const { offices, locations, levels } = default_count_data && default_count_data.layersQuery.getFilterTotals
			let hostoffices = []
			offices && offices.map(off => {
				return hostoffices.push({ name: off.department, count: off.count, active: false, id: off._id, level: off.level, ministry: off.ministry, department: off.department })
			})
			setOffices(hostoffices)
			let hostlocations = []
			locations && locations.map(loc => {
				return hostlocations.push({ name: loc.nameEN, count: loc.count, active: false, nameEN: loc.nameEN, nameMM: loc.nameMM, id: loc._id })
			})
			setLocations(hostlocations)
			let hostlevels = []
			levels && levels.map(lev => {
				return hostlevels.push({ name: lev.name, count: lev.count, active: false, locations: lev.locations })
			})
			setLevels(hostlevels)
		}
	}, [default_count_data])

	useEffect(() => {
		let data = layers_by_child_data
			&& layers_by_child_data.layersQuery
			&& layers_by_child_data.layersQuery.getLayers
		if (data === null && data === undefined && data.length === 0) {
			setUpdatedata([])
			setUpCount(0)
			setuActivePage(2)
		} else if (data && Object.keys(query).length !== 0) {
			const newUpdateData = [
				...new Map([...data]
					.map(l => [l['_id'], l]))
					.values()
			]
			setUpdatedata(newUpdateData)
			setLoadmore(false)
		}
	}, [layers_by_child_data])

	useEffect(() => {
		if (searchresults && searchresults.layersQuery && debouncedValue !== "") {
			let data = [...searchresults.layersQuery.getLayers]
			setSearchedlayers([...new Map(data.map(l =>
				[l['_id'], l])).values()])
			setLoadmore(false)
		}
		if (searchedvalue === "") {
			setSearchedlayers([])
			setRescount(0)
			setsActivePage(2)
		}
	}, [searchresults])

	useEffect(() => {
		if (customlayersdata && customlayersdata.layersQuery) {
			let data = [...layers, ...customlayersdata.layersQuery.getLayers]
			setLayers([...new Map(data.map(l =>
				[l['_id'], l])).values()]);
			setLoadmore(false)
		}
	}, [customlayersdata]);

	useEffect(() => {
		if (totallayers && totallayers.layersQuery) {
			setCount(totallayers.layersQuery.getLayersTotal.count)
		}
	}, [totallayers])

	useEffect(() => {
		if (totalsearchlayers && totalsearchlayers.layersQuery) {
			if (debouncedValue !== "" || debouncedValue !== null) {
				setRescount(parseInt(totalsearchlayers.layersQuery.getLayersTotal.count))
			}
		}
	}, [totalsearchlayers])

	useEffect(() => {
		if (debouncedValue !== "" && searchedvalue !== "") {
			setLoadmore(searchloading)
		}
		if ((debouncedValue === "" || debouncedValue === null) && Object.entries(query).length !== 0) {
			setLoadmore(layers_by_child_loading)
		}
		if ((debouncedValue === "" || debouncedValue === null) && Object.entries(query).length === 0) {
			setLoadmore(customlayersloading)
		}
		if (default_count_loading) {
			setLoading(default_count_loading)
		}
		setLoading((searchloading && searchedlayers.length === 0) || (Object.entries(query).length !== 0 && layers_by_child_loading && updatedata.length === 0) || (customlayersloading && layers.length === 0))
	}, [customlayersloading, searchedvalue, debouncedValue, searchloading, layers_by_child_loading, updatedata, default_count_loading])

	useEffect(() => {
		setSelectedIDs(idslist)
	}, [removedlayerid, layerflag, idslist])

	const isselected = (element) => element.active === true;
	const setDefaultLevels = () => {
		let hostlevels = levels
		hostlevels.map(lev => {
			lev.active = false
			return lev
		})
		setLevels(hostlevels)
	}
	const setDefaultLocations = () => {
		let hostlocations = locations
		hostlocations.map(loc => {
			loc.active = false
			return loc
		})
		setLocations(hostlocations)
	}
	const setDefaultOffices = () => {
		let hostoffices = offices
		hostoffices.map(off => {
			off.active = false
			return off
		})
		setOffices(hostoffices)
	}
	const removeFilterby = () => {
		setDefaultLevels()
		setDefaultLocations()
		setDefaultOffices()
		setQuery({})
		setUpdatedata([])
		setFilterby("")
	}
	const cleanupQuery = (arrval, strval) => {
		if (arrval.some(isselected) === false) {
			if (filterby === strval) {
				setQuery({})
				setUpdatedata([])
				setUpCount(0)
				setUskip(0)
				setuActivePage(2)
				setFilterby("")
			}
		}
	}
	const handleLevelSelect = async (level, key) => {
		setDefaultLocations()
		setDefaultOffices()
		let hostlevels = levels
		hostlevels[key] = hostlevels[key].active ? { name: level.name, count: level.count, active: false, locations: level.locations } : { name: level.name, count: level.count, active: true, locations: level.locations }
		setLevels(hostlevels)
		let querylocations = []
		let querycount = 0
		levels.map(level => {
			if (level.active) {
				return querylocations.push(...level.locations), querycount += level.count, setFilterby(intl.get("editor.layer_list.filtered_by_level"));
			}
		})
		setQuery({
			"location": { "$in": querylocations }
		})
		setUskip(0)
		setUpCount(querycount)
		cleanupQuery(levels, intl.get("editor.layer_list.filtered_by_level"))
	}
	const handleLocationSelect = (loc, key) => {
		setDefaultLevels()
		setDefaultOffices()
		let hostlocations = locations
		hostlocations[key] = hostlocations[key].active ? { name: loc.nameEN, count: loc.count, active: false, nameEN: loc.nameEN, nameMM: loc.nameMM, id: loc.id } : { name: loc.nameEN, count: loc.count, active: true, nameEN: loc.nameEN, nameMM: loc.nameMM, id: loc.id }
		setLocations(hostlocations)
		let querylocations = []
		let querycount = 0
		locations.map(loc => {
			if (loc.active) {
				return querylocations.push(loc.id), querycount += loc.count, setFilterby(intl.get("editor.layer_list.filtered_by_location"));
			}
		})
		setQuery({
			"location": { "$in": querylocations }
		})
		setUskip(0)
		setUpCount(querycount)
		cleanupQuery(locations, intl.get("editor.layer_list.filtered_by_location"))
	}
	const handleOfficeSelect = (off, key) => {
		setDefaultLocations()
		setDefaultLevels()
		let hostoffices = offices
		hostoffices[key] = hostoffices[key].active ? { name: off.department, count: off.count, active: false, id: off.id, level: off.level, ministry: off.ministry, department: off.department } : { name: off.department, count: off.count, active: true, id: off.id, level: off.level, ministry: off.ministry, department: off.department }
		let queryoffices = []
		let querycount = 0
		offices.map(off => {
			if (off.active) {
				return queryoffices.push(off.id), querycount += off.count, setFilterby(intl.get("editor.layer_list.filtered_by_office"));
			}
		})
		setQuery({
			"office": { "$in": queryoffices }
		})
		setUskip(0)
		setUpCount(querycount)
		cleanupQuery(offices, intl.get("editor.layer_list.filtered_by_office"))
	}

	const handleLoadMore = () => {
		setLoadmore(true)
		if ((debouncedValue === "" || debouncedValue === null) && Object.entries(query).length !== 0) {
			setLoading(layers_by_child_loading)
			if (updatedata) {
				if (parseInt(updatedata.length) !== parseInt(upcount)) {
					setuActivePage(prev => prev + 1)
					setUskip(uactivePage * 10 - 10)
				}
			}
		}
		if (debouncedValue !== "" && searchedvalue !== "") {
			if (parseInt(searchedlayers.length) !== parseInt(rescount)) {
				setsActivePage(prev => prev + 1)
				setSskip(sactivePage * 10 - 10)
			}
		}
		if ((debouncedValue === "" || debouncedValue === null) && Object.entries(query).length === 0) {
			if (parseInt(layers.length) !== parseInt(count)) {
				setActivePage(prev => prev + 1)
				setSkip(activePage * 10 - 10)
			}
		}
	}

	const renderLayerList = (players, pcount) => {
		return players.map((layer, index) => {
			if (players.length === index + 1) {
				return (
					<div key={index + 34524}>
						<AddLayerCheckbox
							key={layer._id}
							data={layer}
							checked={selectedIDs && selectedIDs[layer._id] === true}
							onChange={(e, data) => {
								handleCheckBoxClick(layer._id, data.checked)
							}} />
						{parseInt(players.length) !== parseInt(pcount) && parseInt(pcount) > 9 ? (
							<>{loadmore === true ? (
								<div style={{ zIndex: 10, textAlign: 'center' }}>
									<h5>{intl.get('viewer.loading')}</h5>
								</div>
							) : (<Button fluid secondary onClick={() => handleLoadMore(layers, count)} >
								{intl.get('viewer.load_more')}
								<Icon name='chevron down' style={{ margin: '0.3rem 0.2rem 0px 0.2rem' }} />
							</Button>)}</>
						) : <></>}
					</div>)
			} else {
				return (
					<AddLayerCheckbox
						key={layer._id}
						data={layer}
						checked={selectedIDs && selectedIDs[layer._id] === true}
						onChange={(e, data) => {
							handleCheckBoxClick(layer._id, data.checked)
						}} />
				)
			}
		})
	}

	const checkLayerLoaded = (layerID) => {
		return dataMultiverse && dataMultiverse.length && dataMultiverse.filter(
			uni => uni.key === layerID
		).length > 0
	}

	const renderSelectedLayerList = (players) => {
		return (players.length > 0 && selectedIDs
			? players
				.filter(layer => Object.keys(selectedIDs).includes(layer._id) && selectedIDs[layer._id])
				.map(layer =>
					<Segment key={"layer" + layer._id}>
						<i className="delete icon" style={{ float: 'right', cursor: 'pointer' }} onClick={(e) => handleCheckBoxClick(layer._id, false)}></i>
						<h4 className="primary-color-text">{languageContext.language === 'my' ? layer.nameMM : layer.nameEN}</h4>
						<p>{intl.get('viewer.info_type', { level: layer.location ? layer.location.level : '', type: layer.type })}</p>
						<p>{layer.location && layer.location.nameEN ? (intl.get('viewer.info_location', { location: layer.location.nameEN })) : ''}</p>
						<p>{intl.get('viewer.info_uploaded', { name: layer.uploadedBy ? 'uploaded by ' + layer.uploadedBy.email : '', date: moment(layer.updatedAt).format('LLL') })}</p>
						<p>{(layer.dateRange.start && layer.dateRange.end) ? intl.get('viewer.info_coverage', { from_date: moment(layer.dateRange.start).format('LL'), to_date: moment(layer.dateRange.start).format('LL') }) : ''}</p>
						{
							checkLayerLoaded(layer._id) ? null : <h4 className="primary-color-text">{intl.get('viewer.loading')}</h4>
						}
					</Segment>)
			: null)
	}

	const handleCheckBoxClick = (id, checked) => {
		setSelectedIDs({ ...selectedIDs, [id]: checked })
	}

	return (
		<Modal
			className="AddLayerModal"
			closeIcon
			trigger={
				<Button
					primary
					className="AddLayerButton"
					onClick={() => setShowAddLayersModal(!showAddLayersModal)}>
					<span className="tgicon tgicon-add-layer"></span> {intl.get('viewer.add_layer')}
				</Button>}
			closeOnEscape={false}
			closeOnDimmerClick={false}
			open={showAddLayersModal}
			onClose={() => setShowAddLayersModal(!showAddLayersModal)}>
			<><Modal.Header>{intl.get('viewer.add_layer_to_map')}</Modal.Header>
				<Modal.Content scrolling>
					<Modal.Description>
						<Grid columns={3} divided>
							<Grid.Row>
								<Grid.Column>
									<Input icon='search' placeholder='Search by LayerName' className="LayerSearchInput" style={{ width: '100%', marginBottom: '1rem' }} value={searchedvalue} onChange={(e) => { removeFilterby(); setSearchedvalue(e.target.value) }} />
									<h4><span className="tgicon tgicon-filter"></span> {intl.get("editor.layer_list.filter_layers")}</h4>
									<FilterAccordian
										title={intl.get("editor.layer_list.level")}
										list={levels}
										handleClick={handleLevelSelect}
									/>
									<FilterAccordian
										title={intl.get("editor.layer_list.layer_location")}
										list={locations}
										handleClick={handleLocationSelect}
									/>
									<FilterAccordian
										title={intl.get("editor.layer_list.layer_department")}
										list={offices}
										handleClick={handleOfficeSelect}
									/>
								</Grid.Column>
								<Grid.Column>
									<Grid.Column width={9} align="left" className="filteredValues">
										{filterby !== "" ? (
											<div>
												<h4>
													{filterby}:&nbsp;
                    								<Label as="a">
														{intl.get("editor.layer_list.remove_all_filters")} {upcount} <Icon name='delete' onClick={() => removeFilterby()} />
													</Label>
												</h4>
											</div>
										) : <></>}
										{
											levels.map((lev, key) => {
												if (lev.active) {
													return (
														<Label key={key} as='a' className="teal">
															{lev.name}
															<Icon name='delete' onClick={() => handleLevelSelect(lev, key)} />
														</Label>
													)
												}
											})
										}
										{
											locations.map((loc, key) => {
												if (loc.active) {
													return (
														<Label key={key} as='a' className="teal">
															{loc.name}
															<Icon name='delete' onClick={() => handleLocationSelect(loc, key)} />
														</Label>
													)
												}
											})
										}
										{
											offices.map((off, key) => {
												if (off.active) {
													return (
														<Label key={key} as='a' className="teal">
															{off.name}
															<Icon name='delete' onClick={() => handleOfficeSelect(off, key)} />
														</Label>
													)
												}
											})
										}
									</Grid.Column>
									{loading ? <h5>{intl.get('viewer.loading')}</h5> : (searchedlayers.length > 0 && searchedvalue !== "" ? renderLayerList(searchedlayers, rescount) : (
										updatedata && !!updatedata.length ? (
											renderLayerList(updatedata, upcount)
										) : (layers.length > 0 ? renderLayerList(layers, count) : <h5>{intl.get('viewer.loading')}</h5>))
									)}
								</Grid.Column>
								<Grid.Column>
									<Header className="primary-color-text">{intl.get('viewer.selected_layers')}</Header>
									<div className="SelectedLayersWrapper">
										{loading ? <></> : (
											searchedvalue.length > 0 && searchedvalue !== "" ? renderSelectedLayerList(searchedlayers) : (
												updatedata && !!updatedata.length ? (renderSelectedLayerList(updatedata)) : (layers.length > 0 ? (renderSelectedLayerList(layers)) : <></>)
											)
										)}
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button primary onClick={() => setShowAddLayersModal(!showAddLayersModal)}>
						{intl.get('viewer.add_layers_to_map')} <Icon name='chevron right' />
					</Button>
				</Modal.Actions>
			</>
		</Modal>
	);

}
export default AddLayers;