import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, Accordion, Icon, Divider, Button, Dropdown, Modal, Header } from 'semantic-ui-react';
import { mapAggregateVizTypes, getScaleFunc, getLegendMarker, defaultMapStylings } from '../../utils'
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom'
import moment from 'moment';
import { LanguageContext } from '../../context/langProvider'

function LayerCard(props) {
	console.log(props)
	const {
		groupBys,
		filterFunc,
		filters,
		layerID,
		mapAlpha,
		setMapAlpha,
		title,
		isLocked,
		lockedBy,
		nameEN,
		nameMM,
		location,
		type,
		uploadedBy,
		updatedAt,
		dateRange,
		user,
		currentLayerID,
		setCurrentLayerID,
		isThisItemChecked,
		setMapVizType,
		mapStyling,
		selectedIDs,
		setSelectedIDs,
		dataType } = props;
	const [isAccordionOpen, setAccordionOpen] = useState(true);
	const handleAccordionToggle = event => setAccordionOpen(!isAccordionOpen);
	const [legendGroups, setLegendGroups] = useState({});
	const [open, setOpen] = useState(false);

	const languageContext = useContext(LanguageContext)

	let history = useHistory()
	useEffect(() => {
		// console.log("Updating Groupbys for Legend");
		setLegendGroups(groupBys);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters])


	const toggleChartFilters = (event, data) => {
		if (data.checked) {
			setCurrentLayerID(layerID)
		} else {
			setCurrentLayerID(null)
		}
	}

	const toggleShowHideLayers = (value) => {
		setMapAlpha(+value)
	}

	const handleRemove = () => {
		setSelectedIDs({ ...selectedIDs, [layerID]: false })
	}

	const checkLegendItem = (columnKey, filterObject) => {
		console.log(columnKey, filterObject);
		filterFunc(columnKey, filterObject, layerID);
	}

	const style = {
		color: getScaleFunc(mapStyling, 'COLOR', true),
		weight: mapStyling.weight,
		radius: getScaleFunc(mapStyling, 'RADIUS', true),
		symbol: getScaleFunc(mapStyling, 'SYMBOL', true),
		fillMultiplier: mapStyling.fillMultiplier
	}
	// console.log(`LayerCard`)
	// console.log(isLocked)

	return (
		<>
			<Accordion className="LayerCard show">
				<Accordion.Title onClick={handleAccordionToggle} className={mapAlpha > 0 ? 'layerShow' : 'layerHide'}>
					{mapAlpha > 0 ? <span className="tgicon tgicon-show-layer" onClick={(event) => setMapAlpha(+0)}></span> : <span className="tgicon tgicon-hide-layer" onClick={(event) => setMapAlpha(+100)}></span>}
					<span className="layerName">{languageContext.language === 'my' ? nameMM : nameEN}</span>
					<Dropdown icon="ellipsis vertical">
						<Dropdown.Menu id="popupmenu">
							<Dropdown.Item onClick={() => setOpen(true)}><span class="tgicon tgicon-info-alt"></span> {intl.get('viewer.layer_info')}</Dropdown.Item>
							<>
								{
									isLocked && lockedBy !== user.id ? (<Dropdown.Item><Icon name="lock" /> edit layer style is locked</Dropdown.Item>) : (<Dropdown.Item onClick={() => history.push(`/editor/visualize/${layerID}`)}><span class="tgicon tgicon-map-style"></span> {intl.get('viewer.edit_layer_style')}</Dropdown.Item>)
								}
							</>

							{mapAlpha > 0 ? <Dropdown.Item onClick={(event) => setMapAlpha(+0)}><span class="tgicon tgicon-hide-layer"></span> {intl.get('viewer.hide_layer')}</Dropdown.Item> : <Dropdown.Item onClick={(event) => setMapAlpha(+100)}><span class="tgicon tgicon-show-layer"></span> {intl.get('viewer.show_layer')}</Dropdown.Item>}
							<Dropdown.Item onClick={handleRemove}><span class="tgicon tgicon-remove-layer-alt"></span> {intl.get('viewer.remove_layer')}</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Accordion.Title>
				<Accordion.Content active={isAccordionOpen}>
					<div className="layerControls">
						<Checkbox toggle label={intl.get('viewer.filter_charts')} checked={currentLayerID === layerID} onChange={toggleChartFilters} />
						{/* <Checkbox toggle label="Data Table" /> */}
						{dataType === 'POINT'
							? <Button.Group>
								{mapAggregateVizTypes.map((item, i) => {
									return <Button className="buttonGroup" key={'layerCardVizTypeButton' + i} onClick={() => setMapVizType(item)}>{item}</Button>
								})}
							</Button.Group>
							: null
						}
						<div className="opactiySlider">
							<label>{intl.get("viewer.opacity")}</label>
							<input type="range" min="0" max="100" value={mapAlpha ? mapAlpha : defaultMapStylings.fillMultiplier} className="rangeSlider"
								onChange={(event) => setMapAlpha(+event.target.value)} />
						</div>
					</div>
					<Divider />
					<div className="mapLegend">
						{
							legendGroups.length > 0
								? (() => {
									// console.log(legendGroups, style);
									return legendGroups.map((legendGroup, i) =>
										<>
											{legendGroup[0].title}
											<div key={i}>
												{
													legendGroup.map((item, j) => {
														// console.log(dataType,item.groupBy,style.color(item.key),style.symbol(item.key),style.radius(item.key))
														return <div key={i + "Sub" + j}>
															<Checkbox
																label={""}
																checked={isThisItemChecked(item)}
																onChange={() => checkLegendItem(item.groupBy, item.key, false)}
															/>
															{getLegendMarker(item.key, style, 20, dataType)}
															<span className="legendLabel">{item.key} </span>
														</div>
													})
												}
											</div>
										</>
									)
								})()
								: null
						}
					</div>
				</Accordion.Content>
			</Accordion>
			<Modal
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				open={open}
			>
				<Modal.Header>Layer Information</Modal.Header>
				<Modal.Content>
					<h4 className="primary-color-text">{languageContext.language === 'my' ? nameMM : nameEN}</h4>
					<p>{intl.get('viewer.info_type', { level: location.level ? location.level : '', type: type })}</p>
					<p>{location && location.nameEN ? (intl.get('viewer.info_location', { location: location.nameEN })) : ''}</p>
					<p>{intl.get('viewer.info_uploaded', { name: uploadedBy ? uploadedBy.email : '', date: moment(updatedAt).format('LLL') })}</p>
					<p>{(dateRange.start && dateRange.end) ? intl.get('viewer.info_coverage', { from_date: moment(dateRange.start).format('LL'), to_date: moment(dateRange.start).format('LL') }) : ''}</p>
				</Modal.Content>
				<Modal.Actions>
					<Button
						content="Close"
						labelPosition='right'
						icon='checkmark'
						onClick={() => setOpen(false)}
						positive
					/>
				</Modal.Actions>
			</Modal>
		</>
	);

}

export default LayerCard;
