import gql from 'graphql-tag';

export default gql`
  query getOfficeById($id: ID!){
    commonQueries{
      getOfficeById(id:$id){
        _id
        level
        ministry
        department
        location{
          _id
          nameMM
          nameEN
        }
      }
    }
  }
`