import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
export default function NoMatch(props){
  return(
    <div className="NoMatch">
      <Layout>
        <h1>404 Not Found</h1>
        <Link to="/">Return Home</Link>
      </Layout>
    </div>
  );
}