import gql from 'graphql-tag';

export default gql`
  query {
    commonQueries{
      getLayersAccesses{
        _id
        user
        office
        layer
        accessType
        accessDates{
          start
          end
        }
      }
    }
  }
`