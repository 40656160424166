import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/auth';
import { LocalizationContext } from '../context/localization';
import { Button, Form, Grid, Reveal } from 'semantic-ui-react';
import axios from 'axios';
import Layout from '../components/Layout';

import '../styles/OTPVerificationPage.scss';

import PrimaryLogo from '../images/PrimaryLogo.png';

function OTPVerificationPage(props) {
  const context = useContext(AuthContext);
  const { locale } = useContext(LocalizationContext);
  const [loading, setLoading] = useState(true);
  const { otpRequestToken, isVerifyingOTP } = context;

  const [errors, setErrors] = useState({
    isError: false,
    message: ''
  });

  const [values, setValues] = useState({ otpCode: '' });
  const [otpVerificationToken, setotpVerificationToken] = useState('');

  // const { user } = context;

  if (!isVerifyingOTP) {
    props.history.push('/');
  }

  useEffect(() => {
    const otpEndpoint = process.env.NODE_ENV === 'development'
      ? 'http://localhost:3001/auth/sendOTP'
      : '/auth/sendOTP';
    const config = { headers: { 'Content-Type': 'application/json', 'Accept-Language': locale } };
    axios.post(otpEndpoint, { token: otpRequestToken }, config)
      .then(function (response) {
        console.log(response);
        setotpVerificationToken(response.data.otpVerificationToken);
        setLoading(false);
      })
      .catch(function (error) {
        const { message } = error.response.data
        setLoading(false);
        setErrors({
          isError: true,
          message: `Error: ${message}`
        })
        setTimeout(() => {
          setErrors({
            isError: false,
            message: ''
          })
        }, 3000);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onChange = (event) =>
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

  const onSubmit = () => {
    const verifyEndpoint = process.env.NODE_ENV === 'development'
      ? 'http://localhost:3001/auth/verifyOTP'
      : '/auth/verifyOTP';
    const config = { headers: { 'Content-Type': 'application/json', 'Accept-Language': locale } };
    setLoading(true);
    axios.post(verifyEndpoint, { ...values, token: otpVerificationToken }, config)
      .then(function (response) {
        setLoading(false)
        const { user } = response.data;
        console.log(user);
        context.login(user,
          () => props.history.push('/')
        )
      })
      .catch(function (error) {
        const { message } = error.response.data
        setLoading(false);
        setErrors({
          isError: true,
          message: `Error: ${message}`
        })
        setTimeout(() => {
          setErrors({
            isError: false,
            message: ''
          })
        }, 3000);
      });
  };


  return (
    <Layout className="OTPVerificationPage">
      <div className="OTPForm">
        <img src={PrimaryLogo} alt="TGIST Primary Logo" height="200px" />
        <Form onSubmit={onSubmit} noValidate className={loading ? 'loading' : ''}>
          <Form.Input placeholder="Verify One Time Password"
            name="otpCode"
            type="text"
            value={values.otpCode}
            onChange={onChange}
          />
          <Button type="submit" primary>Verify</Button>
        </Form>
        <Grid>
          <Grid.Column width={16}>
            <Reveal active={errors.isError ? true : false}>
              <Reveal.Content as="label" color="red">{errors.message}</Reveal.Content>
            </Reveal>
          </Grid.Column>
        </Grid>
      </div>
    </Layout>
  );
}

export default OTPVerificationPage;