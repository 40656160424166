import React, { useState } from 'react';
import { Accordion, List, Label, Icon } from 'semantic-ui-react'

function FilterAccordian(props) {
    const { title, list, handleClick } = props;
    const [isAccordionOpen, setAccordionOpen] = useState(false);
    const handleAccordionToggle = () => setAccordionOpen(!isAccordionOpen);
    return (
        <Accordion>
            <Accordion.Title active={isAccordionOpen} onClick={handleAccordionToggle} className="tealColor">
                {title}
                <Icon name='dropdown' />
            </Accordion.Title>
            <Accordion.Content active={isAccordionOpen} style={{height: 'auto'}}>
                <List selection className="filterList">
                    {list.map((item, key) => {
                        return (
                            <List.Item key={key} active={item.active} onClick={() => handleClick(item, key)}>
                                {item.name}
                                {item.active ?
                                    <Icon name='delete' />
                                    :
                                    <Label circular>
                                        {item.count}
                                    </Label>
                                }
                            </List.Item>
                        )
                    })}
                </List>
            </Accordion.Content>
        </Accordion>
    );
}

export default FilterAccordian;
