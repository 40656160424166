import React, { useContext } from 'react';
import { AuthProvider } from './context/auth';
import { LanguageContext } from './context/langProvider'
import Routes from './components/Routes';
import intl from 'react-intl-universal';

// Styles
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import './styles/Overwrite.scss';

function App() {

  const locales = {
    "en-US": require('./locales/en-US.json'),
    "my": require('./locales/my.json'),
  };
  const languageContext = useContext(LanguageContext)
  intl.init({
    currentLocale: languageContext.language,
    locales,
  });

  return (
    <div className="App">
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </div>
  );
}

export default App;
