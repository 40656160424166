import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button, Icon } from 'semantic-ui-react'
import breadCrumbSteps from './BreadcrumbSteps';
import intl from 'react-intl-universal';

function StepNavigation({ step, updateLayer, layerID, layer, setSharedStatus}) {
  const stepData = breadCrumbSteps.filter(s => s.stepIndex === step).length
    ? breadCrumbSteps.filter(s => s.stepIndex === step)[0]
    : breadCrumbSteps[0];
  const stepNum = stepData.stepNumber;
  const isFirst = !(+stepNum > 1);
  const isLast = !(+stepNum < 4);
  const nextStep = isLast ? null : breadCrumbSteps[stepNum].stepIndex;
  const prevStep = isFirst ? null : breadCrumbSteps[stepNum-2].stepIndex;

  const doNothing = () => {
    setSharedStatus({
      nameEN: layer.nameEN !== undefined,
      nameMM: layer.nameMM !== undefined,
      office: layer.office !== undefined,
      location: layer.location !== undefined,
    });
  };

  return (
    <Grid.Row className="upload-navigation" columns="equal">
      {isFirst ? false :
        <Grid.Column textAlign="left">
          <Link to={`/editor/${prevStep}`+(layerID ? (`/`+layerID) : ``)} onClick={updateLayer}>
            <Button secondary>
              <Icon name="chevron left"/> {intl.get('button.back')}
            </Button>
          </Link>
        </Grid.Column>}
      {isLast ? false :
        <Grid.Column textAlign="right">
          <Link to={((layer && layer.nameEN !== undefined && layer.nameEN !== null) || (layer && layer.nameMM !== undefined && layer.nameMM !== null)) && layer.office !== undefined &&  layer.location !== undefined ? `/editor/${nextStep}`+(layerID ? (`/`+layerID) : ``): '#'} onClick={((layer && layer.nameEN !== undefined && layer.nameEN !== null) || (layer && layer.nameMM !== undefined && layer.nameMM !== null)) && layer.office !== undefined &&  layer.location !== undefined ? updateLayer: () => doNothing()} >
            <Button primary>
              {intl.get('button.continue')} <Icon name="chevron right"/>
            </Button>
          </Link>
        </Grid.Column>
      }
    </Grid.Row>
  )
}

export default StepNavigation;