import React, { useReducer, createContext } from 'react';

const initialState = {
  locale: 'en-US'
};

const LocalizationContext = createContext({
  locale: 'en-US'
});

function localizationReducer(state, action) {
  switch (action.type) {
    case 'LOCALIZATION':
      return {
        ...state,
        locale: action.payload
      };
    default: 
      return state;
  }
}

function LocalizationProvider(props) {
  const [state, dispatch] = useReducer(localizationReducer, initialState);

  function updateLocalization(localizationData, callback) {
    if (callback && typeof callback === "function")  callback();
    if (localizationData) {
      dispatch({
        type: 'LOCALIZATION',
        payload: localizationData
      });
      if (callback && typeof callback === "function") {
        callback();
      };
    }
  }

  return (
    <LocalizationContext.Provider
      value={{
        locale: state.locale,
        updateLocalization
      }}
      {...props}
    />
  );
}

export { LocalizationContext, LocalizationProvider };
