import gql from 'graphql-tag';

export default gql`
query officesCustomQuery($skip: Int, $take: Int){
  commonQueries{
    getOffices(skip:$skip, take:$take){
        _id
        level
        ministry
        department
        location{
          _id
          nameMM
          nameEN
        }
        parent{
          _id
          nameEN
          nameMM
        }
      }
  }
}
`
