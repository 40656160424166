import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Grid, Segment, Form, Message, Icon } from 'semantic-ui-react';
import Loader from '../Loader';
import ADD_LOCATION from '../../graphql/createUpdateLocation';
import GET_LOCATIONS from '../../graphql/getLocations';
import { useForm, shouldLogState as SHOULD_LOG_STATE, validate } from '../../utils';
import LocationFormFields from './LocationFormFields';
import intl from 'react-intl-universal';

function NewLocation(props) {
  // const { locations, cancelNewLocation } = props;
  const { cancelNewLocation } = props;
  const initialState = { level: '', nameMM: '', nameEN: '', placeCode: '', lat: '', lon: '', }
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [addLocation] = useMutation(ADD_LOCATION, {
    onCompleted: () => {
      setIsSubmitting(false);
      overrideValues(initialState);
      cancelNewLocation();
    },
    errorPolicy: 'all',
    onError: (err) => {
      console.log(err);
    }
  })

  const { onChange, onSubmit, overrideValues, values } = useForm((newLocation) => {
    console.log(newLocation);
    const validation = validate(newLocation);
    if (validation.isValid) {
      setIsSubmitting(true);
      const locationInput = {
        ...newLocation,
        center: {
          lat: parseFloat(newLocation.lat),
          lon: parseFloat(newLocation.lon)
        }
      }
      delete locationInput.lat;
      delete locationInput.lon;

      console.log(locationInput);

      addLocation({
        variables: { locationInput },
        refetchQueries: [{ query: GET_LOCATIONS }],
      });
    }
    else {
      setErrors(validation.errors);
      setIsSubmitting(false);
    }
  }, initialState, SHOULD_LOG_STATE)

  return (
    isSubmitting
      ?
      <Loader />
      :
      <>
        <Segment>
          <Form onSubmit={onSubmit} noValidate>
            <Grid centered columns="equal">
              <LocationFormFields values={values} errors={errors} onChange={onChange} />
              <Grid.Row>
                <Grid.Column textAlign="right">
                  <Form.Field>
                    <Button type="submit" onClick={onSubmit} primary disabled={isSubmitting}>
                      <Icon name="checkmark" /> {intl.get("admin.add_location")}
                    </Button>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              {Object.keys(errors).length === 0 && errors.constructor === Object ? false :
                <Grid.Row>
                  <Grid.Column textAlign="left">
                    <Message error icon="warning sign" content={<ul>
                      {Object.keys(errors).map(key => errors[key].map((error, index) => <li key={index}>{error}</li>))}
                    </ul>} />
                  </Grid.Column>
                </Grid.Row>
              }
            </Grid>
          </Form>
        </Segment>
      </>
  )
};

export default NewLocation;