import React from "react";
import { Redirect } from "react-router-dom";
import { Grid, Header, Button } from "semantic-ui-react";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import UserEditor from "../components/admin/UserEditor";
import LocationEditor from "../components/admin/LocationEditor";
import GroupEditor from "../components/admin/GroupEditor";
import Analytics from "../components/admin/Analytics";
import "../styles/AdminPage.scss";
import intl from "react-intl-universal";

const AdminRouteRenderer = ({ adminroute, user }) => {
  switch (adminroute) {
    case "users":
      return <UserEditor user={user} />;
    case "groups":
      return <GroupEditor user={user} />;
    case "locations":
      return <LocationEditor user={user} />;
    case "analytics":
      return <Analytics user={user} />;
    default:
      return <Redirect to="/admin/users" />;
  }
};

export default function AdminPage(props) {
  const { adminroute } = props.match.params;
  const { user } = props;
  const leftRail = [
    {
      path: "users",
      text: intl.get("admin.users"),
      icon: "tgicon-user"
    },
    {
      path: "groups",
      text: intl.get("admin.groups"),
      icon: "tgicon-user-group"
    },
    {
      path: "locations",
      text: intl.get("admin.locations"),
      icon: "tgicon-location-pin"
    },
    {
      path: "analytics",
      text: intl.get("admin.analytics"),
      icon: "tgicon-analytic-alt"
    }
  ];

  return (
    <Layout className="AdminPage">
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h2" textAlign="left">
              {intl.get("admin.user_adminstrator")}
            </Header>
          </Grid.Column>
          {/* <Grid.Column width={4}>
          <Button fluid primary>Download Usage Data</Button>
        </Grid.Column> */}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} align="left">
            <Button.Group className="adminNavigation">
              {leftRail.map((item, index) => (
                <Button key={index} active={adminroute === item.path}>
                  <Link to={`/admin/${item.path}`}>
                    <span className={"tgicon " + item.icon}></span> {item.text}
                  </Link>
                </Button>
              ))}
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <AdminRouteRenderer adminroute={adminroute} user={user} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  );
}
