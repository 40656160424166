import gql from 'graphql-tag';

export default gql`
query totalOfficeQuery{
    commonQueries{
        getOfficesTotal{
          count
        }
    }
}
`