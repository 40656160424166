import validate from 'validate.js';

const constraints = {
  // mainly groups
  level: {
    length: {
      minimum: 1,
      message: 'is required.'
    },
  },
  ministry: {
    length: {
      minimum: 1,
      message: 'is required.'
    }
  },
  department: {
    length: {
      minimum: 1,
      message: 'is required.'
    }
  },
  office: {
    length: {
      minimum: 1,
      message: 'is required.'
    }
  },
  location: {
    length: {
      minimum: 1,
      message: 'is required.'
    }
  },
  password: {
    length: {
      minimum: 6,
      message: 'is required.'
    }
  },
  // mainly locations
  lat: {
    numericality: {
      greaterThanOrEqualTo: -85.05112878,
      lessThanOrEqualTo: 85.05112878,
      message: 'should be greater than -85.05112878 and less than than 85.05112878'
    }
  },
  lon: {
    numericality: {
      greaterThan: -180,
      lessThanOrEqualTo: 180,
      message: 'should be greater than -180 and less than 180'
    }
  },
  parent: {
    length: {
      minimum: 1,
      message: 'is required.'
    }
  },
  nameMM: {
    length: {
      minimum: 1,
      message: 'is required.'
    }
  },
  nameEN: {
    length: {
      minimum: 1,
      message: 'is required.'
    }
  },
  placeCode: {
    length: {
      minimum: 1,
      message: 'is required.'
    }
  },
  // mainly for users
  email: {
    email: true
  },
  phone: {
    format:{
      pattern: /^\d+$/,
      message: 'is not a valid phone number'
    },
    length: {
      minimum: 5,
      max: 7,
      message: 'is required.'
    }
  },
  name: {
    length: {
      minimum: 1,
      message: 'is required.'
    }
  },
  position: {
    length: {
      minimum: 1,
      message: 'is required.'
    }
  },
  rank: {
    length: {
      minimum: 1,
      message: 'is required.'
    }
  },
  userLevel: {
    length: {
      minimum: 1,
      messsage: 'is required'
    }
  }
}

export default data => {
  return {
    errors: validate({ ...data }, constraints) || false,
    isValid: validate({ ...data }, constraints) === undefined ? true : false
  }
}