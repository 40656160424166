import React, {useState,useEffect} from 'react';
import { Input, Form, Dropdown, Button, Grid } from 'semantic-ui-react'
import intl from 'react-intl-universal';
import { SwatchesPicker } from 'react-color';
import {extent} from 'd3-array';


function DataCleaningForColumnComponent(props) {
  const {countsObj, valueChangeHandler, field} = props;
  console.log(props);

  const [valuesTracker,setValuesTracker] = useState({});

  const trackValue = (oldValue,newValue) => {
    setValuesTracker({...valuesTracker, [oldValue]: newValue});
  }

  let returnJSX = null;

  returnJSX = <div key={"dataCleaning"+field._id}>

  <br/>
  <br/>
  <Grid.Row>
    <h4> Clean Data for {field.nameEN}</h4>
  </Grid.Row>
  <br/>
  <Grid.Row>
    
    {
      Object.keys(countsObj).map((oldValue,i) => {
        return <Form.Group key={'bin'+i}>
          <Form.Field width={4}>
            {oldValue}
          </Form.Field>
          <Form.Field width={1}>
            <span style={
                {
                  backgroundColor: "#ddd",
                  paddingLeft: "4px",
                  paddingRight: "4px"
                }
              }>
              {countsObj[oldValue].length} 
            </span>
          </Form.Field>
          <Form.Field>
            <i aria-hidden="true" className="arrow right icon"></i>
          </Form.Field>
          <Form.Field>
            <Input 
              placeholder={oldValue}
              onChange={(event, { value }) => trackValue(oldValue,value)}
            />
          </Form.Field>
        </Form.Group>
      })
    }
    <button className="ui icon button"
      onClick={() => valueChangeHandler(field,valuesTracker,countsObj)}
    ><i aria-hidden="true" className="exchange icon"></i> Change Values</button>
  </Grid.Row>



  {/* <br/>
  <br/>
  <Grid.Row>
    <h4> Clean Data for {field.nameEN}</h4>
  </Grid.Row>
  <br/>
  <Grid.Row>
    <Form.Field>
      <label>{intl.get('editor.upload.column_name_en')}</label>
      <Input 
        value={field.nameEN}
        onChange={(event, { value }) => handleFieldChange('nameEN')(field._id,value)} />
    </Form.Field>
    <Form.Field>
      <label>{intl.get('editor.upload.column_name_mm')}</label>
      <Input 
        value={field.nameMM}
        onChange={(event, { value }) => handleFieldChange('nameMM')(field._id,value)} />
    </Form.Field>
    <Form.Field>
      <label>{intl.get('editor.upload.column_source')}</label>
      <Input 
        value={field.source ? field.source : ""}
        onChange={(event, { value }) => handleFieldChange('source')(field._id,value)} />
    </Form.Field>
    <Form.Field>
      <label>{intl.get('editor.upload.column_unit')}</label>
      <Input 
        value={field.units ? field.units : ""}
        onChange={(event, { value }) => handleFieldChange('units')(field._id,value)} />
    </Form.Field>
  </Grid.Row> */}
  </div>


  return (
    returnJSX
  )

}

export default DataCleaningForColumnComponent;
