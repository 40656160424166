import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import { AuthContext } from '../context/auth';
import { SocketContext } from '../context/socketProvider'
import { refreshToken, getToken } from '../utils';
import { Container, Menu, Dropdown } from 'semantic-ui-react';
import StickyTimer from './StickyTimer';
import LocaleSwitcher from './LocaleSwitcher';
import { getRole } from '../utils'
import intl from 'react-intl-universal';

import SecondaryLogo from '../images/SecondaryLogo_Light.svg';

export default props => {
  const socketContext = useContext(SocketContext)
  const userContext = useContext(AuthContext);
  const { socket } = socketContext;
  const { user } = props;
  const [notification, setNotification] = useState({
    color: 'white',
    message: {},
  });
  const [token, setToken] = useState(getToken('expressGraphQLAuthRefresh'));
  const [roleLevel, setRoleLevel] = useState(0)
  const handleSetToken = () => {
    const token = getToken('expressGraphQLAuthRefresh');
    const newToken = refreshToken(token);
    newToken.then(data => setToken(data));
  };

  const handleLogout = () => {
    userContext.logout();
    props.history.push('/login');
  };

  const getUri = props.location.pathname.split("/");
  const currentPath = getUri[1];
  const handleSocketReconnect = async () => {
    await socketContext.externalRefresh()
  }
  
  useEffect(() => {
    // console.log(`socket.readyState : ${socket.readyState}`)
    // console.log(`socket.OPEN : ${socket.OPEN}`)
    // if (socket == null || socket === undefined) {
    //   handleSocketReconnect()
    // }
    // if (socket && socket.readyState !== socket.OPEN) {
    //   handleSocketReconnect()
    // }
    // if (socket && socket.readyState !== 1) {
    //   handleSocketReconnect()
    // }
    // if (socket && socket.OPEN !== 1) {
    //   handleSocketReconnect()
    // }
    // if (!socket) {
    //   handleSocketReconnect()
    // }
    if (!socket || (socket == null || socket === undefined) || (socket && socket.readyState !== socket.OPEN) || (socket && socket.readyState !== 1)) {
      handleSocketReconnect()
    }
  }, [socket, token])

  if (socket.readyState !== socket.OPEN) {
    handleSocketReconnect()
  }

  useEffect(() => {
    if (user) {
      let res = getRole(user.userLevel.priority, user.userLevel.accessType)
      setRoleLevel(parseInt(res))
    }
  }, [user])
  // console.log("start navbar socket")
  console.log(socket.readyState)
  console.log(socket.OPEN)
  // console.log("end navbar socket")
  // console.log(user)
  // console.log(roleLevel)
  return (
    <div
      className="HeaderNav"
      style={
        props.location.pathname === '/login' ||
          props.location.pathname === '/otp-verification'
          ? { display: 'none' }
          : {}
      }
    >
      <Menu>
        <Container>
          <Menu.Item className="Logo">
            <Link to="/">
              <img src={SecondaryLogo} alt="TGIST Logo" height="50px" />
            </Link>
          </Menu.Item>
          {user ? (
            <Menu.Menu position="left">
              <Menu.Item active={currentPath === 'viewer'}>
                <Link to="/viewer">
                  <span className="tgicon tgicon-viewer"></span>
                  {intl.get('index.viewer.short')}
                </Link>
              </Menu.Item>
              {roleLevel > 1 ? (
                <Menu.Item active={currentPath === 'editor'}>
                  <Link to="/editor">
                    <span className="tgicon tgicon-editor"></span>
                    {intl.get('index.editor.short')}
                  </Link>
                </Menu.Item>
              ) : (
                  false
                )}
              {roleLevel > 3 ? (
                <Menu.Item active={currentPath === 'admin'}>
                  <Link to="/admin/users">
                    <span className="tgicon tgicon-administration"></span>
                    {intl.get('admin.menu')}
                  </Link>
                </Menu.Item>
              ) : (
                  false
                )}
            </Menu.Menu>
          ) : (
              false
            )}
          {user ? (
            <Menu.Menu position="right">
              <Menu.Item>
                <Dropdown
                  icon="bell"
                  style={{ color: `${notification.color}` }}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      {notification.message &&
                        notification.message.assignedTo &&
                        notification.message.assignedTo !== ''
                        ? notification.message.assignedTo
                        : null}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
              <Menu.Item>
                <span
                  className="tgicon tgicon-user white"
                  style={{ top: '-2px' }}
                ></span>
                <Dropdown
                  text={user.name ? user.name : user.email}
                  closeOnChange={false}
                  closeOnBlur={true}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item fitted="true">
                      <LocaleSwitcher />
                    </Dropdown.Item>
                    {user ? (
                      <Dropdown.Item fitted="true">
                        <LogoutButton
                          handleLogout={handleLogout}
                          history={props.history}
                        />
                      </Dropdown.Item>
                    ) : (
                        false
                      )}
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
            </Menu.Menu>
          ) : (
              false
            )}
        </Container>
      </Menu>
      {!!token ? (
        <StickyTimer
          history={props.history}
          token={token}
          setToken={handleSetToken}
        />
      ) : (
          false
        )}
    </div>
  );
};
