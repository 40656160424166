import React, { useState, useMemo } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Header, Icon, Segment, Modal, Message } from 'semantic-ui-react';
import GET_SINGLE_OFFICE from '../graphql/singleOfficeQuery';
import GET_OFFICES from '../graphql/getOffices';
import GET_LOCATIONS from '../graphql/getLocations';
import UPDATE_OFFICE from '../graphql/createUpdateOffice';
import DELETE_OFFICE from '../graphql/deleteGroup';
import { useForm, shouldLogState as SHOULD_LOG_STATE, validate } from '../utils';

import Loader from '../components/Loader';
import Layout from '../components/Layout';
import GroupFormFields from '../components/admin/GroupFormFields';
import intl from 'react-intl-universal';

function GroupEditorPage(props) {
  const { history } = props;
  const { id } = props.match.params;
  const { loading, data } = useQuery(GET_SINGLE_OFFICE, { variables: { id } });
  const [getLazySingleOffice] = useLazyQuery(GET_SINGLE_OFFICE);
  const [getLazyLocations] = useLazyQuery(GET_LOCATIONS);

  const [isUpdatingGroup, setIsUpdatingGroup] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);

  const initialState = {};

  const [updateOffice] = useMutation(UPDATE_OFFICE, {
    onCompleted: _ => {
      setIsUpdatingGroup(false);
    },
    onError: (error) => {
      setIsError(true);
      setErrorMessage(error);
    },
    errorPolicy: 'all',
  });

  const [deleteOffice, deleteOfficeMutation] = useMutation(DELETE_OFFICE, {
    onCompleted: (data) => {
      const { isSuccess } = data.commonMutations.deleteOffice;
      if (isSuccess) {
        console.log(data);
        history.push('/admin/groups');
      }
    },
    refetchQueries: [{ query: GET_OFFICES }],
    onError: (error) => {
      console.log(error);
      setIsError(true);
    },
    errorPolicy: 'all',
  });

  const { onSubmit, onChange, overrideValues, values } = useForm((values) => {
    const officeInput = { ...values, };


    const validation = validate(officeInput);
    console.log(validation);
    setErrorMessage({});
    if (validation.isValid) {
      setIsUpdatingGroup(true);
      updateOffice({
        variables: { officeInput },
        refetchQueries: [
          { query: GET_LOCATIONS },
          { query: GET_OFFICES },
          { query: GET_SINGLE_OFFICE, variables: { id } }
        ]
      });
    }
    else {
      setFormErrors(validation.errors);
    }

  }, initialState, SHOULD_LOG_STATE);

  const updateDataOnly = () => {
    if (data) {
      // console.log(data);
      const office = data.commonQueries.getOfficeById;
      // console.log(office);
      if (Object.keys(office).length === 0) {
        deleteOffice({ variables: { id } })
      }
      else {
        const values = {
          ...office,
          location: office.location._id
        }
        // console.log(values);
        overrideValues(values);
      }
    }
  }

  useMemo(updateDataOnly, [data])

  const handleRefresh = () => {
    // console.log('handleRefresh');
    getLazySingleOffice({ variables: { id } });
    getLazyLocations();
  }
  
  const handleHardReload = () => window.location.reload();

  const handleDelete = () => {
    // console.log(id);
    deleteOffice({ variables: { id } })
  }

  if (isError) {
    return (
      <>
        <Header as="h3">An Error has occured. Please try again.</Header>
        <p>{JSON.stringify(errorMessage)}</p>
      </>
    )
  }

  return (
    loading
      ?
      <Loader />
      :
      <Layout>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column textAlign="left">
              <Button primary as={Link} to="/admin/groups" icon>
                <Icon name="triangle left" /> Back to Groups
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Header as="h3">{intl.get("admin.edit_group")}</Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              {process.env.NODE_ENV !== 'development' && <Button primary onClick={handleRefresh} icon>
                Reload{' '}<Icon name="redo" />
              </Button>}
              {process.env.NODE_ENV === 'development' && <Button primary onClick={handleHardReload} icon>
                Refresh{' '}<Icon name="redo" />
              </Button>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment.Group>
          <Segment>
            <Segment.Group>
              <Segment as={Form} onSubmit={onSubmit} loading={isUpdatingGroup} onKeyPress={e => e.key === 'Enter' ? onSubmit.bind(this) : false}>
                <Grid columns="equal" textAlign="left">
                  <GroupFormFields values={values} errors={formErrors} onChange={onChange} />
                  {Object.keys(formErrors).length === 0 && formErrors.constructor === Object ? false :
                    <Grid.Row>
                      <Grid.Column textAlign="left">
                        <Message error icon="warning sign" content={<ul>
                          {Object.keys(formErrors).map(key => formErrors[key].map((error, index) => <li key={index}>{error}</li>))}
                        </ul>} />
                      </Grid.Column>
                    </Grid.Row>
                  }
                </Grid>
              </Segment>
            </Segment.Group>
            <Segment.Group>
              <Segment>
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column textAlign="left">
                      <Button className="red" onClick={() => setIsModalOpen(true)} icon disabled={isUpdatingGroup}>{intl.get("button.delete")} <Icon name="trash" /></Button>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                      <Button primary onClick={onSubmit} disabled={isUpdatingGroup} icon >{intl.get("button.update")} <Icon name="check" /></Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Segment.Group>
          </Segment>
        </Segment.Group>
        <Modal open={isModalOpen} closeOnDimmerClick={deleteOfficeMutation.loading}>
          <Modal.Header>{intl.get("admin.delete_group_confirm")}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Button className="red" onClick={handleDelete} icon disabled={deleteOfficeMutation.loading}>{intl.get("button.delete")} <Icon name="trash" /></Button>
              <Button onClick={() => setIsModalOpen(false)} icon disabled={deleteOfficeMutation.loading}>{intl.get("button.cancel")} <Icon name="cancel" /></Button>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Layout>
  )
}

export default GroupEditorPage;