import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Upload from './Upload';
import Visualize from './Visualize';
import Submit from './Submit';
import Publish from './Publish';

function Steps({ step, layer, layerID, setLayer, fields, setFields, setIsNewLayerData, setFieldsToDelete, officeData, userData, accessData, renderMap, user, sharedstatus }) {
  switch (step) {
    case 'upload':
      return <Upload layer={layer} layerID={layerID} setLayer={setLayer} fields={fields} setFields={setFields} setIsNewLayerData={setIsNewLayerData} setFieldsToDelete={setFieldsToDelete} user={user} sharedstatus={sharedstatus}/>;
    case 'visualize':
      return <Visualize layer={layer} layerID={layerID} setLayer={setLayer} fields={fields} setFields={setFields} renderMap={renderMap} user={user}/>;
    case 'submit':
      return <Submit layer={layer} layerID={layerID} setLayer={setLayer} fields={fields} setFields={setFields} renderMap={renderMap} user={user}/>;
    case 'publish':
      return <Publish layer={layer} layerID={layerID} setLayer={setLayer} fields={fields} setFields={setFields} officeData={officeData} userData={userData} accessData={accessData} user={user} />;
    default:
      return <Redirect to="/editor" />
  }
}

export default Steps;
