export default (coordinates) => {
  if (Array.isArray(coordinates)
      && coordinates.length > 0 
      && Array.isArray(coordinates[0]) 
      && coordinates[0].length >= 2 
      && typeof(coordinates[0][0]) === 'number' 
      && typeof(coordinates[0][1]) === 'number')
    return [coordinates];
  else
    return coordinates;
}