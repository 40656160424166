import React from 'react'
import { getIcon, getIconSVG } from './'
import defaultMapStylings from './defaultMapStylings';

export default (value, stylingInfo, bboxLength, type) => {
  let mark = '';
  let modifiedBBoxLength = bboxLength;

  const color = stylingInfo.color(value) ? stylingInfo.color(value) : defaultMapStylings.color;



  if (type==='POINT')  {
    const radius = stylingInfo.radius(value) ? stylingInfo.radius(value) : (defaultMapStylings.radius.min + defaultMapStylings.radius.max)/2;
    const symbol = stylingInfo.symbol(value) ? stylingInfo.symbol(value) : defaultMapStylings.symbol;

    // console.log(stylingInfo,value,color,symbol,radius);


    if (!!getIcon(symbol)) {
      mark = getIconSVG(symbol,bboxLength,color)
    } else if (symbol === 'circle') {
      if (radius*2 > bboxLength) {
        modifiedBBoxLength = radius* 2 + 2
      } 

      mark = <circle cx={modifiedBBoxLength/2} cy={modifiedBBoxLength/2} 
        r={radius} 
        stroke={color}
        fill={color}
        strokeWidth={stylingInfo.weight}
        fillOpacity={stylingInfo.fillMultiplier}>
      </circle>
      
    }
    
  }
    
  if (type==='LINE') {
    if(typeof(color) === "string") {
      mark = <line x1="0" y1={bboxLength/2} x2={bboxLength*2} y2={bboxLength/2} 
        style={{stroke: color,strokeWidth: stylingInfo.weight*2}} 
      />
    } 
    
  }
  if (type==='POLYGON') {
    if(typeof(color) === "string") {
      mark = <rect width={bboxLength} height={bboxLength} 
        style={{
          fill: color, 
          fillOpacity: stylingInfo.fillMultiplier, 
          stroke: color,
          strokeWidth: stylingInfo.weight*2
        }}
      />
    } 
    
  }
    
    
  return <svg className='legendMarks' width={modifiedBBoxLength} height={modifiedBBoxLength}>
    {mark}
  </svg>
}