import { useState, useEffect } from 'react';

export default (callback, initialState = {}, shouldLogState) => {
  const [values, setValues] = useState(initialState);
  const onChange = (event) => {
    event.preventDefault();
    console.log({ [event.target.name]: event.target.value })
    setValues({ ...values, [event.target.name]: event.target.value });
    event.target.focus()
  };

  const onSubmit = (event) => {
    event.preventDefault();
    callback(values);
  };

  const overrideValues = (values) => {
    console.log('overriding');
    setValues({ ...values })
  }

  useEffect(() => {
    if (shouldLogState) {
      console.log(values);
    }
  }, [values, shouldLogState])

  return {
    onChange,
    onSubmit,
    overrideValues,
    values
  };
};
