import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Header, Segment, Table, Icon, Pagination } from 'semantic-ui-react';
import GET_LOCATIONS from '../../graphql/getLocations';
import GET_CUSTOM_LOCATIONS from '../../graphql/getCustomLocations'
import GET_TOTAL_LOCATIONS from '../../graphql/getTotalLocations'
import ADD_NEW_LOCATION from '../../graphql/createUpdateLocation';
import Loader from '../../components/Loader';
import NewLocation from './NewLocation';
import intl from 'react-intl-universal';

function LocationEditor() {
  const { loading: totallocationloading, data: totallocationdata, error: totallocationerror } = useQuery(GET_TOTAL_LOCATIONS)
  const [state, setState] = useState({
    skip: 0,
    take: 9
  })
  const skip = state.skip
  const take = state.take
  const { loading: customlocationloading, data: customlocationdata, error: customlocationerror } = useQuery(GET_CUSTOM_LOCATIONS, { variables: { skip, take } })
  const { loading, data, error } = useQuery(GET_LOCATIONS);
  const [getLocation, getLocationQuery] = useLazyQuery(GET_LOCATIONS);
  const [isAddingLocation, toggleAddNewLocation] = useState(false);
  const [isAddingNewLocation, setIsAddingNewLocation] = useState(false);
  const [errorPrompt, setError] = useState({
    isError: false,
    message: ''
  });
  const [activePage, setActivePage] = useState(1)
  const [boundaryRange, setBoundaryRange] = useState(1)
  const [siblingRange, setSiblingRange] = useState(1)
  const [showEllipsis, setShowEllipis] = useState(true)
  const [showFirstAndLastNav, setShowFirstAndLastNav] = useState(true)
  const [showPreviousAndNextNav, setShowPreviousAndNextNav] = useState(true)
  const [count, setCount] = useState(0)
  const [hostdata, setHostdata] = useState()

  useEffect(() => {
    if (totallocationdata) {
      setCount(totallocationdata && totallocationdata.commonQueries && totallocationdata.commonQueries.getLocationsTotal && totallocationdata.commonQueries.getLocationsTotal.count)
    }
    if (customlocationdata) {
      setHostdata(customlocationdata && customlocationdata.commonQueries && customlocationdata.commonQueries.getLocations && customlocationdata.commonQueries.getLocations)
    }
  }, [customlocationdata, totallocationdata])

  const handleToggleAddNewLocation = () => {
    toggleAddNewLocation(!isAddingLocation);
  };

  const [addLocation, { addLocationLoading = loading }] = useMutation(ADD_NEW_LOCATION, {
    onCompleted: () => {
      setIsAddingNewLocation(false);
      return isAddingNewLocation;
    },
    onError: error => {
      console.log(error.response);
      const { message } = error.response.data;
      setError({
        isError: true,
        message: message || 'error!'
      });
      return errorPrompt;
    },
    errorPolicy: 'all'
  });

  // const handleAddNewLocation = payload => {
  //   setIsAddingNewLocation(true);
  //   const { lat, lon } = payload;
  //   const center = { lat, lon }
  //   delete payload.lat;
  //   delete payload.lon;

  //   const locationInput = {
  //     ...payload,
  //     center
  //   }

  //   console.log(locationInput);
  //   addLocation({
  //     variables: { locationInput },
  //     refetchQueries: [{ query: ADD_NEW_LOCATION }]
  //   })
  // }

  if (customlocationerror) {
    return (
      <Grid>
        <Grid.Row>
          <Header as="h4">An Error has occured. Please try again.</Header>
        </Grid.Row>
        <Grid.Row>
          <Button
            onClick={() => getLocation()}
            loading={getLocationQuery.loading}
            disabled={getLocationQuery.loading}
            primary
            icon>Retry <Icon name="redo" />
          </Button>
        </Grid.Row>
      </Grid >
    )
  }

  const handlePaginationChange = async (e, { activePage }) => {
    setActivePage(activePage)
    setState({
      take: parseInt(9),
      skip: parseInt((activePage * 9) - 9)
    })
  }

  return (
    customlocationloading || addLocationLoading || getLocationQuery.loading
      ?
      <Loader />
      :
      <>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column width={16}>
              {
                !isAddingLocation || !data
                  ?
                  <Segment.Group className="adminToolBar">
                    <Segment>
                      <Button onClick={handleToggleAddNewLocation} primary>
                        <Icon name="map marker alternate" /> {intl.get("admin.add_new_location")}
                      </Button>
                    </Segment>
                  </Segment.Group>
                  :
                  <Segment.Group className="addAdminItems">
                    <Segment>
                      <Grid>
                        <Grid.Column width={16}>
                          <Header as="h3" floated="left">{intl.get("admin.new_location")}</Header>
                          <Button icon="x" onClick={handleToggleAddNewLocation} color="red" floated="right" size="small" />
                        </Grid.Column>
                      </Grid>
                    </Segment>
                    <NewLocation cancelNewLocation={() => toggleAddNewLocation(false)} locations={data.commonQueries.getLocations} />
                  </Segment.Group>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Form>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{intl.get("admin.location_fields.name_mm")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.location_fields.name_en")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.location_fields.level")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.location_fields.placecode")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.location_fields.latitude")}</Table.HeaderCell>
                <Table.HeaderCell>{intl.get("admin.location_fields.longitude")}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {hostdata && hostdata.map(location => (
                <Table.Row key={location._id}>
                  <Table.Cell>{location.nameMM}</Table.Cell>
                  <Table.Cell>{location.nameEN}</Table.Cell>
                  <Table.Cell>{location.level}</Table.Cell>
                  <Table.Cell>{location.placeCode}</Table.Cell>
                  <Table.Cell>{location.center.lat}</Table.Cell>
                  <Table.Cell>{location.center.lon}</Table.Cell>
                  <Table.Cell>
                    <Button as={Link} to={`/admin/location/${location._id}`}>
                      <span className="tgicon tgicon-edit"></span> {intl.get("button.edit")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          {
            Math.ceil(count && count / 9) <= 1 ? null : (<>
              <Pagination
                activePage={activePage}
                boundaryRange={1}
                siblingRange={1}
                onPageChange={handlePaginationChange}
                boundaryRange={boundaryRange}
                size='mini'
                siblingRange={siblingRange}
                ellipsisItem={showEllipsis ? undefined : null}
                firstItem={showFirstAndLastNav ? undefined : null}
                lastItem={showFirstAndLastNav ? undefined : null}
                prevItem={showPreviousAndNextNav ? undefined : null}
                nextItem={showPreviousAndNextNav ? undefined : null}
                totalPages={Math.ceil(count && count / 9)}
              />
            </>)
          }

        </Form>
      </>
  )
}

export default LocationEditor;
