import gql from 'graphql-tag'

export default gql`
    query{
        layersQuery{
            getLayersTotal{
                count
            }
        }
    }
`