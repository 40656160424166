import gql from 'graphql-tag';

export default gql`
query uersTotalQuery{
    userQueries{
        getUsersTotal{
          count
        }
    }
}
`