import gql from 'graphql-tag';

export default gql`
  query getParentBasedOffices($skip: Int, $take: Int, $where: JSON) {
    commonQueries {
      getOffices(skip: $skip, take: $take, where: $where) {
        _id
        level
        ministry
        department
        location {
          _id
          nameMM
          nameEN
        }
        parent {
          _id
          nameEN
          nameMM
          level
          ministry
          department
        }
      }
    }
  }
`;
