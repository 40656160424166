import gql from 'graphql-tag'

export default gql`
    query getTotalLayers($where:JSON){
        layersQuery{
            getLayersTotal(where:$where){
                count
            }
        }
    }
`