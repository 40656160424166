export default (priority, type) => {
    if (priority === 1 && type === "VIEW") {
        return 1
    } else if (priority === 1 && type === "EDIT") {
        return 2
    } else if (priority === 2 && type === "EDIT") {
        return 3
    } else if (priority === 3 && type === "EDIT") {
        return 4
    } else if (priority === 4 && type === "EDIT") {
        return 4
    } else {
        return 1
    }
}